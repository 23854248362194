import {QuantityEditor} from 'js/jsx/src/classes/quote/quantityEditor.jsx'
import {QuoteEditToolbar} from "js/jsx/src/classes/quote/quoteEditForm.jsx";
import {QuoteStatusIndicators} from  "js/jsx/src/classes/quote/quoteHeader.jsx";
import {ActionsMenu} from "js/jsx/src/classes/menus.jsx";
import {SearchForm} from "js/jsx/src/classes/search.jsx";

export class QuoteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overrideSearch: null,
        };
        
        // This binding is necessary to make `this` work in the callback
        this.importQuote = this.importQuote.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.customizeCell = this.customizeCell.bind(this);
        this.doExecuteSearch = this.doExecuteSearch.bind(this);
        this.executeExport = this.executeExport.bind(this);
        this.doSearchExport = this.doSearchExport.bind(this);
        this.searchFinished = this.searchFinished.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.gridConfigurationChanged = this.gridConfigurationChanged.bind(this);
        this.cancelActiveSearchCalls = this.cancelActiveSearchCalls.bind(this);
        this.removeQuoteFromState = this.removeQuoteFromState.bind(this);
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        if (this.activeSearchApi) {
            quosal.net.abortAjaxCall(this.activeSearchApi.opId);
        }
        quosal.customization.grids.configurationUpdated.unbind(this.gridConfigurationChanged);
    }

    componentDidUpdate() {
        quosal.ui.views.parse(document.getElementById('pagecontainer'));
    }

    importQuote() {
        QuoteEditToolbar.importQuote.call(this);
    }

    searchFinished(msg) {
        if (this.refs.search.state.selectedQuote.length > 1) {
            for (var i = 0; i < msg.results.length; i++) {
                if (this.refs.search.state.selectedQuote.find(x => x.IdQuoteMain == msg.results[i].IdQuoteMain))
                msg.results[i].IsSelected = true;
            }
        }

        this.refs.search.setState({ searchResults: msg.results, resultCount: msg.resultCount, pageNumber: msg.pageNumber, searching: false });
    }

    executeSearch() {
        try {
            if (quosal.sell.data.isPreloadingQuoteSearch) {
                this.refs.search.setState({searchResults:[], searching:true});
                quosal.sell.data.quoteSearchFinishedCallback = this.searchFinished;
                return;
            }
            this.doExecuteSearch()
        } 
        catch(e) {
            this.doExecuteSearch();
        }
    }

    cancelActiveSearchCalls() {
        if (quosal.sell.data.isPreloadingQuoteSearchOpId) {
            quosal.net.abortAjaxCall(quosal.sell.data.isPreloadingQuoteSearchOpId);
        }
        if (this.activeSearchApi) {
            quosal.net.abortAjaxCall(this.activeSearchApi.opId);
        }
    }

    doExecuteSearch() {
        var form = this.refs.search.refs.criteria.refs.form;
        var search = form.getFormValues({ dirty: true, getListOfEnumFields: true });
        this.setState({ overrideSearch: null });
        var searchCriteria = search.values;
        var enumFields = search.enumFields;
        var listOfNoteFields = quosal.sell.data.getQuoteSearchNoteFields();
        var pageSize = QuoteSearch.pageSize;
        var pageNumber = this.refs.search.state.pageNumber || 1;

        var cookieValue = {
            searchCriteria: searchCriteria,
            pageNumber: pageNumber,
            enumFields: enumFields
        };
        quosal.util.cookie(QuoteSearch.cookieId, JSON.stringify(cookieValue), { expires: 14 });

        if (document.getElementById("templateSelection")) {
            var templatedValue = document.getElementById("templateSelection").value
            searchCriteria["IsTemplate"] = templatedValue;
        }

        var searchApi = quosal.api.quote.search(searchCriteria, pageSize, pageNumber, enumFields, listOfNoteFields);
        searchApi.finished = function(msg) {
            this.activeSearchApi = null;
            if (this.componentIsMounted) {
                this.searchFinished(msg);
            }            
        }.bind(this);

        searchApi.call();

        this.cancelActiveSearchCalls();
        this.activeSearchApi = searchApi;
        this.refs.search.setState({searchResults:[], searching:true});
    }

    executeExport() {
        this.doSearchExport();
    }
    
    doSearchExport() {
        this.refs.search.setState({ isExportProcessing: true });

        var form = this.refs.search.refs.criteria.refs.form;
        var search = form.getFormValues({ dirty: true, getListOfEnumFields: true });

        var searchCriteria = search.values;
        var enumFields = search.enumFields;
        var listOfNoteFields = quosal.sell.data.getQuoteSearchNoteFields();
        let totalQuoteNumber;
        if (this.refs.search.state.selectedQuote.length > 0){
            totalQuoteNumber = this.refs.search.state.selectedQuote.length;
        } else {
            totalQuoteNumber = this.refs.search.state.resultCount;  
        }    

        let selectedIdQuoteMains = [];

        selectedIdQuoteMains = this.refs.search.state.selectedQuote.map(quote => quote.IdQuoteMain);

        if (document.getElementById("templateSelection")) {
            var templatedValue = document.getElementById("templateSelection").value
            searchCriteria["IsTemplate"] = templatedValue;
        }

        var gridConfig = quosal.customization.grids[this.props.gridConfiguration.gridName];

        var columns = gridConfig.Configurations[this.props.gridConfiguration.configurationName].Columns ||
            gridConfig.Configurations['Default'].Columns

        for (var i = 0; i < columns.length; i++) {
            let fieldConfig = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName].fieldConfigurations[columns[i].FieldName];
            columns[i].DisplayName = fieldConfig == undefined ? columns[i].DisplayName : fieldConfig.FieldRename;
        }

        var searchApi = quosal.api.quote.exportquote(columns, searchCriteria, totalQuoteNumber, enumFields, listOfNoteFields, selectedIdQuoteMains);
        searchApi.finished = function (msg) {
            this.refs.search.setState({ isExportProcessing: false });
            this.activeSearchApi = null;
            if (msg.errorMessage) {
                Dialog.open({
                    title: 'Error',
                    message: msg.errorMessage,
                    links: [Dialog.links.close]
                });
            } else {                
                window.open(msg.exportQuoteUrl, '_blank');
            }
        }.bind(this);

        searchApi.call();

        this.cancelActiveSearchCalls();
        this.activeSearchApi = searchApi;
    }

    resetSearch() {
        // anything extra that has to happen when you reset the search can happen here
        this.setState({overrideSearch: null});
        quosal.util.cookie(QuoteSearch.cookieId, '', {expires: 14});
        this.refs.search.setState({searchResults:[], searching:false});
        this.cancelActiveSearchCalls();
    }

    defaultSearchTerms() {
        var defaults = quosal.util.clone(QuoteSearch.defaultSearchTerms);
        if (app.currentUser.DefaultSearchQuoteStatus) {
            defaults['QuoteStatus'] = app.currentUser.DefaultSearchQuoteStatus;
        }
        return defaults;
    }

    customizeCell (params) {
        if (params.field.FieldName === 'QuoteStatusIndicators') {
            var quote = params.gridRow.props.row;
            var statusFlags = {
                CustomerUnknown: true
            };
            if (quote.QuoteStatus === 'Won') {
                statusFlags.IsCompleted = true;
            } else if (quote.IsLocked) {
                statusFlags.IsLocked = true;
            }
            return (
                <QuoteStatusIndicators quote={quote} statusFlags={statusFlags} style={{float:'right', whiteSpace:'nowrap'}} />
            );
        }
    }

    componentDidMount() {
        // FSP 10/31/16: these two query string parameters (nocookieload and searchquotename) are potential inputs from
        //      using the quick quote search in the header. We can't retire or change these inputs unless the quick quote search is
        //      reimplemented for every page in the application (as opposed to, say, reimplemented just for Snap pages).
        var noCookieLoad = quosal.util.queryString('nocookieload') == 'yes';
        var searchquotename = quosal.util.queryString('searchquotename');
        var viaquick = quosal.util.queryString('quick') == 'y';

        this.componentIsMounted = true;
        var overrideSearch = {};
        var overrideSearchTerms = this.defaultSearchTerms();
        if (searchquotename) {
            searchquotename = searchquotename.replace('+', ' ');
            overrideSearchTerms.QuoteName = decodeURIComponent(searchquotename);
        }
        if (!noCookieLoad) {
            var cookieStringValue = quosal.util.cookie(QuoteSearch.cookieId);
            var cookie = cookieStringValue && JSON.parse(cookieStringValue);
            if (cookie && typeof cookie === 'object') {
                var pageNumber = cookie.pageNumber;
                if (pageNumber) {
                    this.refs.search.setState({pageNumber: pageNumber});
                }
                var searchCriteria = cookie.searchCriteria;
                for (var key in searchCriteria) {
                    overrideSearchTerms[key] = searchCriteria[key];
                }
                overrideSearch.enumFields = cookie.enumFields;
            }
        }
        overrideSearch.searchCriteria = overrideSearchTerms;
        this.setState({overrideSearch: overrideSearch}, ()=> {this.executeSearch()});
        quosal.customization.grids.configurationUpdated.bind(this.gridConfigurationChanged);
    }

    gridConfigurationChanged() {
        this.executeSearch();
    }

    promptDeleteQuote(idQuoteMain) {
        var me = this;
        Dialog.open({
            title:"Comfirm Delete",
            message: "Are you sure you want to delete this quote?",
            links: [{
                title: 'Yes, Delete',
                callback: function() {
                    Dialog.close({
                        callback: function() {
                            quosal.setQuoteStatus.deleted(idQuoteMain, false, ()=> me.removeQuoteFromState(idQuoteMain));
                        }
                    });
                }},
                {
                    title: 'No, Cancel', 
                    callback: Dialog.close
                }
            ]
        });
    }

    removeQuoteFromState(idQuoteMain) {
        this.refs.search.setState({
            searchResults: this.refs.search.state.searchResults.filter(function(obj) {
                return obj.IdQuoteMain !== idQuoteMain;
            })
        });
    }

    checkCanNotDelete(row) {
        return row.IsLocked 
            || row.QuoteStatus == "Deleted"
            || row.QuoteStatus == "Archived"
    }

    SelectRow(row) {
        let SelectedRow = this.refs.search.state.searchResults.filter(function (obj) {
            return obj.IdQuoteMain == row.IdQuoteMain;
        })
        
        if (SelectedRow[0].IsSelected == true) {
            if (this.refs.search.state.searchResults.every((quote) => quote.IsSelected)) {
                this.refs.search.setState({selectedQuote: this.refs.search.state.selectedQuote.filter((quote) => quote.QuoteReadableId !== SelectedRow[0].QuoteReadableId)});
            }
            else {
                this.refs.search.setState((prevState) => ({
                    selectedQuote: prevState.selectedQuote.filter((quote) => quote.QuoteReadableId !== SelectedRow[0].QuoteReadableId)
                }));
            }
        }
        else {
            if (SelectedRow) {   
                if (this.refs.search.state.searchResults.every((quote) => !quote.IsSelected)) {
                    this.refs.search.setState((prevState) => ({
                        selectedQuote: [ ...prevState.selectedQuote, SelectedRow[0]]
                    }))
                }
                else {  
                    this.refs.search.setState((prevState) => ({
                        selectedQuote: [SelectedRow[0], ...prevState.selectedQuote]
                    }))
                }
            }
        }

        row.IsSelected = row.IsSelected == undefined ? true : !row.IsSelected;
    }

    render() {
        var quoteActions = [];
        if (app.currentUser.IsAdministrator) { 
            quoteActions.push({
                title: 'Import Quote',
                callback: this.importQuote
            });
        }
        if (app.settings.global.EnableElectronicOrders) {
            quoteActions.push({
                title: 'Search Shipments',
                callback: function() {
                    window.location.href = quosal.util.url('shippingsearch.quosalweb');
                }
            });
        }
        
        var actionMenu = quoteActions.length == 0 ? "" : <PanelToolbar>
            <PanelToolbarButton>
                <ActionsMenu actions={quoteActions} />
            </PanelToolbarButton>
        </PanelToolbar>;
        var copyTabs = quosal.util.userCanCopyTabs();
        var copyItems = quosal.util.queryString("copyitems") == "true";
        var customColumns = [{
                isSelectAll: true,
                createCell: function (row) {
                    var onClick = () => this.SelectRow(row.props.row);
                    return <input type="checkbox" onClick={onClick} readOnly checked={row.props.row.IsSelected || false} />
                }.bind(this)
            },{
            replaceSortableColumn: true,
            createCell: function(row) {
                var url = "";
                var linkName = "Open";
                if (copyItems) {
                    url = quosal.util.url('copyitems.quosalweb', 'copyitems=true', 'idcopyquote=' + row.props.row.IdQuoteMain);
                    url = url + "&_suid=1";
                    linkName = "View Items"
                }
                else if (copyTabs) {
                    url = quosal.util.url('copytabs.quosalweb', 'copytabs=true', 'idcopyquote=' + row.props.row.IdQuoteMain);
                    url = url + "&_suid=1";
                    linkName = "View Tabs"
                }
                else {
                    var dashboardModule = quosal.sell.modules.find('quote.dashboard');
                    var dashUrlQuery = 'idquotemain=' + row.props.row.IdQuoteMain;
                    url = dashboardModule.getUrl({ query: dashUrlQuery })
                }

                return <a href={url} className="link">{linkName}</a>
            }.bind(this)
        }];
        var canCopyQuote = (app.currentUser.IsContentMaintainer || app.currentUser.IsAdministrator || app.currentUser.IsStandardPlus || !quosal.settings.getValue('DisableCopyQuote')) && !app.currentUser.IsReadOnly;
        var specialCopy = copyItems || copyTabs;
        if (canCopyQuote && !specialCopy) {
            customColumns.push({
                createCell: function (row) {
                    var title = row.props.row.IsTemplate ? 'Create Quote from This Template' : 'Copy Quote';
                    var onClick = QuoteEditToolbar.copyQuote.bind(null, row.props.row.IdQuoteMain, row.props.row.QuoteStatus);
                    return <a onClick={onClick} ><div className="toolbutton copy-quote" title={title} /></a>;
                }.bind(this)
            });
            customColumns.push({
                createCell: function (row) {
                    var title = 'Delete Quote';
                    var onClick = () => this.promptDeleteQuote(row.props.row.IdQuoteMain);
                    return this.checkCanNotDelete(row.props.row) ? <div style={{width:'20px'}}/> : <a onClick={onClick} ><div className="toolbutton delete" title={title} /></a>;
                }.bind(this)
            });
        }

        if (copyTabs) {
            customColumns.push({
                title: "Is Template",
                createCell: function(row) {
                    if (copyTabs) {
                        return <input type="checkbox"  readOnly checked={row.props.row.IsTemplate}/>
                    }
                }.bind(this)
            });
        }

        var searchCriteriaProps = {};
        searchCriteriaProps.formProps = {firstPanelTitleContents: actionMenu, isSearchForm: true};
        searchCriteriaProps.alwaysDirtyInputValues = (this.state.overrideSearch && this.state.overrideSearch.searchCriteria) || this.defaultSearchTerms();

        var props = {};

        return (
            <SearchForm ref="search" resetSearch={this.resetSearch} customResultsColumns={customColumns}
                formConfiguration={this.props.formConfiguration} gridConfiguration={this.props.gridConfiguration}
                executeSearch={this.executeSearch.bind(this)} executeExport={this.executeExport.bind(this)} title={'Search Results'} resultGridSize={'1-1'}
                gridProps={{ customizeCell: this.customizeCell }} parent={"quoteSearch"}
                searchCriteriaProps={searchCriteriaProps} pageSize={QuoteSearch.pageSize}
                showSortIconsAtBottomRight={true}
                {...props} />
        );
    }
}
global.QuoteSearch = QuoteSearch;

QuantityEditor.activeEditor= null;
QuantityEditor.previousEditor= null;

QuoteSearch.pageSize= 50;
QuoteSearch.defaultSearchTerms= {
    'OrderBy': 'create_date DESC',
    'IsArchive': 'false',
    'QuoteStatus': 'All'
};
QuoteSearch.cookieId= 'c_forminputs_quotesearch';