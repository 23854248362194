export const DUPLICATE_DYNAMIC_CONDITION_TOOLTIP = "Duplicate Dynamic Condition";
export const ERROR_MSG_TOAST_NOTIFICATION = "We found issues on this page. Please fix them before you try to save again.";
export const NOTIFICATION_TOAST_TITLE = {
	error: "Error",
	success: "Success",
	warning: "Warning",
	info: "Info"
};
export const NOTIFICATION_TOAST_COLOR = {
	error: "#EF5350",
	success: "#4CAF50",
	warning: "#FF9800",
};
