
import React, { useCallback, useEffect, useState } from "react";

import { Button } from "../forms";
import TruncatedText from "../forms/truncatedText";

export function MassTabDelete({ rerenderContentGrid }) {
    const tabs = quosal.util.getVisibleTabs(app.currentQuote).map((tab) => ({ ...tab, checked: false }));
    const [errorText, setErrorText] = useState("");
    const [checkboxStates, setCheckboxStates] = useState(tabs.map((tab) => tab.checked));

    useEffect(() => {
        let idQuoteTabArray = [];
        checkboxStates.forEach((checked, index) => {
            if (checked) {
                idQuoteTabArray.push(tabs[index].IdQuoteTabs);
            }
        });
        if (idQuoteTabArray.length === 0) {
            setErrorText("Please select at least one tab to delete");
        }
        else if (idQuoteTabArray.length === tabs.length) {
            setErrorText("You cannot delete all tabs");
        } else {
            setErrorText("");
        }
    }, [checkboxStates, tabs]);

    const handleSelectAllChange = (event) => {
        const updatedCheckboxStates = checkboxStates.map(() => event.target.checked);
        setCheckboxStates(updatedCheckboxStates);
    };

    const handleTabCheckboxChange = (index) => {
        const updatedCheckboxStates = [...checkboxStates];
        updatedCheckboxStates[index] = !updatedCheckboxStates[index];
        setCheckboxStates(updatedCheckboxStates);
    };

    const deleteSelectedTabs = useCallback(() => {
        let idQuoteTabArray = [];
        checkboxStates.forEach((checked, index) => {
            if (checked) {
                idQuoteTabArray.push(tabs[index].IdQuoteTabs);
            }
        });

        const deleteApi = quosal.api.tab.deleteTabs(idQuoteTabArray, app.currentQuote.IdQuoteMain);

        deleteApi.finished = (msg) => {
            quosal.sell.quote.updateFromApiResponse(msg);
            Dialog.setIsWorking(false);
            rerenderContentGrid();
            Dialog.closeAll();
        };

        Dialog.setIsWorking(true);
        deleteApi.call();
    }, [checkboxStates, tabs]);

    return (
        <div style={{ display: "flex", flexDirection: "column", height:"100%" }}>
            <div style={{overflow:"auto"}}>
            <table cellPadding='5' className='datagrid'>
                <thead>
                    <tr>
                        <th width='75' style={{ cursor: 'pointer' }} className='title nosort center'>
                            <label style={{ display: 'block' }}>Check All</label>
                            <input
                                checked={checkboxStates.every((checked) => checked)}
                                type="checkbox"
                                id="checkAll"
                                onChange={handleSelectAllChange}
                            />

                        </th>
                        <th width='250' className='title nosort'>Tab Name</th>
                        <th width='100' className='title nosort'>Tab Total</th>
                        <th width='100' className='title nosort'>Tab Recurring Total</th>
                    </tr>
                </thead>
                <tbody>
                    {tabs.map((tab, index) => (
                        <tr className='dataGridRow' key={index}>
                            <td className='content center' style={{ cursor: 'pointer', padding: '10px 5px' }}>
                                <input
                                    type="checkbox"
                                    id={`tabCheckbox${index}`}
                                    checked={checkboxStates[index]}
                                    onChange={() => handleTabCheckboxChange(index)}
                                />
                                <label htmlFor={`tabCheckbox${index}`}></label>
                            </td>
                            <td className='content'>
                                <TruncatedText maxWidth="250px" text={tab.TabName} />
                            </td>
                            <td className='content'>
                                <TruncatedText maxWidth="100px" text={app.currentQuote.formatCurrency(tab.TabTotal)} />
                            </td>
                            <td className='content'>
                                <TruncatedText maxWidth="100px" text={app.currentQuote.formatCurrency(tab.RecurringTotal)} />
                            </td>
                        </tr>

                    ))}
                </tbody>
            </table>
            </div>
            <div style={{ display: "flex", marginLeft: "auto", gap: "5px", padding: "5px", marginTop: "5px" }}>
                <Button type="cancel" title="Cancel Changes" onClick={Dialog.closeAll}>
                    Cancel
                </Button>
                <Button
                    className="save"
                    onClick={deleteSelectedTabs}
                    disabled={errorText !== ""}
                >
                    Delete Selected Tabs
                </Button>
            </div>
            <div style={{ display: "flex", marginLeft: "auto", gap: "5px", padding: "5px", color: "red", height: "5px", minHeight:"20px" }} >
                {errorText}
            </div>
        </div>
    )
}


