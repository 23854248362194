import { Box } from "@mui/material";
const SVG_NO_FORMS = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Form_To_Display.svg';
const SVG_NO_FORMS_DARK = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Form_To_Display_Dark.svg';
const SVG_NO_RESULTS = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Results.svg';
const SVG_NO_RESULTS_DARK = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Results_Dark.svg';

export const NoResultComponent = ({ searchedValue, theme }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginTop: "100px"
			}}
		>
			<img
				style={{ height: "230px" }}
				src={
					searchedValue.length > 0
						? theme.palette.mode === "dark"
							? SVG_NO_RESULTS_DARK
							: SVG_NO_RESULTS
						: theme.palette.mode === "dark"
						? SVG_NO_FORMS_DARK
						: SVG_NO_FORMS
				}
				alt="Submitted sales tracks forms no form to display image"
				className="salestrack-no-form-image"
			/>
		</Box>
	);
};
