import { Button, Grid, Box } from '@mui/material'
import { CardGridContainerStyled, CommonSalesTrackTitle } from "../../common/guidedSelling/styledComponents/commonStyled";
import { SectionContainerStyled } from "./styledComponents/createFormStyled";
import { CommonBreadCrumbs } from "../../common/guidedSelling/components/commonBreadCrumbs";
import { ANSWERS_INITIAL_STATE, CUSTOM_SX_FORM_BUILDER_CONTAINER, DIVIDER_STYLE, ENUM_DATA_NAME, ENUM_KEY_CHECKBOX, ENUM_KEY_DATE, ENUM_KEY_NUMBER, ENUM_KEY_RADIO, ENUM_KEY_SELECT, ENUM_KEY_TEXT, handleValidateDate, randomId, SECTION_STRUCTURE } from './constants/createFormConstants';
import { SectionLayout } from './components/createForm/sectionLayout';
import { api } from "../../common/guidedSelling/api/api";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormContext from './contexts/configureFormContext';
import dayjs from 'dayjs';
import { COLLAPSE_ALL_SECTION, EXPAND_ALL_SECTION, SET_EXPAND_SECTION } from './actions/formActions';
import { FormHeaderActions, SectionAccordian } from './components/createForm';
import { NotificationToast } from './components/NotificationToast';
import { ERROR_MSG_TOAST_NOTIFICATION } from './constants';

export class ConfigureForm extends React.Component {
    static contextType = FormContext;
    constructor(props) {
        super(props);

        this.state = {
            sections: [{ ...SECTION_STRUCTURE },],
            isEdited: false,
            isSendable: true,
            expandAllSections: false
        }

        // Bind this
        this.onError = this.onError.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.updateIsSendible = this.updateIsSendible.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleValidateRequiredFields = this.handleValidateRequiredFields.bind(this);
        this.createSalesTrackForm = this.createSalesTrackForm.bind(this);
        this.handleDataFormatter = this.handleDataFormatter.bind(this);
        this.handleFormatDataToPost = this.handleFormatDataToPost.bind(this);
        this.setExpandSections = this.setExpandSections.bind(this);
        this.handleExpandCollapseAll= this.handleExpandCollapseAll.bind(this);
        this.setExpandAllSections = this.setExpandAllSections.bind(this);
    }

    componentDidMount() {
        if(!!this.props.editingFormItem && this.props.editingFormItem.length > 0){
         this.handleDataFormatter();
        } else {
            this.setState({ expandAllSections: true });
            this.context.setForm({
				sections: this.state.sections,
				isEdited: this.state.isEdited,
				isSendable: this.state.isSendable,
				expandSections: [{ sectionId: this.state.sections[0]?.sectionId, isExpanded: true }]
			});
        }
    }

    onError(title, message) {
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }

    onDelete(elementToDelete, formAction) {
        Dialog.open({
            title: 'Delete?',
            closeButton: true,
            width: "400px",
            id: 'salestrack-delete-dialog',
            message: `Are you sure you want to Delete this ${elementToDelete}?`,
            links: [Dialog.links.cancel, {
                title: <span className='salestrack-btn-delete-icon-container'>Delete<DeleteOutlineOutlinedIcon fontSize='small' /></span>,
                id: "salestrack-btn-delete",
                callback: () => {
                    formAction();
                    Dialog.closeAll();
                }
            }]
        });
    }

    onCancel() {
        const { setForm } = this.context;

        Dialog.open({
            title: 'Cancel?',
            closeButton: true,
            width: "400px",
            message: `Are you sure you want to Cancel changes made to the form?`,
            links: [
                {
                    title: 'No',
                    id: "salestrack-btn-cancel",
                    callback: () => {
                        Dialog.closeAll();
                    }
                }
                , {
                    title: 'Yes',
                    id: "salestrack-btn-continue",
                    callback: () => {
                        !!this.props.editingFormItem && this.props.editingFormItem.length > 0 ? this.handleDataFormatter() : setForm({
                            sections: [{ ...SECTION_STRUCTURE },],
                            isEdited: false,
                            isSendable: true,
                        });
                        Dialog.closeAll();
                    }
                }]
        });
    }

    handleValidateRequiredFields() {
        const sectionArray = this.context.sections;
        const { isSendable, formState, setForm } = this.context;

        const handleValidateOptionsRequired = (answerToValidate) => {
            return answerToValidate.answer === '';
        }

        const handleValidateAnswers = (answersToValidate) => { return handleValidateOptionsRequired(answersToValidate[0]) || handleValidateOptionsRequired(answersToValidate[1])};
        let formValid = true;
        const expandSections = [];
        for (let section of sectionArray) {
            const questionArray = section.questions;
            let sectionValid = true;
            if (section.name === "" || section.name.length > 100) {
                sectionValid =   false;
            }
            for (let question of questionArray) {
                const answerArray = !!question.answers ? question.answers : [];
                const multipliersArray = !!question.multipliers ? question.multipliers : [];
                const dataType = question.answerDataType;
                if (question.tooltip.length > 100) {
                    sectionValid =  false;
        
                }
                for (let answer of answerArray) {
                    if (answer.isEmpty || answer.isRepeated || answer.answer.length > 75) {
                        sectionValid =  false;
             
                    }
                }
                for (let multiplier of multipliersArray) {
                    if (question.isMultiplier && multiplier.questionSortOrder === '') {
                        sectionValid =  false;
              
                    }
                }
                if (question.question === '' || question.answerDataType === '' || question.question.length > 100) {
                    sectionValid =  false;
         
                }
                switch (dataType) {
                    case ENUM_DATA_NAME[ENUM_KEY_DATE]:
                        if (!!handleValidateDate(question.from, question.from, question.to) || !!handleValidateDate(question.to, question.from, question.to)) {
                            sectionValid =  false;
                         
                        };
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_TEXT]:
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_NUMBER]: 
                    // Does not go into the if statement if both minValue and maxValue are empty
                            if( question.minValue != '' && question.maxValue != '' && question.maxValue <= question.minValue){
                                    sectionValid =  false;
                   
                            };
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_SELECT]:
                    case ENUM_DATA_NAME[ENUM_KEY_RADIO]:
                        if (handleValidateAnswers(answerArray)) {
                            sectionValid =  false;
                        }
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_CHECKBOX]:
                        if (handleValidateAnswers(answerArray) || (question.hasMinOptions && (question.minOptions === '' || question.minOptions >= question.answers.length))) {
                            sectionValid =  false;
                        }
                        break;
                };
            }
            expandSections.push({ sectionId: section.sectionId, isExpanded: !sectionValid });
            formValid = formValid && sectionValid;
        }
        
        if (isSendable)
            setForm({ ...formState, isSendable: formValid, expandSections });
        else if(!formValid){
            setForm({ ...formState, expandSections });
        }
        return formValid;
    }

    handleDataFormatter() {
        const dataToFormat = this.props.editingFormItem;
        const { setForm, formState, getAllQuestionWithoutNumericField } = this.context;
        const questionsWithoutNumericFieldArray = getAllQuestionWithoutNumericField(dataToFormat);
        let newSectionArray = [];

        for (var sectionIndex = 0; sectionIndex < dataToFormat.length; sectionIndex++) {
            const sectionSelected = dataToFormat[sectionIndex];
            let newQuestionArray = [];
            const questions = sectionSelected.questions;
            for (var questionIndex = 0; questionIndex < questions.length; questionIndex++) {
                const questionSelected = questions[questionIndex];
                if (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_NUMBER]) {
                    const newMultipliers = questionSelected.multipliers.map((multiplierSelected) => {
                        const linkedQuestionObject = questionsWithoutNumericFieldArray.find((element) => element.id === multiplierSelected.toMultiplyId);
                        return {
                            questionSortOrder: linkedQuestionObject ? linkedQuestionObject.value : '', questionId: linkedQuestionObject ? linkedQuestionObject.id : '', numberQuestionSortOrder: questionSelected.sortOrder, numberQuestionId: questionSelected.questionId
                        }
                    });
                    const newQuestion = { ...questionSelected, multipliers: newMultipliers };
                    newQuestionArray.push(newQuestion);
                } else if (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_DATE]) {
                    const newFromDate = new Date(questionSelected.fromDate);
                    const newToDate = new Date(questionSelected.toDate);
                    const newQuestion = { ...questionSelected, from: questionSelected.fromDate === '' ? null : dayjs(newFromDate), to: questionSelected.toDate === '' ? null : dayjs(newToDate) };
                    delete newQuestion.fromDate;
                    delete newQuestion.toDate;
                    newQuestionArray.push(newQuestion);
                } else {
                    const answers = questionSelected.answers;
                    let newMappedAnswersArray = [];
                    const newAnswerArray = answers.length > 0 ? answers.map((answerSelected, answerIndex) => {
                        const { products } = answerSelected;
                        const newProductsArray = products.map((product) => {
                            newMappedAnswersArray.push({ answer: answerSelected.answer, manufacturerPartNumber: product.manufacturerPartNumber, sectionIndex, questionIndex, answerIndex, productId: product.productId, id: randomId() })
                            return { ...product };
                        })
                        const newManufacturerPartNumbers = (newProductsArray.length === 0) ? newProductsArray : newProductsArray;
                        return { ...answerSelected, products: newManufacturerPartNumbers }
                    }) : ANSWERS_INITIAL_STATE;
                    let newQuestion;
                    if (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_CHECKBOX]) {
                        newQuestion = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray, hasMinOptions: questionSelected.minOptions !== 0, minOptions: questionSelected.minOptions !== 0 ? questionSelected.minOptions : '' };
                    } else {
                        newQuestion = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray };
                    }
                     
                    newQuestionArray.push(newQuestion);
                }
            }
            const newSection = { ...sectionSelected, questions: newQuestionArray };
            newSectionArray.push(newSection);
        }
        // initialize sections accordian state
        const initialExpandSectionsState = newSectionArray.map(({ sectionId }) => ({
			sectionId,
			isExpanded: false
		}));
        setForm({ ...formState, isEdited:false, sections: newSectionArray, expandSections: initialExpandSectionsState, isSendable: true });
    }

    createSalesTrackForm = function (salesTrackForm) {
        const { setForm, formState } = this.context;
        const { salesTrackItem, onEdit } = this.props;
        api.createSalesTrackForm(salesTrackForm, (msg) => {
            setForm({
                ...formState,
                salesTrackForm: msg.salesTrackForm
            });
            onEdit(salesTrackItem);
        }, (msg) => {
            this.onError('Error', msg.error);
        });
    }

    handleFormatDataToPost() {
        const sectionsToFormat = this.context.sections;
        let newSectionArray = [];

        const handleRemoveQuestionIdAndMappedAnswers = (questionToFormat) => {
            const questionFormatted = questionToFormat;
            delete questionFormatted.mappedAnswers;
            !!questionFormatted.questionId && delete questionFormatted.questionId;
            return questionFormatted;
        }

        for (let section of sectionsToFormat) {
            const newQuestionArray = section.questions.map(question => {

                const { answerDataType } = question;
                let newQuestion;
                switch (answerDataType) {
                    case ENUM_DATA_NAME[ENUM_KEY_DATE]:
                        newQuestion = { ...question, fromDate: !!question.from ? question.from.toISOString() : '', toDate: !!question.to ? question.to.toISOString() : '', idQuestion: question.questionId };
                        delete newQuestion.from;
                        delete newQuestion.to;
                        return handleRemoveQuestionIdAndMappedAnswers(newQuestion);
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_TEXT]:
                        return handleRemoveQuestionIdAndMappedAnswers({ ...question, answers: [], idQuestion: question.questionId });
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_NUMBER]:
                        const multipiersToBeMapped = question.multipliers.filter((multiplierSelected) => multiplierSelected.questionId !== '');
                        const newMultipliers = multipiersToBeMapped.map((multiplierSelected) => { return { idQuestion: multiplierSelected.questionId } });
                        newQuestion = { ...question, multipliers: newMultipliers, idQuestion: question.questionId };
                        return handleRemoveQuestionIdAndMappedAnswers(newQuestion);
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_SELECT]:
                    case ENUM_DATA_NAME[ENUM_KEY_RADIO]:
                    case ENUM_DATA_NAME[ENUM_KEY_CHECKBOX]:
                        const questionToBeMapped = question.answers.filter((answer, index) => index < 2 ? true : answer.answer !== '');
                        const newAnswers = questionToBeMapped.map(answerSelected => {
                            const { products, answer, sortOrder } = answerSelected;
                            const newProductsArray = products.length === 1 && products[0].manufacturerPartNumber === '' ? [] : products.map(product => { return { manufacturerPartNumber: product.manufacturerPartNumber } });
                            const newAnswer = { answer, products: newProductsArray, sortOrder };
                            return newAnswer;
                        });
                        const minOptions = question.hasMinOptions ? (question.minOptions || 1) : 0;
                        newQuestion = { ...question, minOptions, answers: newAnswers, idQuestion: question.questionId };
                        newQuestion.answerDataType === ENUM_DATA_NAME[ENUM_KEY_CHECKBOX] && delete newQuestion.hasMinOptions;
                        return handleRemoveQuestionIdAndMappedAnswers(newQuestion);
                        break;
                };
            });
            const newSection = { ...section, questions: newQuestionArray };
            !!newSection.sectionId && delete newSection.sectionId;
            newSectionArray.push(newSection);
        }

        return newSectionArray;
    };

    updateIsSendible(val) {
        const { formState, setForm } = this.context;
        setForm({ ...formState, isSendable: val });
    }

    onSubmitClick() {
        const { formState, setForm } = this.context;
        const canSubmit = this.handleValidateRequiredFields();
        setForm({ ...formState, isSendable: canSubmit });
        if (canSubmit) {
            const { sections } = this.context;
            const { salesTrackItem: { id } } = this.props;
            const newData = {
                idSalesTracks: id,
                sections: this.handleFormatDataToPost(sections),
            }
            this.createSalesTrackForm(newData);
        }
    }

    getDependentQuesNumbersLists = ({sections,currSecQuestions,currSectionIdx}) => {
        const currSectionQuesIds = currSecQuestions.map((question) => question.questionId);
		const otherSectionQuestions = sections
			.filter((_, idx) => idx !== currSectionIdx)
			.flatMap((section) => section.questions);
		const dqDependentQuesNums = new Set();
		const quantityDependentQuesNums = new Set();
        
		otherSectionQuestions.forEach((question) => {
			if (
				[ENUM_KEY_SELECT, ENUM_KEY_RADIO].includes(question.answerDataType) &&
				question.dynamicQuesConditions?.length > 0
			) {
				question.dynamicQuesConditions.forEach((condition) => {
					if (currSectionQuesIds.includes(condition.questionId)) {
						dqDependentQuesNums.add(question.sortOrder);
					}
				});
			}

			if (question.answerDataType === ENUM_KEY_NUMBER && question.multipliers?.length > 0) {
				question.multipliers.forEach((multiplier) => {
					if (currSectionQuesIds.includes(multiplier.questionId)) {
						quantityDependentQuesNums.add(question.sortOrder);
					}
				});
			}
		});

		return {
			dqDependentQuesNumbers: Array.from(dqDependentQuesNums),
			quantityDependentQuesNumbers: Array.from(quantityDependentQuesNums)
		};
    }

    setExpandSections(sectionId, isExpanded){
        const { formActions } = this.context;
		formActions[SET_EXPAND_SECTION]({
			sectionId,
			isExpanded
		});
    }

    handleExpandCollapseAll() {
        const expandAll = !this.state.expandAllSections;
		const { formActions } = this.context;
		this.setState({ expandAllSections: expandAll });
		expandAll ? formActions[EXPAND_ALL_SECTION](): formActions[COLLAPSE_ALL_SECTION]();
    }

    setExpandAllSections (value){
        this.setState({ expandAllSections: value });
    }
    
    render() {
        const { salesTrackItem, onBack, onEdit, theme } = this.props;
        const { palette: { text } } = theme;
        const { sections, isEdited, formActions, isSendable, formState :{ expandSections } } = this.context;
        const breadCrumbsData = {
            actualBreadCrumbName: salesTrackItem?.name + ' - Form Builder ',
            breadCrumbs: [
                {
                    name: 'Sales Tracks',
                    navigate: onBack,
                },
                {
                    name: 'Sales Track Details',
                    navigate: () => onEdit(salesTrackItem),
                }
            ],
        }

        return <>
                <>
                <Box component='div' sx={{ width: '97%', margin: 'auto' }}>
                    <CommonBreadCrumbs {...breadCrumbsData} />
                </Box>
                <Box component='div' className="salesTrackTopBar">
                    <CommonSalesTrackTitle data-testid ={this.props.salesTrackItem?.state ? "test-form-title-active" : "test-form-title-inactive"} variant="h2" className="salesTrack-title">{this.props.salesTrackItem?.name} {this.props.salesTrackItem?.state ? "" : "(Inactive)"} </CommonSalesTrackTitle >
                    <Box component='div' className="createform-header-buttons">
                        <Button id={`createform-cancel-button`} disabled={!isEdited} color="button_primary" variant="outlined" onClick={() => this.onCancel()}>Cancel</Button>
                        <Button id={`createform-save-button`} disabled={!isEdited} color="button_primary" sx={{ color: text.inverse }} variant="contained" onClick={this.onSubmitClick}>Save</Button>
                    </Box>
                </Box>
            </>
            <Grid container item xs={12} direction="row" sx={{ padding: '0 10px' }}>
            <Grid sx={{width:'100%'}}>
                <NotificationToast
                    sx={{pl:{ xl:'11px', lg:'5px'}, width: {xl:"96%", lg:"95%"}}}
                    isOpen={!this.context.isSendable}
                    type={"error"}
                    message={ERROR_MSG_TOAST_NOTIFICATION}
                />
            </Grid>
            </Grid>
            <CardGridContainerStyled container direction="row" rowSpacing='10px' sx={CUSTOM_SX_FORM_BUILDER_CONTAINER}>
                <Grid container item xs={12} direction="row" justifyContent="space-between" sx={{ padding: '0 10px 15px 10px', height: 'fit-content', borderBottom: DIVIDER_STYLE }}>
                    <CommonSalesTrackTitle variant="h3" className="salesTrack-title" sx={{fontSize:'20px'}} >Form Builder</CommonSalesTrackTitle >
                    <FormHeaderActions
							formActions={formActions}
							expandAllSections={this.state.expandAllSections}
							handleExpandCollapseAll={this.handleExpandCollapseAll}
                            expandSections={expandSections}
                            setExpandAllSections={this.setExpandAllSections}
						/>
                </Grid>
                    <SectionContainerStyled sx={{padding:'10px'}}>
					{sections.map((section, index) => {
						const dependentQuestionNumbers = this.getDependentQuesNumbersLists({
							sections,
							currSecQuestions: section.questions,
							currSectionIdx: index
						});
                        const sectionExpandState = expandSections.find(({sectionId}) => section.sectionId === sectionId);
						return (
							<SectionAccordian
                                key={"form-section-" + index}
								id={"form-section-" + index}
								title={section?.name || ""}
								expanded={sectionExpandState?.isExpanded || false}
								setExpanded={( isExpanded ) => this.setExpandSections(section.sectionId, isExpanded)}
							>
								<SectionLayout
									theme={theme}
									isSendable={isSendable}
									onDelete={this.onDelete}
									key={"form-section-" + index}
									sections={sections}
									section={section}
									formActions={formActions}
									sectionIndex={index}
									dependentQuestionNumbers={dependentQuestionNumbers}
								/>
							</SectionAccordian>
						);					
					})}
                    </SectionContainerStyled>
				</CardGridContainerStyled>
			</>
    }
}