import * as React from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { ImageEditor } from "../../imageEditor.jsx";
import { useAppQuoteContext } from "../../../../../context/AppQuoteContext.jsx";
import { PriceModifierHelper } from "../priceModifierHelper.jsx";
import { useEditorContext } from "./context/editorContext.jsx";

const getCommentText = (text) => {
	const brIndex = text.indexOf("<br>");
	const pIndex = text.indexOf("</p><p>");
	if (brIndex == -1) {
		return [text.split("</p><p>").length - 1, "</p><p>"];
	}
	if (pIndex == -1) {
		return [text.split("<br>").length - 1, "<br>"];
	}
	return brIndex > pIndex
		? [text.split("</p><p>").length - 1, "</p><p>"]
		: [text.split("<br>").length - 1, "<br>"];
};

export function destructHTMLstring(str) {
	let cleanString = str;
	const [count, tag] = getCommentText(cleanString);
	if (count >= 1) {
		cleanString = cleanString.substring(0, cleanString.indexOf(tag));
		cleanString = cleanString.replace(/(<([^>]+)>)/gi, "");
		cleanString += "...";
	} else {
		cleanString = cleanString.replace(/(<([^>]+)>)/gi, "");
	}
	return cleanString;
};

const calculatePackageItemQuantity = (tabId) => {
	const rows = getRows(tabId);
	const allPackagesItems = [];

	rows.forEach((row) => {
		if (row.isPackageHeader || row.IsPackageItem) {
			allPackagesItems.push(row);
		}
	});

	const packageItems = [];
	let packageHeaderQuantity = null;
	let packageHeaderId = null;
	for (let i = 0; i < allPackagesItems.length; i++) {
		if (allPackagesItems[i].isPackageHeader) {
			packageHeaderId = allPackagesItems[i].idQuoteItems;
			packageHeaderQuantity = allPackagesItems[i].Quantity;
			continue;
		}

		let quantity = null;
		if (packageHeaderId === allPackagesItems[i].parentQuoteItem) {
			quantity = packageHeaderQuantity * allPackagesItems[i].PackageQty;
			packageItems.push({
				packageItemQuantity: quantity,
				packageItemId: allPackagesItems[i].idQuoteItems
			});
		}

		if (i === allPackagesItems.length - 1 || allPackagesItems[i + 1].isPackageHeader) {
			packageHeaderQuantity = 0;
		}
	}

	return packageItems;
};

const showImageEditor = (
	src,
	field,
	IdQuoteItems,
	IdQuoteTabs,
	setChange,
	ckeditor,
	contentGrid
) => {
	const imgEditorSet = {
		src,
		IdQuoteItems,
		IdQuoteTabs,
		imageId: `ProductEdit_${field}`,
		disabled: false,
		IdQuoteMain: app.currentQuote?.IdQuoteMain,
		sizeInPixels: "64px",
		norescale: false,
		setChange,
		ckeditor,
		contentGrid
	};

	const closeDialog = function () {
		Dialog.close();
		setChange();
	};

	const editor = <ImageEditor {...imgEditorSet} />;
	Dialog.open({
		title: "Edit Image",
		height: "620px",
		width: "760px",
		resizable: true,
		draggable: true,
		onClose: closeDialog,
		links: [{ title: "Cancel", callback: Dialog.close }],
		message: editor
	});
};

export function getRows(tabId, quote) {
	const rows = [];
	const headers = getHeaders(tabId);
	const items = getItems(tabId, quote);

	items.forEach((item, i) => {
		let row = null;
		let first = true;
		if (item.LineType == "Comment") {
			const cleanString = item.ItemNotesHtml || '(click to add a comment)';
			const string = destructHTMLstring(cleanString);
			row = { ItemNotesHtml: string, comment: true, rawItemNotesHtml: item.ItemNotesHtml };
		} else {
			headers.forEach((header) => {
				if (first) {
					row = { [header.FieldName]: item[header.FieldName] };
					first = false;
				} else {
					row[header.FieldName] = item[header.FieldName];
				}
			});
		}
		row.id = i;
		enhanceRowData(item, row);
		if (item.LineType != "Comment") {
			row.ItemNotesHtml = item.ItemNotesHtml;
		}
		rows.push({ ...item, ...row });
	});
	return rows;
}

export function getOutputGridLayoutRows(columns, tabId) {
	const rows = [];
	const items = getItems(tabId);
	items.forEach((item, i) => {
		let row = null;
		let first = true;
		if (item.LineType == "Comment") {
			const cleanString = item.ItemNotesHtml || '(click to add a comment)';
			const string = destructHTMLstring(cleanString);
			row = { ItemNotesHtml: string, comment: true };
		} else {
			columns.forEach((column) => {
				if (item[column.field]) {
					if (first) {
						row = { [column.field]: item[column.field] };
						first = false;
					} else {
						row[column.field] = item[column.field];
					}
				}
			});
		}
		row.id = i;
		enhanceRowData(item, row);
		if (item.LineType != "Comment") {
			row.ItemNotesHtml = item.ItemNotesHtml;
		}
		rows.push({...item, ...row});
	});
	return rows;
}

function handleClickEditNote(row, tabId) {
	sessionStorage.setItem("cpq_open_tab", tabId);
	Dialog.closeAll({ skipAnimation: true });
	app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
	app.currentModule.loadSubModule("product.edit", {
		container: "quoteModule",
		query: `itemid=${row.idQuoteItems}&idquotetabs=${row.idQuoteTabs}`
	});
}

function enhanceRowData(item, row) {
	row.idQuoteItems = item.IdQuoteItems;
	row.idQuoteTabs = item.IdQuoteTabs;
	row.idQuoteMain = item.IdQuoteMain;
	row.isPackageHeader = item.IsPackageHeader;
	row.source = item.Source;
	row.packageQty = item.PackageQty;
	row.parentQuoteItem = item.ParentQuoteItem;
}

export function getAllColumns(
	actionButtons,
	tabId,
	buildNotesDialog,
	handleDateChange,
	handleOptionChange,
	setChange,
	isStandardUserAndProtectedTab,
	canModifyProtectedItem,
	isAdministrator,
	handleModalClick,
	setModalData,
	ckeditor,
	contentGrid
) {
	const { isDisabledQuoteActions } = useEditorContext();
	const { quote, updateQuoteItems } = useAppQuoteContext();
	const gridConfig = quosal.customization.grids.QuoteContent;
	const headers = getHeaders(tabId);
	const cols = [];
	cols.push(actionButtons[0]);
	cols.push(actionButtons[1]);
	const textStyle = { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" };
	const checkboxStyle = { accentColor: "#2E3F80" };
	const items = getItems(tabId, quote);

	const errorMessagePriceModifier =
		"Invalid Price Modifier. Please use M (Markup) G (Gross Margin) L (List Price Discount) or B (Base Price Discount) as your modifier, followed by a number.";
	const errorMessageCostModifier =
		"Invalid Cost Modifier.Please use C as your modifier, followed by a number.";
	const errorMessageRecurringCostModifier =
		"Invalid Recurring Cost Modifier. Please use C as your modifier.";

	const getWarningCell = (value) => (
		<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
			{value}
			<Tooltip title={errorMessagePriceModifier}>
				<WarningAmberIcon style={{ cursor: "pointer", marginLeft: "auto", color: "red" }} />
			</Tooltip>
		</div>
	);

	const totalWidth = headers.reduce((n, {ColumnWidth}) => n + ColumnWidth, 0);

	headers.forEach((item) => {
		const fieldConfig =
			quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName]
				.fieldConfigurations[item.FieldName];
		const isEnabled =
			isStandardUserAndProtectedTab && item.FieldName.toLowerCase() !== "quantity"
				? false
				: isFieldEditable(item, isDisabledQuoteActions) && isFieldDoubleClickable(item);
		const enumeration = quosal.metadata.enums[item.EnumType];
		let isDropdown = false;
		let options = [];
		
		if (enumeration || fieldConfig?.CustomDropdownContents) {
			isDropdown = true;
			options = enumeration || fieldConfig.CustomDropdownContents;
		}

		cols.push({
			field: item.FieldName,
			type: isDropdown ? 'singleSelect' : undefined,
			valueOptions: options,
			getOptionValue: (option) => option?.Value,
  			getOptionLabel: (option) => option?.Label,
			headerName: fieldConfig?.FieldShortName || fieldConfig?.FieldRename || item.DisplayName, 
			flex: item.ColumnWidth / totalWidth * 100,
			minWidth: item.ColumnWidth,
			filterable: true,
			sortable: false,
			editable: isEnabled,
			colSpan: (value, row, column, apiRef) => {
				if (row?.comment) {
					return headers.length;
				}
				return undefined;
			},
			valueGetter: (value, row, column, apiRef) => {
				if (row?.comment) {
					return row.ItemNotesHtml;
				}
				if (!row) {
					return null;
				}
				return row[item.FieldName];
			},
			cellClassName: setValidateCellClass.bind(null, item),
			renderCell: (cellValues) => {
				const itemID = cellValues.row.idQuoteItems;
				const itemData = items.filter((el) => el.IdQuoteItems === itemID)[0];
				const enumeration = quosal.metadata.enums[item.EnumType];
				const isCellDisabled = !isFieldEditable(item, isDisabledQuoteActions);
				const cannotModifyProtectedItem = cellValues.row.IsProtectedItem && !canModifyProtectedItem;

				if (fieldConfig?.CustomDropdownContents) {
					return itemData[item.FieldName];
				}
				if (enumeration && cellValues.value) {
					return enumeration.filter((code) => code.Value == cellValues.value)[0].Label;
				}
				if (cellValues.row.comment) {
					return (
						<Link
							onClick={() => handleClickEditNote(cellValues.row, tabId)}
							style={textStyle}
							href="#"
							underline="none"
						>
							{cellValues.row.ItemNotesHtml}
						</Link>
					);
				}

				switch (item.FieldName) {
					case "PriceModifier":
						if (validateCell(item, cellValues.value)) {
							return (
								<div style={{ display: "flex", alignItems: "center" }}>
									<PriceModifierHelper
										itemCost={itemData?.Cost}
										itemSuggestedPrice={itemData?.SuggestedPrice}
										itemPrice={itemData?.QuoteItemPrice}
										itemBasePrice={itemData?.BasePrice}
										priceModifier={itemData?.PriceModifier}
										idQuoteItems={itemData?.IdQuoteItems}
										idQuoteMain={itemData?.IdQuoteMain}
										cellStyle={{ paddingTop: "5px" }}
										field={item}
										advancedRoundingEnabled={quote.enableAdvancedRounding}
										handleModalClick={handleModalClick}
										setModalData={setModalData}
										isDisabled={cannotModifyProtectedItem || isCellDisabled}
										setChanges={setChange}
										contentGrid={contentGrid}
									/>
									<div style={{ marginLeft: "5px" }}>{cellValues.value}</div>
								</div>
							);
						}
						return getWarningCell(cellValues.value);
					case "RecurringPriceModifier":
						if (validateCell(item, cellValues.value)) {
							return (
								<div style={{ display: "flex", alignItems: "center" }}>
									<PriceModifierHelper
										itemCost={itemData?.RecurringCost}
										itemSuggestedPrice={itemData?.RecurringSuggestedPrice}
										itemPrice={itemData?.RecurringAmount}
										itemBasePrice={itemData?.RecurringBasePrice}
										priceModifier={itemData?.RecurringPriceModifier}
										idQuoteItems={itemData?.IdQuoteItems}
										idQuoteMain={itemData?.IdQuoteMain}
										cellStyle={{ paddingTop: "5px" }}
										field={item}
										advancedRoundingEnabled={quote.enableAdvancedRounding}
										handleModalClick={handleModalClick}
										isDisabled={cannotModifyProtectedItem || isCellDisabled}
										setModalData={setModalData}
										setChange={setChange}
										contentGrid={contentGrid}
									/>
									<div style={{ marginLeft: "5px" }}>{cellValues.value}</div>
								</div>
							);
						}
						return getWarningCell(cellValues.value);
					case "QuoteItemPrice":
					case "OverridePrice":
						if (cellValues.row.PriceModifier === "PRICEOVERRIDE") {
							return (
								<div style={{ fontStyle: "italic", fontWeight: "bold" }}>
									{quote?.formatCurrency(cellValues.value || 0)}
								</div>
							);
						}
						return <>{quote?.formatCurrency(cellValues.value || 0)}</>;
					case "RecurringPrice":
					case "RecurringTotal":
					case "RecurringAmount":
						if (cellValues.row.RecurringPriceModifier === "PRICEOVERRIDE") {
							return (
								<div style={{ fontStyle: "italic", fontWeight: "bold" }}>
									{quote?.formatCurrency(cellValues.value)}
								</div>
							);
						}
						return <>{quote?.formatCurrency(cellValues.value)}</>;
					case "Source":
					case "SourceVendorName":
					case "VendorPartNumber":
					case "Warehouse":
					case "Dimension1":
					case "Dimension2":
					case "Dimension3":
					case "Dimension4":
					case "Dimension5":
					case "ExternalQuoteNumber":
					case "ExternalReference":
					case "CrmReference":
					case "CwClass":
					case "EtilizeSerializedAttributes":
					case "FactorItemMfp":
					case "ItemNumber":
					case "ItemNotes":
					case "SourceManufacturerName":
					case "SourceManufacturerId":
					case "ManufactureInformation":
					case "ProductType":
					case "ProductClass":
					case "ProductCategory":
					case "ProductSubCategory":
					case "GrossMargin":
						return <>{cellValues.value}</>;
					case "Discount":
					case "RecurringDiscount":
					case "TaxRate":
					case "GstRate":
					case "PstRate":
					case "TaxCode":
						return <>{cellValues.value}%</>;
					case "PackageQty":
						if (cellValues.row.isPackageHeader || !cellValues.row.IsPackageItem) {
							cellValues.value = "";
							return <>{cellValues.value}</>;
						}

						return <>{cellValues.value}</>;
					case "Quantity":
						if (cellValues.row.IsPackageItem) {
							const packageItemsQuantity = calculatePackageItemQuantity(tabId);
							packageItemsQuantity.forEach((item) => {
								if (cellValues.row.idQuoteItems === item.packageItemId) {
									return <>{item.packageItemQuantity}</>;
								}
							});
						} else {
							return <>{cellValues.value || 0}</>;
						}
						break;
					case "Cost":
					case "RecurringCost":
					case "SuggestedPrice":
					case "RecurringSuggestedPrice":
					case "ExtendedPrice":
					case "BasePrice":
					case "RecurringBasePrice":
					case "ExtendedCost":
					case "ExtendedSuggestedPrice":
					case "RecurringExtendedCost":
					case "RecurringExtendedPrice":
					case "RecurringExtendedSuggestedPrice":
					case "DiscountAmount":
					case "RecurringDiscountAmount":
					case "RecurringDiscountConverted":
					case "Tax":
					case "RecurringTax":
					case "TaxConverted":
					case "GrossMarginAmount":
					case "GstConverted":
					case "PstConverted":
					case "PackagePrice":
					case "TotalWeight":
					case "NetCost":
					case "FederalGovPrice":
					case "GsaPrice":
					case "StateGovPrice":
					case "StartingCost":
						return <>{quote?.formatCurrency(cellValues.value || 0)}</>;
					case "ItemCube":
					case "ItemHeight":
					case "ItemLength":
					case "ItemWidth":
						return <>{accounting.toFixedAuto(cellValues.value)}</>;
					case "CostModifier":
						return modifierCostComponent(
							item,
							cellValues.value,
							errorMessageCostModifier
						);
					case "RecurringCostModifier":
						return modifierCostComponent(
							item,
							cellValues.value,
							errorMessageRecurringCostModifier
						);
					case "OverridePriceModifier":
					case "RecurringCalculatedPriceModifier":
						return modifierCostComponent(
							item,
							cellValues.value,
							errorMessagePriceModifier
						);
					case "IsTaxable":
					case "IsOptional":
					case "IsHiddenItem":
					case "IsMetadataItem":
					case "IsModTagModified":
					case "IsPhaseItem":
					case "IsPrinted":
					case "IsPromotion":
					case "IsProtectedItem":
					case "IsProtectedPrice":
					case "IsRampable":
					case "IsRebate":
					case "IsRecurringTaxable":
					case "IsSelected":
					case "IsShowPrice":
					case "IsSpecialOrder":
					case "IsTotalsIncluded":
					case "IsDeferrable":
					case "ApprovalOnChange":						
					case "EnforceItemModTags":
					case "PageBreak":
					case "DisableRemaps":
					case "OptionLocked":
						let isDisabled = isCellDisabled;
						if (item.FieldName === "IsHiddenItem" || (item.FieldName === "IsProtectedItem" && !cellValues.row.ParentQuoteItem)) {
							isDisabled = !isAdministrator;
						}
						else if (item.FieldName === "IsProtectedItem" && cellValues.row.ParentQuoteItem) {
							if (isAdministrator) {
								const parentItem = items.find((item) => item.IdQuoteItems === cellValues.row.ParentQuoteItem);
								isDisabled = parentItem.IsProtectedItem;
							}
							else {
								isDisabled = true;
							}
						}
						return (
							<input
								type="checkbox"
								checked={cellValues.value}
								onClick={(event) => {
									//update the UI before Data.Update and Data.ForceUpdate completes								
									updateQuoteItems((items) => {
										const currentItem = items.find((item) => item.IdQuoteItems === cellValues.id);
										currentItem[item.FieldName] = event.target.checked;
										if (item.FieldName === "IsProtectedItem" || item.FieldName === "IsProtectedPrice") {
											if (cellValues.row.childRows) {
												cellValues.row.childRows.forEach((childRow) => {
													const childItem = items.find((item) => item.IdQuoteItems === childRow);
													childItem[item.FieldName] = event.target.checked;
												})
											}
										}
									
										return [...items];
									});									
									//update the column value so Data.Update will receive the correct value
									handleCheckBoxClick(item, cellValues, event)
								}}
								style={checkboxStyle}
								disabled={isDisabled}
							/>
						);
					case "Picture":
					case "Thumbnail":
					case "Z_customPicture1":
					case "Z_customPicture2":
					case "Z_customPicture3":
					case "Z_customPicture4":
						const isNoImage = cellValues.value?.includes("empty") || null;
						const imgSrc = isNoImage ? "images/default-image.png" : cellValues.value;
						const thumbnailStyle = cannotModifyProtectedItem 
							? { pointerEvents: "none" }
							: isCellDisabled
							? { pointerEvents: "none"}
							: {};
						return (
							<div
								className="new-ui-grid-product-img-ctn"
								onClick={cannotModifyProtectedItem ? undefined : isCellDisabled ? undefined : () => {
									showImageEditor(
										imgSrc,
										cellValues.field,
										cellValues.row.idQuoteItems,
										tabId,
										setChange,
										ckeditor,
										contentGrid
									);
								}}
								style={{ ...thumbnailStyle }}
							>
								<img style={{ width: "40px" }} src={imgSrc} />
							</div>
						);
					case "ItemNotesHtml":
						return cannotModifyProtectedItem 
						? <div dangerouslySetInnerHTML={{ __html: destructHTMLstring(cellValues.value) }}></div>
						: isCellDisabled
						? <div dangerouslySetInnerHTML={{ __html: destructHTMLstring(cellValues.value) }}></div>
						: <ItemNotesGridCompontnet
							cellValues={cellValues} item={item} buildNotesDialog={buildNotesDialog}
						/>
					case "ContractStartDate":
					case "ImportedItemDiscountStartDate":
					case "PromotionExpirationDate":
					case "ImportedQuoteDiscountStartDate":
					case "ImportedQuoteDiscountExpirationDate":
					case "ImportedItemDiscountExpirationDate":
					case "RebateExpirationDate":
					case "ModifyDate":
					case "ExternalQuoteExpiration":
						return (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={["DatePicker"]}>
									<MobileDatePicker
										onChange={handleDateChange.bind(null, cellValues)}
										defaultValue={
											(cellValues.value && dayjs(cellValues.value)) || ""
										}
										readOnly={item.FieldName === "ContractStartDate"}
									/>
								</DemoContainer>
							</LocalizationProvider>
						);
					default:
						break;
				}
			}
		});
	});
	return cols;
}

export function getRHSColumns(resultSets) {
	const gridConfig = quosal.customization.grids.QuoteContent;
	const cols = [];
	const checkboxStyle = { accentColor: "#2E3F80" };

	resultSets.forEach((item) => {
		const fieldConfig =
			quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName]
				.fieldConfigurations[item.FieldName];

		cols.push({ 
			field: item.FieldName,
			headerName: fieldConfig?.FieldShortName || fieldConfig?.FieldRename || item.DisplayName, 
			width: item.Width,
			filterable: false,
			sortable: false,
			editable: false,
			sortOrder: item.SortOrder,
			headerAlign: item.TextAlignment,
			align: item.TextAlignment,
			isBold: item.IsBold,
			isItalic: item.IsItalic,
			isUnderline: item.IsUnderline,
			colSpan: (value, row, column, apiRef) => {
				if (row?.comment) {
					return resultSets.length;
				}
				return undefined;
			},
			valueGetter: (value, row, column, apiRef) => {
				if (row?.comment) {
					return row.ItemNotesHtml;
				}
				if (!row) {
					return null;
				}
				return row[item.FieldName];
			},
			cellClassName: setValidateCellClass.bind(null, item),
			renderCell: (cellValues) => {
				if (item.isEditor && cellValues.row.IsPackageItem) {
					switch (item.FieldName) {
						case "Quantity":
						case "LongDescription":
						case "ProductDetails":
						case "ManufacturerPartNumber":
							return <>{cellValues.value}</>;
						case "Thumbnail":
							return <></>;
					}
				} else {
					switch (item.FieldName) {
						case "PriceModifier":
							return (
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ marginLeft: "5px" }}>{cellValues.value}</div>
								</div>
							);
						case "RecurringPriceModifier":
							return (
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ marginLeft: "5px" }}>{cellValues.value}</div>
								</div>
							);
						case "QuoteItemPrice":
						case "OverridePrice":
							if (cellValues.row.PriceModifier === "PRICEOVERRIDE") {
								return (
									<div style={{ fontStyle: "italic", fontWeight: "bold" }}>
										{app.currentQuote?.formatCurrency(cellValues.value || 0)}
									</div>
								);
							}
							return <>{app.currentQuote?.formatCurrency(cellValues.value || 0)}</>;
						case "RecurringPrice":
						case "RecurringTotal":
						case "RecurringAmount":
							if (cellValues.row.RecurringPriceModifier === "PRICEOVERRIDE") {
								return (
									<div style={{ fontStyle: "italic", fontWeight: "bold" }}>
										{app.currentQuote?.formatCurrency(cellValues.value || 0)}
									</div>
								);
							}
							return <>{app.currentQuote?.formatCurrency(cellValues.value || 0)}</>;
						case "Source":
						case "SourceVendorName":
						case "VendorPartNumber":
						case "Warehouse":
						case "Dimension1":
						case "Dimension2":
						case "Dimension3":
						case "Dimension4":
						case "Dimension5":
						case "ExternalQuoteNumber":
						case "ExternalReference":
						case "CrmReference":
						case "CwClass":
						case "EtilizeSerializedAttributes":
						case "FactorItemMfp":
						case "ItemNumber":
						case "ItemNotes":
						case "SourceManufacturerName":
						case "SourceManufacturerId":
						case "ManufactureInformation":
						case "ProductType":
						case "ProductClass":
						case "ProductCategory":
						case "ProductSubCategory":
							return <>{cellValues.value}</>;
						case "Discount":
						case "RecurringDiscount":
						case "TaxRate":
						case "GstRate":
						case "PstRate":
							return <>{cellValues.value}%</>;
						case "TaxCode":
							if (cellValues.row.TaxCode === "PACKAGE" && cellValues.row.InvoiceGroupingRecId == 0) {
								return <>{cellValues.value}</>;
							}
							break;
						case "IdRecurringRevenue":
							break;
						case "Cost":
						case "RecurringCost":
						case "SuggestedPrice":
						case "RecurringSuggestedPrice":
						case "ExtendedPrice":
						case "BasePrice":
						case "RecurringBasePrice":
						case "ExtendedCost":
						case "ExtendedSuggestedPrice":
						case "RecurringExtendedCost":
						case "RecurringExtendedPrice":
						case "RecurringExtendedSuggestedPrice":
						case "DiscountAmount":
						case "RecurringDiscountAmount":
						case "RecurringDiscountConverted":
						case "Tax":
						case "RecurringTax":
						case "TaxConverted":
						case "GrossMarginAmount":
						case "GstConverted":
						case "PstConverted":
						case "PackagePrice":
						case "TotalWeight":
						case "NetCost":
						case "FederalGovPrice":
						case "GsaPrice":
						case "StateGovPrice":
						case "StartingCost":
							return <>{app.currentQuote?.formatCurrency(cellValues.value || 0)}</>;
						case "ItemCube":
						case "ItemHeight":
						case "ItemLength":
						case "ItemWidth":
							return <>{accounting.toFixedAuto(cellValues.value)}</>;
						case "CostModifier":
						case "RecurringCostModifier":
						case "OverridePriceModifier":
						case "RecurringCalculatedPriceModifier":
						case "GrossMargin":
							return <>{cellValues.value}</>;
						case "IsTaxable":
						case "IsOptional":
						case "IsHiddenItem":
						case "IsMetadataItem":
						case "IsModTagModified":
						case "IsPhaseItem":
						case "IsPrinted":
						case "IsPromotion":
						case "IsProtectedItem":
						case "IsProtectedPrice":
						case "IsRampable":
						case "IsRebate":
						case "IsRecurringTaxable":
						case "IsSelected":
						case "IsShowPrice":
						case "IsSpecialOrder":
						case "IsTotalsIncluded":
						case "IsDeferrable":
						case "ApprovalOnChange":
						case "EnforceItemModTags":
						case "PageBreak":
						case "DisableRemaps":
						case "OptionLocked":
							if (cellValues.value) {
								return (
									<input
										type="checkbox"
										defaultChecked
										onClick={(event) =>
											handleCheckBoxClick(item, cellValues, event)
										}
										style={checkboxStyle}
									/>
								);
							}
							return (
								<input
									type="checkbox"
									defaultChecked={false}
									onClick={(event) =>
										handleCheckBoxClick(item, cellValues, event)
									}
									style={checkboxStyle}
								/>
							);
						case "Picture":
						case "Thumbnail":
						case "Z_customPicture1":
						case "Z_customPicture2":
						case "Z_customPicture3":
						case "Z_customPicture4":	
							const isNoImage = cellValues.value.includes("empty");
							const imgSrc = isNoImage ? "images/addNewPhoto.png" : cellValues.value;
							return (
								<div className="new-ui-grid-product-img-ctn">
									<img
										src={imgSrc}
										style={{ width: "40px", height: "inherit" }}
									/>
								</div>
							);
						case "ItemNotesHtml":
							return <>{cellValues.value}</>;
						case "ContractStartDate":
						case "ImportedItemDiscountStartDate":
						case "PromotionExpirationDate":
						case "ImportedQuoteDiscountStartDate":
						case "ImportedQuoteDiscountExpirationDate":
						case "ImportedItemDiscountExpirationDate":
						case "RebateExpirationDate":
						case "ModifyDate":
						case "ExternalQuoteExpiration":
							break;
					}
				}
			}
		});
	});

	const sortedCols = cols.sort((a, b) => a.sortOrder - b.sortOrder);
	return sortedCols;
}

const readOnlyFields = [
	"ImportedItemDiscountStartDate",
	"PromotionExpirationDate",
	"ImportedQuoteDiscountStartDate",
	"ImportedQuoteDiscountExpirationDate",
	"RebateExpirationDate",
	"ModifyDate",
	"ExternalQuoteExpiration",
	"ExtendedPrice",
	"DiscountAmount",
	"RecurringDiscountAmount",
	"RecurringDiscount",
	"Discount",
	"TaxRate",
	"Tax",
	"RecurringTax",
	"GrossMargin",
	"GrossMarginAmount "
];

const isFieldDoubleClickable = (item) => {
	return !(item.DataType === "Boolean" || item.FieldName === "Thumbnail");
}
const isFieldEditable = (item, isDisabledQuoteActions) =>  !isDisabledQuoteActions && !item.ReadOnly && !readOnlyFields.includes(item.FieldName);

const handleCheckBoxClick = (item, cellValues, event) => {
	if (item.DataType === "Boolean") {
		cellValues.row[item.FieldName] = !cellValues.row[item.FieldName];
	} else {
		event.stopPropagation();
	}
};

const modifierCostComponent = (item, value, errorMessage) => {
	if (validateCell(item, value)) {
		return <>{value}</>;
	}
	return (
		<div style={{ display: "flex", alignItems: "end", width: "100%" }}>
			{value}
			<Tooltip title={errorMessage}>
				<WarningAmberIcon style={{ cursor: "pointer", marginLeft: "auto", color: "red" }} />
			</Tooltip>
		</div>
	);
};

const validateCell = (item, value) => {
	const newFullModifierRegex =
		/^(PRICEOVERRIDE|TABDEFAULTS|QUOTEDEFAULTS|((M|G|L|B)-?\d+\.?\d*)?((N|R|U)((-[1-9])|[0-4]))?)$/i;

	switch (item.FieldName) {
		case "CostModifier":
		case "RecurringCostModifier":
			if (value) {
				return value.match(quosal.costModifierRegex);
			}
			return true;
		case "PriceModifier":
		case "RecurringPriceModifier":
		case "OverridePriceModifier":
		case "RecurringCalculatedPriceModifier":
			if (value) {
				return value.match(newFullModifierRegex);
			}
			return true;
		default:
			return true;
	}
};

const setValidateCellClass = (item, params) =>
	validateCell(item, params.value) ? "" : "cellValidationError";

export const getLayoutName = (tabId) => {
	const gridConfig = quosal.customization.grids.QuoteContent;
	const gridLayouts = gridConfig.Configurations;
	let quoteTab = null;
	app.currentQuote?.Tabs.forEach((a) => {
		if (a.IdQuoteTabs === tabId) {
			quoteTab = a;
		}
	});
	if (quoteTab === null) {
		return gridLayouts.Default;
	}
	return gridLayouts[quoteTab?.GridFormat] === undefined
		? gridLayouts.Default
		: gridLayouts[quoteTab?.GridFormat];
};

export const getHeaders = (tabId) => {
	const layout = getLayoutName(tabId);
	const gridConfig = quosal.customization.grids.QuoteContent;
	const gridLayouts = gridConfig.Configurations;
	const possibleDefaultColumns = [
		"Thumbnail",
		"ManufacturerPartNumber",
		"LongDescription",
		"QuoteItemPrice",
		"PriceModifier",
		"Quantity",
		"ExtendedPrice"
	];
	let quoteTab = null;
	app.currentQuote?.Tabs.map((tab) => {
		if (tab.IdQuoteTabs == tabId) {
			quoteTab = tab;
		}
	});
	const columnsToRender =
		gridLayouts[quoteTab?.GridFormat] === undefined
			? layout.Columns.filter((column) => possibleDefaultColumns.includes(column.FieldName))
			: layout.Columns;

	return columnsToRender;
};

export function getItems(tabId, quote) {
	let items = [];
	quote = quote || app.currentQuote;
	const itemsByTabId = quosal.util.getItemsByTabId(quote);
	if (tabId) {
		items = itemsByTabId[tabId] || [];
	} else {
		items = quote?.Items || [];
	}
	return items;
}

export const getGridConfig = (tabId, layoutName) => {
	const gridLayouts = quosal.customization.grids.QuoteContent.Configurations;
	const quoteTab = app.currentQuote?.Tabs.find((tab) => tab.IdQuoteTabs === tabId);
	const layout =
		gridLayouts[quoteTab.GridFormat] == undefined
			? gridLayouts.Default
			: gridLayouts[quoteTab.GridFormat];

	return {
		gridName: "QuoteContent",
		configurationName: layoutName,
		fieldList: "QuoteItems",
		boVersion: layout.BoVersion
	};
};

export const removeZeroQuantityItems = (tabId, setChange, setLoading) => {
	let idQuoteTabs = null;
	let currentTab = null;
	if (tabId) {
		app.currentQuote?.Tabs.map((tab) => {
			if (tab.IdQuoteTabs == tabId) {
				currentTab = tab;
			}
		});
	}

	if (currentTab) {
		idQuoteTabs = currentTab.IdQuoteTabs;
	} else {
		for (let i = 0; i < app.currentQuote?.Tabs.length; i++) {
			app.currentQuote.Tabs[i].isUnsaved = true;
		}
	}

	setLoading && setLoading(true);
	const removeItemsApi = quosal.api.quote.removeZeroQuantityItems(
		app.currentQuote?.IdQuoteMain,
		idQuoteTabs
	);
	removeItemsApi.finished = function (msg) {
		if (msg.quote) {
			app.currentQuote = msg.quote;
			quosal.sell.quote.update(msg.quote);
			setChange();
			setLoading && setLoading(false);
		}
	};

	removeItemsApi.call();
};

function ItemNotesGridCompontnet({ cellValues, item, buildNotesDialog }) {
	const { isDisabledQuoteActions } = useEditorContext();
	const [notes, setNotes] = React.useState(cellValues.value
		? destructHTMLstring(cellValues.value)
		: "(click to add notes)");
	const dialogGridParams = {
		props: {
			row: { IdQuoteItems: cellValues.row.idQuoteItems, isUnsaved: false }
		},
		forceUpdate: () => { }
	};
	const itemNotesHtmlStyle =
	isDisabledQuoteActions
		? { pointerEvents: "none" }
		: {};
	return (
		<a
			className="new-ui-item-notes-html"
			onClick={() => {
				const showNotesDialog = buildNotesDialog(
					dialogGridParams,
					item,
					cellValues.value,
					true,
					setNotes
				);
				showNotesDialog();
			}}
			style={{ ...itemNotesHtmlStyle }}
		>
			{notes}
		</a>
	);
}