import { ATTACHED_DYNAMIC_QUESTION_MESSAGE, COMMON_GRID_PADDING, DATA_TYPE_OPTIONS, DELETE_BUTTON_LINKED_FIELD_MESSAGE, DIVIDER_STYLE, ENUM_DATA_NAME, ENUM_KEY_CHECKBOX, ENUM_KEY_DATE, ENUM_KEY_NUMBER, ENUM_KEY_RADIO, ENUM_KEY_SELECT, INPUT_LABEL_LIMIT_HELPER_TEXT, INPUT_VALUE_LIMIT_HELPER_TEXT, MANDATORY_FIELD_HELPER_TEXT, QUESTION_FIELD_CHARACTER_LIMIT, TOOLTIP_FIELD_CHARACTER_LIMIT, TOOLTIP_LIMIT_HELPER_TEXT } from "../../constants/createFormConstants";
import { Grid, FormControlLabel, Box } from '@mui/material'
import { CommonInputTextStyled, CustomWidthTooltip, IOSSwitch } from "../../../../common/guidedSelling/styledComponents/commonStyled";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CommonSelect } from "../../../../common/guidedSelling/components/commonSelect";
import { QuestionButtonStyled, QuestionGridStyled, QuestionsNumbering } from "../../styledComponents/createFormStyled";
import { OptionTypeForm } from "./dataTypeForms/optionTypeForm";
import { NumberRangeTypeForm } from "./dataTypeForms/numberRangeTypeForm";
import FormContext from "../../contexts/configureFormContext";
import { DateTypeForm } from "./dataTypeForms/DateTypeForm";
import { ConfirmationModal, SingleAccordian } from "../../../../common/guidedSelling/components";
import { allowDynamicQuesFeature } from "../../../../common/guidedSelling/utils";
import { DynamicQuestionMappingContainer } from "../dynamicQuestion";

export class QuestionLayout extends React.Component {
    static contextType = FormContext;

    constructor(props) {
        super(props);

        this.state = {
            inputValueSelectedIndex: '',
            answersRef: [],
            expandedDynamicQuesSection: false,
            isOpenConfirmationModal: false,
            targetInputDataType: '',
        }

        this.handleSelectInputValue = this.handleSelectInputValue.bind(this);
        this.handleChangeMapInput = this.handleChangeMapInput.bind(this);
        this.updateAnswersReference = this.updateAnswersReference.bind(this);
    }

    componentDidMount() {
        this.updateAnswersReference();
    }

    updateAnswersReference() {
        this.setState({ ...this.state, answersRef: this.props.question.answers });
    }

    handleSelectInputValue(event) {
        const value = event.target.value;
        const { question: { answers } } = this.props;
        const isAnswerSelected = (element) => element.sortOrder === value;
        const newInputValueSelectedIndex = answers.findIndex(isAnswerSelected);
        this.setState({ ...this.state, inputValueSelected: value, inputValueSelectedIndex: (newInputValueSelectedIndex), isManufacturerPartNumberValid: true });
    }

    handleChangeMapInput(event) {
        const value = event.target.value;
        this.setState({ ...this.state, mapValue: value, isManufacturerPartNumberValid: true });
    }

    render() {
        const { question: { question, isRequired, isDynamicQuestion, isActive, tooltip, answerDataType, sortOrder, dynamicQuesConditions }, formActions, questionIndex, sectionIndex, onDelete, questions, isSendable, theme } = this.props;
        const {formState: { sections } } = this.context;
        const isDynamicQuesFeatureAllowed = allowDynamicQuesFeature();
		const showDynamicQuesToggle =
			isDynamicQuesFeatureAllowed &&
			[ENUM_DATA_NAME[ENUM_KEY_SELECT], ENUM_DATA_NAME[ENUM_KEY_RADIO]].includes(
				answerDataType
			);
        const confirmationText = (
			<>
				Changing the Data Type would delete dynamic conditions added
				<br />
				for this question permanently. This action cannot be undone.
			</>
		);    

        const handleValidateMandatoryInput = (input) => {
            return !isSendable && input === "";
        }

        const handleErrForTooltip = input => !isSendable && input.length > 100;

        const handleErrForInputLabel = input => !isSendable && input === "" || !isSendable && input.length > 100;
        
        const handleMaxLengthErrForInputValue = input =>  !isSendable && input.length > 75;

		const handleErrForReqInputValue = (input) => (!isSendable && input === "") || handleMaxLengthErrForInputValue(input);

        const handleErrMsgForTooltip = (input) => {
            if(!isSendable) {
                if(input.length > 100){
                    return TOOLTIP_LIMIT_HELPER_TEXT;
                }
            }
        }

        const handleErrMsgForInputLabel = (input) => {
            if(!isSendable) {
                if(input === ""){
                    return MANDATORY_FIELD_HELPER_TEXT;
                }            
                else if( input.length > 100){
                    return INPUT_LABEL_LIMIT_HELPER_TEXT
                }
            }
            return null;
        }

        const handleErrMsgForInputValue = (input, index) => {
            if(!isSendable) {
                if(index < 2){
                    if(input === ""){
                        return MANDATORY_FIELD_HELPER_TEXT;
                    }            
                    else if( input.length > 75 ){
                        return INPUT_VALUE_LIMIT_HELPER_TEXT 
                    }
                }else{
                    if( input.length > 75 ){
                        return INPUT_VALUE_LIMIT_HELPER_TEXT 
                    }
                }
            }
            return null;
        }

        const datatypeFormHandler = (answerDataType) => {
            switch (answerDataType) {
                case ENUM_DATA_NAME[ENUM_KEY_DATE]:
                    return <DateTypeForm  {...this.props} handleValidateMandatoryInput={handleValidateMandatoryInput} />;
                case ENUM_DATA_NAME[ENUM_KEY_NUMBER]:
                    return <NumberRangeTypeForm  {...this.props} handleValidateMandatoryInput={handleValidateMandatoryInput} />;
                case ENUM_DATA_NAME[ENUM_KEY_SELECT]:
                case ENUM_DATA_NAME[ENUM_KEY_RADIO]:
                case ENUM_DATA_NAME[ENUM_KEY_CHECKBOX]:
                    return <OptionTypeForm  {...this.props} handleErrForReqInputValue={handleErrForReqInputValue} handleMaxLengthErrForInputValue={handleMaxLengthErrForInputValue} handleErrMsgForInputValue={handleErrMsgForInputValue} handleValidateMandatoryInput={handleValidateMandatoryInput} answersRef={this.state.answersRef} updateAnswersReference={this.updateAnswersReference} />;
            };
        }

        const handleValidateQuestionID = () => {
            /* Mapped question with Dynamic conditions messages */
            const allQuestions = sections.flatMap((section) => section.questions);
            let qIndex = sortOrder-1;
            const questionId = allQuestions[qIndex]?.questionId;
			const mappedDynamicQuestionsresult = allQuestions?.reduce((acc, question) => {
				const dynamicQuestionConditionsQIds = question?.dynamicQuesConditions?.map(
					(id) => id?.questionId
				);
				dynamicQuestionConditionsQIds?.includes(questionId) &&
					acc.push(question?.sortOrder);
				return acc;
			}, []);

            const linkedFieldResult = allQuestions?.reduce((acc, question) => {
				const linkedFieldIds = question?.multipliers?.map(
					(id) => id?.questionId
				);
				linkedFieldIds?.includes(questionId) &&
					acc.push(question?.sortOrder);
				return acc;
			}, []);
			if (mappedDynamicQuestionsresult.length > 0 && linkedFieldResult.length > 0) {
                return [
					ATTACHED_DYNAMIC_QUESTION_MESSAGE +" "+mappedDynamicQuestionsresult.join(","),
                    <br />,
					DELETE_BUTTON_LINKED_FIELD_MESSAGE + " " +linkedFieldResult.join(",")
				];
			}
            else if (mappedDynamicQuestionsresult.length > 0 && linkedFieldResult.length === 0) {
                return ATTACHED_DYNAMIC_QUESTION_MESSAGE +" "+ mappedDynamicQuestionsresult.join(",");
            }
            else if (mappedDynamicQuestionsresult.length === 0 && linkedFieldResult.length > 0) {
                return DELETE_BUTTON_LINKED_FIELD_MESSAGE+" "+linkedFieldResult.join(",");
            }
            return null;
        }

        const setExpandedDynamicQuesSection = (value) => {
            this.setState({ expandedDynamicQuesSection: value });
        }

        const handleDynamicQuesToggleChange = (event) => {
            formActions["common"](
                event.target.checked,
                event.target.name,
                sectionIndex,
                questionIndex
            )
            const isDynamicQueChecked = event.target.checked;
            if (!isDynamicQueChecked){
                if (dynamicQuesConditions?.length === 0) {
                    setExpandedDynamicQuesSection(isDynamicQueChecked)
                }
             }
             else {
                setExpandedDynamicQuesSection(isDynamicQueChecked)
            } 
        }

        const isMappedWithDynamicQuestion = () => {
            const filterCriteria = questions?.flatMap(data=>data?.dynamicQuesConditions).flatMap(d=>d?.questionId)
            return filterCriteria?.includes(questions[questionIndex]?.questionId);
        }
        
        const showDynamicQuesAccordion = isDynamicQuestion || dynamicQuesConditions?.length > 0;

        const onSelectDataTypeChange = () => {
			formActions["answerDataType"](
				this.state.targetInputDataType,
				sectionIndex,
				questionIndex
			);
			closeConfirmationModal();
		};

        const handleSelectDataTypeChange = (event) => {
			const currentQuestion = sections[sectionIndex].questions[questionIndex];
			if (
				[ENUM_KEY_SELECT, ENUM_KEY_RADIO].includes(currentQuestion.answerDataType) &&
				![ENUM_KEY_SELECT, ENUM_KEY_RADIO].includes(event.target.value) &&
				currentQuestion.dynamicQuesConditions?.length > 0
			) {
				this.setState({
					...this.state,
					targetInputDataType: event.target.value,
					isOpenConfirmationModal: true
				});
			} else {
				this.setState({ ...this.state, targetInputDataType: event.target.value });
				formActions["answerDataType"](event.target.value, sectionIndex, questionIndex);
			}
		};

		const closeConfirmationModal = () => {
			this.setState({ ...this.state, isOpenConfirmationModal: false });
		};

        return <QuestionGridStyled item container xs={12}>
            <Grid item xs={0.3}>
                <QuestionsNumbering>{sortOrder + '.'}</QuestionsNumbering>
            </Grid>
            <Grid item xs={11.7}>
            <Grid item xs={3} p={COMMON_GRID_PADDING}>
                <CommonSelect error={handleValidateMandatoryInput(answerDataType)} helperText={handleValidateMandatoryInput(answerDataType) ? MANDATORY_FIELD_HELPER_TEXT : null} defaultValue='Choose an option' disabled={!isActive} required id={`select-datatype-section-${sectionIndex}-question-${questionIndex}`} label="Select Input Data Type" name='answerDataType' handleChange={handleSelectDataTypeChange} options={DATA_TYPE_OPTIONS} value={answerDataType}         sx={{
                        backgroundColor: 'background.main',
                    }} />
            </Grid>
            <Grid item xs={12} p={COMMON_GRID_PADDING} >
                <CommonInputTextStyled error={handleErrForInputLabel(question)} helperText={handleErrMsgForInputLabel(question)} disabled={!isActive} required id={`question-input-section-${sectionIndex}-question-${questionIndex}`} inputProps={{ maxLength: QUESTION_FIELD_CHARACTER_LIMIT }} name='question' label="Input Label/Question" placeholder='Input Text' value={question} onChange={(event) => formActions['common'](event.target.value, event.target.name, sectionIndex, questionIndex)} />
            </Grid>
            <Grid item xs={12} p={COMMON_GRID_PADDING}>
                <CommonInputTextStyled error={handleErrForTooltip(tooltip)} helperText={handleErrMsgForTooltip(tooltip)} disabled={!isActive} id={`tooltip-input-section-${sectionIndex}-question-${questionIndex}`} inputProps={{ maxLength: TOOLTIP_FIELD_CHARACTER_LIMIT }} name='tooltip' label="Tooltip" placeholder='Input Text' value={tooltip} onChange={(event) => formActions['common'](event.target.value, event.target.name, sectionIndex, questionIndex)} />
            </Grid>
            {answerDataType !== '' && datatypeFormHandler(answerDataType) }
            {showDynamicQuesAccordion &&  (
									<SingleAccordian
                                    id={"dynamic-question-accordian"}
                                    title={"Dynamic Question"}
                                    expanded={this.state.expandedDynamicQuesSection}
                                    setExpanded={setExpandedDynamicQuesSection}
                                >
                                    <DynamicQuestionMappingContainer
                                        currentSectionIndex={sectionIndex}
                                        currentQuestionIndex={questionIndex}
                                        currentQuestion={this.props.question}
                                        dynamicQuestionLists={dynamicQuesConditions}
                                    />
                                </SingleAccordian>
					)}
            <Grid container item xs={12} justifyContent='flex-start' sx={{ borderTop: DIVIDER_STYLE, borderTopStyle: 'dotted', paddingTop: "12px" }}>
                        <FormControlLabel
                            sx={{ marginRight: '32px'}}
                            color="button_primary"
                            control={
                                <IOSSwitch
                                    name='isActive'
                                    id={`isactive-switch-section-${sectionIndex}-question-${questionIndex}`}
                                    checked={isActive}
                                    onChange={(event) => formActions['common'](event.target.checked, event.target.name, sectionIndex, questionIndex)}
                                    color="button_primary" />}
                            label="Active" />
                        <FormControlLabel
                            sx={{ marginRight: '32px'}}
                            color="button_primary"
                            control={
                                <IOSSwitch
                                    name='isRequired'
                                    id={`isrequired-switch-section-${sectionIndex}-question-${questionIndex}`}
                                    checked={isRequired}
                                    onChange={(event) => formActions['common'](event.target.checked, event.target.name, sectionIndex, questionIndex)}
                                    color="button_primary" />}
                            label="Required" />
                    {showDynamicQuesToggle && (
									<FormControlLabel
                                        sx={{ marginRight: '32px'}}
										color="button_primary"
										control={
											<IOSSwitch
												name="isDynamicQuestion"
												id={`isDynamicQuestion-switch-section-${sectionIndex}-question-${questionIndex}`}
												checked={this.props.question.isDynamicQuestion}
												onChange={handleDynamicQuesToggleChange}
												color="button_primary"
											/>
										}
										label="Dynamic Question"
									/>
					)}
                        <CustomWidthTooltip title={!!handleValidateQuestionID() && handleValidateQuestionID()}> 
                                <Box component="div" sx={{marginLeft: "auto"}}  >
                                    <QuestionButtonStyled id={`delete-question-button-section-${sectionIndex}-question-${questionIndex}`} disabled={questions.length <= 1 || isMappedWithDynamicQuestion() ? true : (!!handleValidateQuestionID())} endIcon={<DeleteOutlineOutlinedIcon />} onClick={() => onDelete('Question', () => formActions['remove-question'](sectionIndex, questionIndex))} sx={{ color: theme.palette.button_danger.secondary }} >Delete Question</QuestionButtonStyled>
                                </Box>                               
                        </CustomWidthTooltip>
                </Grid>
            </Grid>
            <ConfirmationModal
				isOpen={this.state.isOpenConfirmationModal}
				handleClose={closeConfirmationModal}
				title="Change the Data Type?"
				body={confirmationText}
				cancelBtnText="Cancel"
				confirmBtnText="Confirm"
				onCancel={closeConfirmationModal}
				onConfirm={onSelectDataTypeChange}
			/>
        </QuestionGridStyled>
    }
}
