import {Form} from "js/jsx/src/classes/forms.jsx";

export class TabEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {     
            isDirty: false
        }; 

        // This binding is necessary to make `this` work in the callback
        this.fieldChanged = this.fieldChanged.bind(this); 
        this.nextTab = this.nextTab.bind(this);
        this.previousTab = this.previousTab.bind(this);
        this.saveAndCloseForm = this.saveAndCloseForm.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.createTab = this.createTab.bind(this);
        this.deleteTab = this.deleteTab.bind(this);
        this.loadProductSearchFormConfigurationEnum = this.loadProductSearchFormConfigurationEnum.bind(this);
    }

    componentDidMount(){
        this.componentIsMounted = true;
    }

    componentWillUnmount(){
        this.componentIsMounted = false;
    }

    fieldChanged(form, panel, input, callback) {
        this.setState({isDirty: true});

        if (callback)
            callback();
    }

    nextTab() {
        var index = app.currentQuote.Tabs.findIndex(s=>s.IdQuoteTabs == this.props.tab.IdQuoteTabs);
        index++;

        if (index >= app.currentQuote.Tabs.length)
            index = 0;

        var nextTab = app.currentQuote.Tabs[index];

        app.currentModule.loadSubModule('tab.edit', {
            container: 'quoteModule',
            query: 'idquotetabs=' + nextTab.IdQuoteTabs
        });
    }

    previousTab() {
        var index = app.currentQuote.Tabs.findIndex(s=>s.IdQuoteTabs == this.props.tab.IdQuoteTabs);
        index--;

        if (index < 0) {
            index = app.currentQuote.Tabs.length - 1;
        }

        var prevTab = app.currentQuote.Tabs[index];

        app.currentModule.loadSubModule('tab.edit', {
            container: 'quoteModule',
            query: 'idquotetabs=' + prevTab.IdQuoteTabs
        });
    }

    saveAndCloseForm(e) {
        this.saveForm(e, true);
    }

    saveForm(e, closeAfterSave) {
        if (quosal.htmlEditorCallback) {
            quosal.htmlEditorCallback();
            delete quosal.htmlEditorCallback;
        }

        var saveForm = function () {
            this.setState({isSaving: true});

            var updatedValues = this.refs.form.getFormValues(true);
            var updatesRequired = 0;
            var updatesCompleted = 0;

            var afterUpdate = function (msg) {
                updatesCompleted++;

                if (msg.error) {
                    Dialog.open({
                        title: 'ERROR',
                        message: msg.error,
                        links: [{
                            title: 'OK',
                            callback: function callback() {
                                var tabNameId = document.getElementById('TabEdit_TabName');
                                tabNameId.scrollIntoView(false);
                                tabNameId.focus();
                                Dialog.close();
                            }
                        }]
                    });
                    this.setState({isDirty: false, isSaving: false});
                    this.refs.form.reset();
                } else if (updatesCompleted >= updatesRequired) {
                    quosal.sell.quote.updateFromApiResponse(msg);

                    if (closeAfterSave === true) {
                        app.currentModule.unloadSubModules();
                        app.currentModule.loadSubModule('quote.content', {
                            container: 'quoteModule'
                        });
                    } else if (this.componentIsMounted) {
                        this.setState({isDirty: false, isSaving: false});
                        this.refs.form.clean();
                    }
                }
            }.bind(this);

            if (updatedValues['IsTaxable'] !== undefined) {
                updatesRequired++;
                var isTaxable = updatedValues['IsTaxable'];
                delete updatedValues['IsTaxable'];

                var setTaxApi = quosal.api.tab.setTaxability(app.currentQuote.IdQuoteMain, this.props.tab.IdQuoteTabs, isTaxable);
                setTaxApi.finished = function (msg) {
                    afterUpdate(msg);
                }.bind(this);
                setTaxApi.call();
            }

            if (updatedValues['RecurringLabel'] !== undefined) {
                updatesRequired++;
                var recurringLabel = updatedValues['RecurringLabel'];
                delete updatedValues['RecurringLabel'];

                var setIdRecurringRevenueApi = quosal.api.tab.setIdRecurringRevenue(app.currentQuote.IdQuoteMain, this.props.tab.IdQuoteTabs, recurringLabel);
                setIdRecurringRevenueApi.finished = function (msg) {
                    afterUpdate(msg);
                }.bind(this);
                setIdRecurringRevenueApi.call();
            }

            if (Object.keys(updatedValues).length > 0) {
                updatesRequired++;

                var updateApi = quosal.api.data.update({
                    fields: updatedValues,
                    queries: [{
                        table: 'QuoteTabs',
                        where: [{
                            field: 'IdQuoteTabs',
                            operator: 'Equals',
                            value: this.props.tab.IdQuoteTabs
                        }]
                    }]
                }, app.currentQuote.IdQuoteMain);
                updateApi.finished = function (msg) {
                    afterUpdate(msg);
                };
                updateApi.call();
            }

        }.bind(this);
        if (this.refs.form.prepareThenSave) {
            this.refs.form.prepareThenSave(saveForm);
        } else {
            saveForm();
        }
    }

    cancelChanges() {
        this.setState({isDirty: false});
        this.refs.form.reset();
    }
    
    createTab(e, lineType) {
        var createApi = quosal.api.data.create({
            table: 'QuoteTabs',
            IdQuoteMain: app.currentQuote.IdQuoteMain,
            TabName: 'New Tab',
            TabNumber: app.currentQuote.Tabs.length + 1
        }, app.currentQuote.IdQuoteMain);
        createApi.finished = function(msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }

            if (msg.results && msg.results.length > 0 && this.componentIsMounted) {
                var newTab = msg.results[0];

                app.currentModule.loadSubModule('tab.edit', {
                    container: 'quoteModule',
                    query: 'idquotetabs=' + newTab.IdQuoteTabs
                });
            }
        }.bind(this);

        var navState = {
            cancelNavigation: function() {this.canceled = true},
            resumeNavigation: function(createApi) {
                createApi.call();
                this.setState({loadingMessage: 'Creating New Tab...'});
            }.bind(this, createApi)
        };

        this.refs.form.onNavigation(navState);

        if (!navState.canceled) {
            navState.resumeNavigation();
        }
    }

    deleteTab() {
        var doDelete = function() {
            var itemIds = [];
            for (var i = 0; i < app.currentQuote.Items.length; i++) {
                if (app.currentQuote.Items[i].IdQuoteTabs == this.props.tab.IdQuoteTabs) {
                    itemIds.push(app.currentQuote.Items[i].IdQuoteItems);
                }
            }

            let deleteApi;
            if (quosal.util.isNewEditorEnabled()) {
                deleteApi = quosal.api.tab.deleteTab(this.props.tab.IdQuoteTabs);
            } else {            
                deleteApi = quosal.api.data.delete([
                    {
                        table: 'QuoteItems',
                        where: [{
                            field: 'IdQuoteItems',
                            operator: 'In',
                            value: itemIds
                        }]
                    },
                    {
                        table: 'QuoteTabs',
                        where: [{
                            field: 'IdQuoteTabs',
                            operator: 'Equals',
                            value: this.props.tab.IdQuoteTabs
                        }]
                    }
                ], app.currentQuote.IdQuoteMain);
            }

            deleteApi.finished = function(msg) {
                Dialog.close({skipAnimation: true});

                quosal.sell.quote.updateFromApiResponse(msg);

                var url = quosal.util.url('quote.dashboard', 'submodules=quote.content');
                quosal.navigation.navigate(url);
            }.bind(this);
            Dialog.setIsWorking(true);
            deleteApi.call();
        }.bind(this);

        const enableTabRestore = quosal.util.isNewEditorEnabled();
		const retentionPeriod = app.settings.global.TabDeletionRetentionPeriod;
        Dialog.confirmDelete({
            message: enableTabRestore ? <span>{'Are you sure you want to delete this tab?'} <br /> {'The tab can only be restored within ' + retentionPeriod + ' days after deletion.'}</span> : 
            'Are you sure you want to delete this tab?',
            callback: doDelete
        });
    }

    loadProductSearchFormConfigurationEnum() {
        var defaultConfigurationName = 'Default';
        var productSearchFormConfigurations = []
        for (var productSearchFormConfigurationName in quosal.customization.forms.ProductSearch.Configurations) {
            if (productSearchFormConfigurationName !== defaultConfigurationName) {
                productSearchFormConfigurations.push(productSearchFormConfigurationName);
            }
        }
        productSearchFormConfigurations.sort();
        productSearchFormConfigurations.unshift('', defaultConfigurationName);
        this.productSearchFormConfigurationsEnum = productSearchFormConfigurations.map(x => ({ Label: x, Value: x }));
    }

    render() {
        if (this.state.loadingMessage) {
            return (
                <FormPlaceholder message={this.state.loadingMessage} />
            );
        }

        var haveProtectedItems = false;
        var items = app.currentQuote.Items;
        if (!quosal.util.userCanModifyProtectedTab()) {
            for (var i = 0; i < items.length; i++) {
                if (items[i].IdQuoteTabs == this.props.tab.IdQuoteTabs && items[i].IsProtectedItem) {
                    haveProtectedItems = true;
                }
            }
        }
        
        var isEditable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly && (!this.props.tab.IsProtectedTab || quosal.util.userCanModifyProtectedTab());
        var isDeletable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly;
        if (!quosal.util.userCanModifyProtectedTab()) {
            if (this.props.tab.IsProtectedTab || haveProtectedItems) {
                isDeletable = false; 
            }
        }

        var topToolbar = { position: 'top' };
        topToolbar.control = <TabEditToolbar key="editToolbar" tab={this.props.tab} form={this} isEditable={isEditable} canDelete={isDeletable} />;

        var bottomToolbar = { position: 'bottom' };
        bottomToolbar.control = <TabEditToolbar key="editToolbar" tab={this.props.tab} form={this} isEditable={isEditable} canDelete={isDeletable} />;

        var formConfiguration = quosal.util.clone(this.props.configuration);
        formConfiguration.Fields = quosal.util.clone(formConfiguration.Fields);
        for (var i = 0; i < formConfiguration.Fields.length; i++) {
            if (formConfiguration.Fields[i].FieldName === 'RtfNotes') {
                formConfiguration.Fields[i] = quosal.util.clone(formConfiguration.Fields[i])
                formConfiguration.Fields[i].FieldName = 'HtmlNotes';
                delete formConfiguration.Fields[i].Value;
            }
            if (formConfiguration.Fields[i].FieldName === 'ProductSearchFormConfiguration') {
                if (!this.productSearchFormConfigurationsEnum) {
                    this.loadProductSearchFormConfigurationEnum();
                }
                formConfiguration.Metadata = quosal.util.clone(formConfiguration.Metadata);
                formConfiguration.Metadata['ProductSearchFormConfiguration'] = this.productSearchFormConfigurationsEnum;
                formConfiguration.Fields[i] = quosal.util.clone(formConfiguration.Fields[i])
                formConfiguration.Fields[i].DataType = 'Enum';
                formConfiguration.Fields[i].EnumType = 'ProductSearchFormConfiguration';
            }

            if ((formConfiguration.Fields[i].FieldName === 'TaxCode' ||
                formConfiguration.Fields[i].FieldName === 'TaxRate' ||
                formConfiguration.Fields[i].FieldName === 'Tax') &&
                app.settings.global.UseAsioTaxService) {
                formConfiguration.Fields[i].ReadOnly = true;
            }
        }

        return (
            <div>
                <Form ref="form" onSubmit={this.saveForm} tab={this.props.tab} disabled={this.state.isSaving || !isEditable} onChange={this.fieldChanged} configuration={formConfiguration} formControls={[topToolbar, bottomToolbar]} />
            </div>
        );
    }
}

class TabEditToolbar extends React.Component {
    render() {
        return (
            <Panel>
                <PanelToolbar style={{display:'block'}}>
                    <CwWidgets.CwButton className="TabDetailsClass" toolTip="Previous Tab" onClick={this.props.form.previousTab} icon="img/svgs//v1.0/Action_Back.svg" />
                    <CwWidgets.CwButton toolTip="Next Tab" onClick={this.props.form.nextTab} icon="img/svgs/v1.0/Action_Forward.svg" />
                    <CwWidgets.CwButton toolTip="Create New Tab" onClick={this.props.form.createTab} icon="img/svgs/v1.0/Action_AddNew.svg" disabled={!this.props.isEditable}/>
                    <CwWidgets.CwButton toolTip="Delete This Tab" onClick={this.props.form.deleteTab} icon="img/svgs/v1.0/Action_Delete.svg" disabled={!this.props.canDelete} />

                    <SaveButton text="Save and Close" style={{float:'right'}} disabled={!this.props.form.state.isDirty || this.props.form.state.isSaving} isSaving={this.props.form.state.isSaving} onClick={this.props.form.saveAndCloseForm} visible={this.props.isEditable} />
                    <SaveButton style={{float:'right', marginRight:10}} disabled={!this.props.form.state.isDirty || this.props.form.state.isSaving} isSaving={this.props.form.state.isSaving} onClick={this.props.form.saveForm} visible={this.props.isEditable} />
                    <Button type="cancel" style={{float:'right', marginRight:10}} disabled={!this.props.form.state.isDirty} onClick={this.props.form.cancelChanges} visible={this.props.isEditable}>Cancel</Button>
                </PanelToolbar>
            </Panel>
        );
    }
}

class TabPriceControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};    
   
        // This binding is necessary to make `this` work in the callback
        this.getFormValues = this.getFormValues.bind(this);
        this.discountAmountChanged = this.discountAmountChanged.bind(this);
        this.priceModeChanged = this.priceModeChanged.bind(this);
    }

    getTab() {
        return app.currentQuote.Tabs.firstOrNull((s)=>s.IdQuoteTabs == quosal.util.queryString('idquotetabs')) || {};
    }

    getFormValues() {
        var tab = this.getTab();
        var values = {};

        if (this.props.type == 'OneTime') {
            values = {
                IsTargetMarkup: tab.IsTargetMarkup,
                IsTargetMargin: tab.IsTargetMargin,
                IsTargetDiscount: tab.IsTargetDiscount,
                IsTargetListDiscount: tab.IsTargetListDiscount,
                TargetMarkup: tab.TargetMarkup,
                TargetMargin: tab.TargetMargin,
                TargetDiscount: tab.TargetDiscount
            };
        } else if (this.props.type == 'Recurring') {
            values = {
                RecurringTargetPriceControlType: tab.RecurringTargetPriceControlType,
                RecurringTargetPriceControlValue: tab.RecurringTargetPriceControlValue
            };
        }

        return values;
    }

    discountAmountChanged(e) {
        var tab = this.getTab();

        if (this.props.type == 'OneTime') {
            if (tab.IsTargetMarkup) {
                tab.TargetMarkup = e.target.value;
            } else if (tab.IsTargetMargin) {
                tab.TargetMargin = e.target.value;
            } else if (tab.IsTargetDiscount) {
                tab.TargetDiscount = e.target.value;
            } else if (tab.IsTargetListDiscount) {
                tab.TargetDiscount = e.target.value;
            }
        } else if (this.props.type == 'Recurring') {
            tab.RecurringTargetPriceControlValue = e.target.value;
        }

        this.props.handleChange(e);
        this.forceUpdate();
    }

    priceModeChanged(e) {
        var tab = this.getTab();
        var selectedMode = e.target.value;

        if (this.props.type == 'OneTime') {
            tab.IsTargetMarkup = false;
            tab.IsTargetMargin = false;
            tab.IsTargetDiscount = false;
            tab.IsTargetListDiscount = false;

            if (selectedMode == 'Markup') {
                tab.IsTargetMarkup = true;
            } else if (selectedMode == 'Gross Margin') {
                tab.IsTargetMargin = true;
            } else if (selectedMode == 'Base Price Discount') {
                tab.IsTargetDiscount = true;
            } else if (selectedMode == 'List Price Discount') {
                tab.IsTargetListDiscount = true;
            }
        } else if(this.props.type == 'Recurring') {
            if (selectedMode == 'Markup') {
                tab.RecurringTargetPriceControlType = quosal.priceModifier.targetPriceControlTypes.TargetMarkup;
            } else if (selectedMode == 'Gross Margin') {
                tab.RecurringTargetPriceControlType = quosal.priceModifier.targetPriceControlTypes.TargetMargin;
            } else if (selectedMode == 'Base Price Discount') {
                tab.RecurringTargetPriceControlType = quosal.priceModifier.targetPriceControlTypes.TargetDiscount;
            } else if (selectedMode == 'List Price Discount') {
                tab.RecurringTargetPriceControlType = quosal.priceModifier.targetPriceControlTypes.TargetListDiscount;
            } else {
                tab.RecurringTargetPriceControlType = quosal.priceModifier.targetPriceControlTypes.Normal;
            }
        }

        this.props.handleChange(e);
        this.forceUpdate();
    }

    render() {
        var tab = this.getTab();
        var priceModes = quosal.priceModifier.priceModes;
        var priceModifierType = this.props.type == "OneTime" ? "PriceModifier" : "RecurringPriceModifier";
        var priceObj = quosal.priceModifier.getPriceValues(tab, priceModifierType);
        var currentMode = priceObj.currentMode;
        var options = [];
        var discountValue = priceObj.discountValue;
        var discountLabel = priceObj.discountLabel;
        var disableInput = this.props.formField.isDisabled();

        for(var i = 0; i < priceModes.length; i++) {
            options.push(<option key={'option' + i} value={priceModes[i]}>{priceModes[i]}</option>);
        }

        var discountControls = <div>
                                    <div className="formfieldlabel"><label htmlFor="TabPriceControlValue" className="formlabel">{discountLabel}</label></div>
                                    <div className="formfield"><input maxLength="80" disabled={disableInput} style={this.props.style} type="text" name="TabPriceControlValue" id="TabPriceControlValue" title="Tab Name" value={discountValue} onChange={this.discountAmountChanged} onKeyPress={this.props.keyPressed} /></div>
                                </div>;

        return (
            <div>
                <div className="formselectfieldwrapper" style={this.props.style}>
                    <select className="formselectfield" disabled={disableInput} name="TabPriceControl" id="TabPriceControl" title="Tab Price Control" value={currentMode} onChange={this.priceModeChanged}>
                        {options}
                    </select>
                </div>
                {currentMode == 'Normal' ? null : discountControls}
            </div>
        )
    }
}
global.TabPriceControl = TabPriceControl;

class TabRoundingControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {          
            currentTab: quosal.util.clone(this.getTab())
        };
        this.getFormValues = this.getFormValues.bind(this); 
        this.getTab = this.getTab.bind(this);
        this.roundingMethodChanged = this.roundingMethodChanged.bind(this);
        this.roundingPrecisionChanged = this.roundingPrecisionChanged.bind(this);
        this.reset = this.reset.bind(this);
    }

    getTab() {
        return app.currentQuote.Tabs.firstOrNull((s)=>s.IdQuoteTabs == quosal.util.queryString('idquotetabs')) || {};
    }

    getFormValues() {
        var values = {};
        if (this.props.type == 'OneTime') {
            values = {
                OnetimeRoundingMethod : this.state.currentTab.OnetimeRoundingMethod,
                OnetimeRoundingPrecision : this.state.currentTab.OnetimeRoundingPrecision
            };        
        } else if (this.props.type == 'Recurring') {
            values = {
                RecurringRoundingMethod : this.state.currentTab.RecurringRoundingMethod,
                RecurringRoundingPrecision : this.state.currentTab.RecurringRoundingPrecision
            };
        }
        return values;
    }

    roundingMethodChanged(e) {
        var selectedMethod =  e.target.value;
        if (this.props.type == 'OneTime') {
            this.setState({ 
                currentTab: {
                    ...this.state.currentTab, 
                    OnetimeRoundingMethod : selectedMethod } 
                });           
        } else if (this.props.type == 'Recurring') {
            this.setState({ 
                currentTab: {
                    ...this.state.currentTab, 
                    RecurringRoundingMethod : selectedMethod } 
            });            
        }
        this.props.handleChange(e);
    }
    
    roundingPrecisionChanged(e) {       
        var precisionValue = e.target.value;
        if (this.props.type == 'OneTime') {
            this.setState({ 
                currentTab: {
                    ...this.state.currentTab, 
                    OnetimeRoundingPrecision : precisionValue } 
            });          
        } else if (this.props.type == 'Recurring') {
            this.setState({ 
                currentTab: {
                    ...this.state.currentTab, 
                    RecurringRoundingPrecision : precisionValue } 
            });         
        }        
        this.props.handleChange(e);
    }

    reset() {
        this.setState({
            currentTab: quosal.util.clone(this.getTab())
        });    
    }

    render() {
        var disableInput = this.props.formField.isDisabled();
        var selectedRoundingMethod = 'Bankers';
        var roundingPrecision = 2;
        var precisionLabel = '';
        var roundingOptions = ['Bankers', 'Standard', 'RoundUp'];
        var roundingMethodOptions = [];
        var precisionOptions = [];
        var roundingFieldsHoverText = "Bankers (default) - Numbers which are equidistant from the two nearest integers are rounded to the nearest even integer"+
            "\nStandard - Increase price value by 1 if the next digit is 5 or more"+
            "\nRoundUp - Increase price value by 1 if there is any value above 0 to the right";
        for(var i=4; i>=-9; i--){
            precisionOptions.push(<option key= {'precisionOption' + i} value = {i}>{i}</option>);
        }        
        for(var i = 0; i < roundingOptions.length; i++) {
            roundingMethodOptions.push(<option key={'option' + i} value={roundingOptions[i]}>{roundingOptions[i]}</option>);
        }

        if (this.props.type =='OneTime') {
            selectedRoundingMethod = this.state.currentTab.OnetimeRoundingMethod;
            roundingPrecision = this.state.currentTab.OnetimeRoundingPrecision;
            precisionLabel = 'One-Time Rounding Precision';
        } else if (this.props.type == 'Recurring') {
            selectedRoundingMethod = this.state.currentTab.RecurringRoundingMethod;
            roundingPrecision = this.state.currentTab.RecurringRoundingPrecision;  
            precisionLabel = 'Recurring Rounding Precision';
        }
        if (!selectedRoundingMethod) {
            selectedRoundingMethod = 'Bankers';
        }
        var precisionControls = <div>
                                  <div className="formfieldlabel"><label htmlFor="TabRoundingPrecisionValue" className="formlabel">{precisionLabel}</label></div>                                  
                                  <div className="formselectfieldwrapper" style={this.props.style}> 
                                   <select className="formselectfield" disabled={disableInput} name="TabRoundingPrecisionValue" id="TabRoundingPrecisionValue" title="Tab Rounding Precision" value={roundingPrecision} onChange={this.roundingPrecisionChanged} maxLength="80">
                                      {precisionOptions}
                                    </select>    
                                   </div>             
                                </div>;
        
        return (<div>
                   <div className="formselectfieldwrapper" style={this.props.style}>
                    <select className="formselectfield" disabled={disableInput} name="TabRoundingControl" id="TabRoundingControl" title={roundingFieldsHoverText} value={selectedRoundingMethod} onChange={this.roundingMethodChanged}>
                    {roundingMethodOptions}
                     </select>
                    </div>
                {precisionControls}
                </div> );
    }
}

global.TabRoundingControl = TabRoundingControl;

class TabTotals extends React.Component {
    render() {
        var tab = app.currentQuote.Tabs.firstOrNull((s)=>s.IdQuoteTabs == quosal.util.queryString('idquotetabs')) || {};

        return (
            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{fontSize:'12px'}}>
                <tbody>
                    <tr>
                        <td className="content">Extended:</td>
                        <td className="rightcontent">{app.currentQuote.formatCurrency(tab.TabTotal)}</td>
                    </tr>
                    <tr>
                        <td className="content">Recurring:</td>
                        <td className="rightcontent">{app.currentQuote.formatCurrency(tab.RecurringTotal)}</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

global.TabTotals = TabTotals;