import { api } from "../api/api";
import {
	Autocomplete,
	Box,
	Grid,
	InputAdornment,
	TextField,
	Typography
} from "@mui/material";
import { DEBOUNCE } from "../constants/common";
import {Search } from "@mui/icons-material";

export class SalesTracksSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchPlaceHolder: "Search input text",
			options: [],
			results: [],
			value: null
		};
	}

	render() {
		const { onFetchResults, setSearchedValue } = this.props;
		const { options, value, searchPlaceHolder } = this.state;
		const {
			palette: { background }
		} = this.props.theme;


		const debouncedFetch = DEBOUNCE((request, callback) => {
			api.getAllSalesTracks(request, (msg) => {
				callback(msg.salesTracks);
			});
		});
		const fetchResults = (request, callback) => debouncedFetch(request, callback);
		const updateSuggestionsList = (InputValue) => {
			fetchResults(InputValue, (fetchedResults) => {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				newOptions =
					!!InputValue && fetchedResults ? [...newOptions, ...fetchedResults] : [];
				this.setState({ ...this.state, options: newOptions });

				if (!InputValue || InputValue.length >= 3) {
					onFetchResults && onFetchResults(fetchedResults);
				}
			});
			this.props?.setFilter?.(InputValue);
		};

		return (
			<>
				<div>
					<Autocomplete
						className="salestrack-search-input"
						fullWidth
						disableClearable
						size="small"
						popupIcon={null}
						freeSolo={true}
						blurOnSelect={true}
						getOptionLabel={(option) => option.name || option}
						filterOptions={(x) => x}
						options={options}
						autoComplete
						variant="outlined"
						includeInputInList
						filterSelectedOptions
						value={value}
						sx={{
							height: "40px",
							width: "266px",
							marginLeft: "auto",
							backgroundColor: background.ghost
						}}
						noOptionsText="No results"
						onChange={(event, newValue) => {
							this.setState({
								...this.state,
								options: newValue.name ? [newValue, ...options] : options,
								value: newValue
							});
							// newValue can be an object or a single string value.
							updateSuggestionsList(newValue.name || newValue);
						}}
						onInputChange={(event, newInputValue) => {
							setSearchedValue && setSearchedValue(newInputValue)
							updateSuggestionsList(newInputValue.name || newInputValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={searchPlaceHolder}
								sx={{
									"& .MuiOutlinedInput-root": {
										border: "1px solid rgba(0, 0, 0, 0.23)",
										borderRadius: "4px"
									}
								}}
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<Box
											sx={{
												width: "10%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<InputAdornment position="end">
													<Search
														sx={{ fontSize: "14px", cursor: "pointer" }}
													/>
											</InputAdornment>
										</Box>
									)
								}}
							/>
						)}
						renderOption={(props, { name }) => {
							return (
								<li {...props}>
									<Grid container alignItems="center">
										<Grid
											item
											sx={{
												width: "calc(100% - 44px)",
												wordWrap: "break-word"
											}}
										>
											<Typography variant="body2" color="text.secondary">
												{name}
											</Typography>
										</Grid>
									</Grid>
								</li>
							);
						}}
					/>
				</div>
			</>
		);
	}
}