import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useEditorContext } from "../context/editorContext";
import { getLayoutName, getGridConfig } from "../Helpers";
import { DataGridCustomizer } from "../../../tables";
import { useSelectedRowContext } from "./SelectedRowContext";
import { useAppQuoteContext } from "../../../../../../context/AppQuoteContext";

const useTabsWrapperContainer = () => {
	const {
		contentGrid,
		ckeditor,
		handleCKEditorSelectedTabId,
		setSelectedTabsToDroppedTabs,
	} = useEditorContext();
	const {quote, quote: { Tabs }, updateQuoteTabs} = useAppQuoteContext();
	const {setSelectedRows} = useSelectedRowContext();
	const [change, setChange] = useState(false);
	const [duplicated, setDuplicated] = useState(false);
	const [layoutChanged, setLayoutChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(getOpenTab());
	const [layoutsName, setLayoutsName] = useState(preparedState());
	const deleteModalRef = useRef();
	const marginModalRef = useRef();
	const newRecordRef = useRef();
	const priceSourceModalRef = useRef();

	const itemIds = useMemo(() => [], []);
	const isEditable = !quote.IsLocked && !app.currentUser.IsReadOnly;
	const lastTab = Tabs.length === 1;

	function getOpenTab() {
		const open_tab = sessionStorage.getItem("cpq_open_tab");
		if (open_tab === null) {
			return Tabs[0].IdQuoteTabs;
		}
		
		if (Tabs.find((tab) => tab.IdQuoteTabs === open_tab) === undefined) {
			return Tabs[0].IdQuoteTabs;
		}

		return open_tab;
	}

	function preparedState() {
		if (Tabs.length) {
			let combinedData = {};
			Tabs.forEach((tab) => {
				const tabId = tab.IdQuoteTabs;
				const layoutName = getLayoutName(tabId);
				combinedData = { ...combinedData, [tabId]: layoutName.ConfigurationName };
			});
			return combinedData;
		}
	}

	const confirmDeletion = useCallback(
		(itemToDelete) => {
			for (let i = 0; i < quote.Items.length; i++) {
				if (quote.Items[i].IdQuoteTabs == itemToDelete) {
					itemIds.push(quote.Items[i].IdQuoteItems);
				}
			}		

			const afterDelete = function (msg) {
				if (msg.error) {
					Dialog.open({
						title: "ERROR",
						message: msg.error,
						links: [
							{
								title: "OK",
								callback: function callback() {
									Dialog.close();
								}
							}
						]
					});
				} else {
					deleteModalRef.current.showModal(false);
				}
			};
			
			const  deleteApi = quosal.api.tab.deleteTab(itemToDelete);
			deleteApi.finished = function (msg) {
				quosal.sell.quote.updateFromApiResponse(msg);
				afterDelete(msg);

				if (open === itemToDelete && quote.Tabs.length !== 1) {
					if (open === quote.Tabs[quote.Tabs.length - 1].IdQuoteTabs) {
						updateOpenTab(quote.Tabs[0].IdQuoteTabs)
					} else {
						const index = quote.Tabs.findIndex(
							(item) => item.IdQuoteTabs === itemToDelete
						);
						updateOpenTab(quote.Tabs[index + 1].IdQuoteTabs);
					}
				} 
				
				ckeditor?.execute?.("deleteProduct", itemToDelete);
				setSelectedTabsToDroppedTabs(app.currentQuuote);
				Dialog.setIsWorking(false);				
			};
			Dialog.setIsWorking(true);
			deleteApi.call();
		},
		[ckeditor, Tabs, itemIds, open]
	);

	const handleDeleteTab = (isShown, id) => {
		const retentionPeriod = app.settings.global.TabDeletionRetentionPeriod;
		const presetData = {
			lastItem: lastTab,
			titleText: lastTab ? "Unable to Delete Tab" : "Confirm Delete Tab",
			confirmationText: lastTab
				? "You can not delete the last tab on this quote."
				: "Are you sure you want to delete this tab? The tab can only be restored within " + retentionPeriod + " days after deletion.",
			deleteCallback: () => confirmDeletion(id)
		};
		deleteModalRef.current.showModal(isShown, presetData);
	};

	const handleDeleteGridRow = (isShown, rows, hasBundle, totalBundle, hasProtectedItem, deleteCallback) => {
		const plural = rows?.length > 1 ? "s" : "";
		const pluralPackage = totalBundle > 1 ? "s" : "";
		const cannotDeleteProtectedItem = hasProtectedItem && rows?.length == 0;
		const presetData = {
			lastItem: cannotDeleteProtectedItem,
			titleText: cannotDeleteProtectedItem ? 'No items selected' : 'Confirm Delete Item' + plural,
			confirmationText: (cannotDeleteProtectedItem ? 'You must select one or more unprotected items for this operation.' : 
				`Are you sure you want to delete ${rows?.length} item${plural}` + 
				(hasBundle ? ', including ' + totalBundle + ' bundle' + pluralPackage : '') + '?' +
				(hasProtectedItem ? ' Protected items will not be deleted.' : '')),
			rows,
			deleteCallback: () => deleteCallback(rows)
		};
		deleteModalRef.current.showModal(isShown, presetData);
	};

	const saveTabOrderChange = function(updatedData) {
		const updates = [];
		for (let i = 0; i < quote.Tabs.length; i++) {
			if (quote.Tabs[i].IdQuoteTabs !== updatedData[i].IdQuoteTabs) {
			const tabNumber = i + 1;
				updates.push({
					fields: {
						TabNumber: tabNumber
					},
					queries: [
						{
							table: "QuoteTabs",
							where: [
								{
									field: "IdQuoteTabs",
									operator: "Equals",
									value: updatedData[i].IdQuoteTabs
								}
							]
						}
					]
				});
			}
		}

		if (updates.length > 0) {
			const updateApi = quosal.api.data.update(updates, quote.IdQuoteMain);
			updateApi.setFlag("fixTabOrder");
			updateApi.finished = function (msg) {
				quosal.sell.quote.updateFromApiResponse(msg);
			};
			updateApi.call();
			return true;
		}
		return false;
	};

	const handleMove = useCallback(
		(sourceId, targetId) => {
			const sourceIndex = Tabs.findIndex((item) => item.id === sourceId);
			const targetIndex = Tabs.findIndex((item) => item.id === targetId);
			const updatedData = [...Tabs];

			const [removed] = updatedData.splice(sourceIndex, 1);
			updatedData.splice(targetIndex, 0, removed);
			updateQuoteTabs(()=>updatedData);
			saveTabOrderChange(updatedData);
		},
		[Tabs]
	);

	const updateOpenTab = (arg) => {
		setOpen(arg);
		handleCKEditorSelectedTabId(arg);
		setSelectedRows([]);
	};

	const gridCallback = useCallback(
		(LayoutName, tabId) => {
			setLayoutsName({ ...layoutsName, [tabId]: LayoutName });
			setLayoutChanged(layoutChanged);
		},
		[layoutChanged, layoutsName]
	);

	const openCustomizeGrid = useCallback(
		(tabId) => {
			const gridConfiguration = getGridConfig(tabId, layoutsName[tabId]);
			const showGridCustomizer = DataGridCustomizer.showDataGridCustomizer.bind(
				null,
				gridConfiguration,
				true,
				() => setChange(!change),
				false,
				tabId,
				() => setChange(!change),
			);
			DataGridCustomizer.executeTabsWrapperCallback = gridCallback;
			showGridCustomizer();
		},
		[change, gridCallback, layoutsName]
	);

	return {
		contentGrid,
		duplicated,
		isEditable,
		lastTab,
		loading,
		open,
		change,
		Tabs,
		deleteModalRef,
		marginModalRef,
		newRecordRef,
		priceSourceModalRef,
		updateOpenTab,
		setChange,
		setDuplicated,
		setLoading,
		handleDeleteTab,
		handleDeleteGridRow,
		handleMove,
		openCustomizeGrid
	};
};

export default useTabsWrapperContainer;
