import { Form } from "js/jsx/src/classes/forms.jsx";
import { GridColumn } from "js/jsx/src/classes/grids.jsx";
import { DataGrid } from "js/jsx/src/classes/tables.jsx";

export class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: this.props.searchResults ?? [],
            searching: this.props.seraching ?? false,
            pageNumber: this.props.pageNumber ?? null,
            resultCount: this.props.resultCount ?? null,
            selectedQuote: this.props.selectedQuote ?? [],
            isExportProcessing: false,
        };
        // This binding is necessary to make `this` work in the callback
        this.resetSearch = this.resetSearch.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.applyTypeSpecificFormProps = this.applyTypeSpecificFormProps.bind(this);
    }
    resetSearch() {
        this.setState({ searchResults: [], pageNumber: null, resultCount: null, isExportProcessing: false, selectedQuote: [] });
        if (this.props.resetSearch) {
            this.props.resetSearch();
        }
    }
    executeSearch() {
        var form = this.refs.criteria.refs.form;
        var searchFields = form.getFields();
        var foreignKeys = searchFields.where(s => s.widgetType == 'ForeignKeyField');
        var searchCriteria = form.getFormValues(true);

        var searchApi = (
            quosal.data.searchAPIs && quosal.data.searchAPIs[this.props.gridConfiguration.gridName]
            && quosal.data.searchAPIs[this.props.gridConfiguration.gridName](searchCriteria)
        )
        if (!searchApi) {
            var where = [];
            for (var field in searchCriteria) {
                var value = searchCriteria[field];

                var fk = foreignKeys.firstOrNull(s => s.props.field.FieldName == field);

                if (fk) {
                    if (!String.isNullOrEmpty(value) && !String.isNullOrEmpty(fk.widgetParameters.foreignSearchField)) {
                        value = value + '%';
                    }

                    where.push({
                        field: field,
                        operator: (String.isNullOrEmpty(value) || String.isNullOrEmpty(fk.widgetParameters.foreignSearchField)) ? 'Equals' : 'Like',
                        value: value,
                        foreignTable: fk.widgetParameters.foreignTable,
                        localKey: fk.widgetParameters.localKey,
                        foreignKey: fk.widgetParameters.foreignKey,
                        foreignSearchField: String.isNullOrEmpty(value) ? null : fk.widgetParameters.foreignSearchField
                    });
                } else {
                    if (typeof value === 'string') {
                        value = value + '%';
                    }

                    where.push({
                        field: field,
                        operator: 'Like',
                        value: value
                    });
                }
            }

            searchApi = quosal.api.data.query({
                table: quosal.customization.grids[this.props.gridConfiguration.gridName].ObjectName,
                where: where
            });
        }

        searchApi.finished = function (msg) {
            var results = [];
            if (msg.resultSets.length > 1) {
                for (var i = 0; i < msg.resultSets.length; i++) {
                    results.splice(-1, 0, ...msg.resultSets[i]);
                }
            } else if (msg.resultSets.length == 1) {
                results = msg.resultSets[0];
            }

            if (this.props.customizeSearchResults) {
                results = this.props.customizeSearchResults(results) || results;
            }

            this.setState({ searchResults: results, searching: false });
        }.bind(this);
        searchApi.call();

        this.setState({ searchResults: [], searching: true });
    }

    componentDidMount() {
        if (this.props.autoSearch) {
            (this.props.executeSearch || this.executeSearch)();
        }
    }
    applyTypeSpecificFormProps(formProps) {
        var objectType = quosal.customization.grids[this.props.gridConfiguration.gridName].ObjectName;
        if (objectType === 'Users') {
            if (!formProps.alwaysDirtyInputValues) {
                formProps.alwaysDirtyInputValues = {};
            }
            quosal.util.mergeObject(formProps.alwaysDirtyInputValues, SearchForm.UsersDefaultSearchTerms);
        }
        return formProps;
    }
    render() {
        var criteriaForm = quosal.customization.forms[this.props.formConfiguration.formName].Configurations[this.props.formConfiguration.configurationName] ||
            quosal.customization.forms[this.props.formConfiguration.formName].Configurations['Default'];
        var resultsGrid = quosal.customization.grids[this.props.gridConfiguration.gridName].Configurations[this.props.gridConfiguration.configurationName] ||
            quosal.customization.grids[this.props.gridConfiguration.gridName].Configurations['Default']

        var executeSearch = this.props.executeSearch || this.executeSearch;

        var title = this.props.title;
        var pageNavigation = null;
        if (this.props.pageSize && this.state.pageNumber) {
            var pageSize = this.props.pageSize,
                pageNumber = this.state.pageNumber,
                resultCount = this.state.resultCount;
            var lastPageNumber = Math.ceil(resultCount / pageSize)
            var pageStart = (pageNumber - 1) * pageSize + 1;
            var pageEnd = pageNumber * pageSize
            var isLastPage = (pageNumber == lastPageNumber);
            if (isLastPage) {
                pageEnd = resultCount;
            }

            var goToPageClick = function (pageToGoTo) {
                this.setState({ searchResults: [], pageNumber: pageToGoTo, selectedQuote: this.state.selectedQuote }, executeSearch);
            }
            
            var goToStartButton = (pageNumber <= 2) ? null :
                <div className="prev" onClick={goToPageClick.bind(this, 1)} >|&lt;&lt;</div>;
            var previousButton = (pageNumber == 1) ? null :
                <div className="prev" onClick={goToPageClick.bind(this, pageNumber - 1)} >&lt; Page {pageNumber - 1}</div>;
            var nextButton = isLastPage ? null :
                <div className="next" onClick={goToPageClick.bind(this, pageNumber + 1)} >Page {pageNumber + 1} &gt;</div>;
            var goToEndButton = (pageNumber >= lastPageNumber - 1) ? null :
                <div className="next" onClick={goToPageClick.bind(this, lastPageNumber)} >&gt;&gt;|</div>;
            var pageNavigation = <div className="paging">
                {goToStartButton}
                {previousButton}
                <div className="resultCount">Viewing results {pageStart} - {pageEnd} of {resultCount}</div>
                {nextButton}
                {goToEndButton}
            </div>;
        }

        var formProps = this.props.formProps ? quosal.util.clone(this.props.formProps) : {};
        formProps.isSearchForm = true;

        formProps = this.applyTypeSpecificFormProps(formProps);

        var isFormEditable = true
        try {
            isFormEditable = quosal.customization.forms[this.props.formConfiguration.formName].Configurations[this.props.formConfiguration.configurationName].IsEditable
        }
        catch (execption) {
            //assume isFormEditable should be true
        }
        formProps.editable = isFormEditable;

        var gridProps = this.props.gridProps ? quosal.util.clone(this.props.gridProps) : {};
        var isGridEditable = true
        try {
            isGridEditable = quosal.customization.grids[this.props.gridConfiguration.gridName].Configurations[this.props.gridConfiguration.configurationName].IsEditable;
        }
        catch (execption) {
            //assume isGridEditable should be true
        }
        gridProps.customizable = isGridEditable;

        // SM 9/19/17 #9243234: A config might be using a field that has been designated private since the config was saved. This removes from search results header.
        if (criteriaForm && resultsGrid.Columns) {
            for (var f = resultsGrid.Columns.length - 1; f >= 0; f--) {
                var fieldName = resultsGrid.Columns[f].FieldName;
                if (quosal.customization.fields[criteriaForm.ObjectType][criteriaForm.ObjectName].isPrivateField[fieldName]) {
                    resultsGrid.Columns.splice(f, 1);
                }
            }
        }

        if (resultsGrid && resultsGrid.Columns && resultsGrid.Columns.length) {
            for (var i = 0; i < resultsGrid.Columns.length; i++) {
                var dataType = resultsGrid.Columns[i].DataType;
                if (dataType === 'String' || dataType === 'Double' || dataType === 'Int32' || dataType == 'DateTime') {
                    resultsGrid.Columns[i].Sortable = true;
                }
            }
        }

        if (this.props.specialColumnRemaps && resultsGrid && resultsGrid.Columns && resultsGrid.Columns.length) {
            resultsGrid = quosal.util.clone(resultsGrid, 1);
            for (var i = 0; i < resultsGrid.Columns.length; i++) {
                var newColumnSource = this.props.specialColumnRemaps[resultsGrid.Columns[i].FieldName];
                if (newColumnSource) {
                    resultsGrid.Columns[i].FieldName = newColumnSource;
                }
            }
        }

        var searchCriteriaProps = this.props.searchCriteriaProps || {};

        const handleSelectAllExportRows = (checked) => {
            /// if checked add all the rows respective page to selectedQuote else remove all the rows of respective page from selectedQuote
           let selectedQuoteIfChecked = [...new Map([...this.state.selectedQuote, ...this.state.searchResults].map(rec=>[rec.QuoteReadableId,rec])).values()]
            this.setState({selectedQuote: checked ? selectedQuoteIfChecked : this.state.selectedQuote.filter((quote) => !this.state.searchResults.includes(quote))})
        }

        return (
            <div className="fluid">
                <SearchCriteria ref="criteria" formConfiguration={criteriaForm}
                    onSearch={this.props.executeSearch || this.executeSearch}
                    onExport={this.props.executeExport || this.executeExport}
                    searching={this.state.searching} resetSearch={this.resetSearch}
                    resultCount={this.state.resultCount}
                    formProps={formProps} searchForm={this} {...searchCriteriaProps}
                />
                <SearchResults ref="results" grid={resultsGrid} results={this.state.searchResults} nonFormattedFields={this.props.nonFormattedFields}
                    searching={this.state.searching} customColumns={this.props.customResultsColumns} gridConfiguration={this.props.gridConfiguration}
                    gridProps={gridProps} resultGridSize={this.props.resultGridSize} title={title} titleChildren={pageNavigation}
                    customContentAboveResults={this.props.customContentAboveResults} 
                    selectAllExportRows={handleSelectAllExportRows}
                    showSortIconsAtBottomRight={this.props.showSortIconsAtBottomRight}
                />
            </div>
        );
    }
}

SearchForm.UsersDefaultSearchTerms = {
    'IsInactive': false,
    'IsApprovalOnly': false
};

class SearchCriteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };

        // This binding is necessary to make `this` work in the callback
        this.resetForm = this.resetForm.bind(this);
    }
    resetForm() {
        this.refs.form.clearFields();
        this.refs.form.clean();

        if (this.props.resetSearch) {
            this.props.resetSearch();
        }
    }
    
    render() {
        var defaultPanel = this.props.formConfiguration.Panels.firstOrNull((s) => !s.CanRemove); //find the default criteria panel

        var firstPanelExtraContent = this.props.firstPanelExtraContent // deliberately undefined if not needed

        var onSearch;
        if (this.props.searchForm.props.pageSize) {
            onSearch = function () {
                this.props.searchForm.setState({ searchResults: [], pageNumber: null, resultCount: null, selectedQuote: [] }, this.props.onSearch);
            }.bind(this);
        } else {
            onSearch = this.props.onSearch;
        }

        var onExport;
        if (this.props.searchForm.props.pageSize) {
            onExport = function () {                
                this.props.searchForm.setState({ pageNumber: this.props.searchForm.state.pageNumber }, this.props.onExport);
            }.bind(this);
        } else {
            onExport = this.props.onExport;
        }

        var panelControls = [];
        if (firstPanelExtraContent) {
            panelControls.push({
                panel: defaultPanel.IdFormPanel,
                position: 'bottom',
                control: (
                    <div key="firstPanelExtra">
                        {firstPanelExtraContent}
                    </div>
                )
            });
        }

        var onExportDialog = ()=> onExportConfirm(
            this.props.searchForm.state.selectedQuote.length > 0 ?
                this.props.searchForm.state.selectedQuote.length :
                this.props.resultCount
            );
    
        var onExportConfirm = function (resultCount) {
        
            Dialog.open({
                title: "Confirm export",
                links: [  {
                    title: 'Export',
                    callback: function () {
                        onExport();
                        Dialog.close();
                    }

                },{ title: "Cancel", callback: Dialog.close }],
                message: `Are you sure you want to export ${resultCount} quote${resultCount > 1 ? "s" : ""}?`
            });
        };
    

        const showExcelExportButton = this.props.searchForm.props.parent === "quoteSearch" && !quosal.util.userCanCopyTabs() && quosal.util.queryString("copyitems") != "true";
        var floatingControls = <div className="search-controls" key="SearchControls" style={{width: !showExcelExportButton ? "200px" : "325px"}}>
            <button key="search" data-cy="searchButton" onClick={onSearch} disabled={(this.props.searching && this.props.searchForm.props.parent != "quoteSearch")  || this.props.searchForm.state.isExportProcessing}>Search</button>
            <button key="reset" data-cy="restButton" onClick={this.resetForm} disabled={(this.props.searching && this.props.searchForm.props.parent != "quoteSearch") || this.props.searchForm.state.isExportProcessing}>Reset</button>
            {showExcelExportButton && 
            <button key="excelexport" data-cy="excelExportButton" style={{ padding: '0px 5px' }} 
                onClick={onExportDialog}
                disabled={(this.props.searchForm.state.searchResults && this.props.searchForm.state.searchResults.length == 0) || this.props.searchForm.state.isExportProcessing || !this.props.searchForm.state.searchResults}>Export to Excel
                {this.props.searchForm.state.isExportProcessing && <Spinner style={{ marginLeft: '3px' }} />}
            </button>}
        </div>;

        var formProps = this.props.formProps || {};
        formProps.alwaysDirtyInputValues = ((formProps.alwaysDirtyInputValues)
            ? quosal.util.mergeObject(formProps.alwaysDirtyInputValues, this.props.alwaysDirtyInputValues)
            : this.props.alwaysDirtyInputValues);

        var numericInputsUseRanges = this.props.numericInputsUseRanges;
        if (this.props.numericInputsUseRanges == null) {
            numericInputsUseRanges = true;
        }

        return (
            <Form configuration={this.props.formConfiguration} onSubmit={this.props.onSearch} ref="form" floatingControls={floatingControls} panelControls={panelControls} noSave={true} {...formProps}
                pickDropdownFirstOption={true} numericInputsUseRanges={numericInputsUseRanges} />
        );
    }
}

export class SearchResults extends React.Component {
    render() {
        var gridProps = this.props.gridProps || {};
        var customizable = (this.props.customizable == null) ? true : this.props.customizable;
        var excludeColumns = ["CrmSalesOrderId", "CrmServiceTicketId", "CrmProjectId"];
        var content = this.props.searching ?
            <FormPlaceholder message="Searching..." /> :
            <DataGrid headers={this.props.grid.Columns} nonFormattedFields={this.props.nonFormattedFields} rows={this.props.results} excludeColumns={excludeColumns}
                configuration={this.props.gridConfiguration} customColumns={this.props.customColumns} customizable={customizable} sortableHeaders={true} selectAllExportRowsCallback={this.props.selectAllExportRows}
                showSortIconsAtBottomRight={this.props.showSortIconsAtBottomRight}
                {...gridProps} />;

        var gridSize = this.props.resultGridSize || '4x3';
        var title = this.props.title || 'Search Results';

        return (
            <GridColumn size={gridSize}>
                <Panel title={title} className="paging" allowFullscreen={true} titleChildren={this.props.titleChildren}>
                    <PanelContent>
                        {this.props.customContentAboveResults || ''}
                        {content}
                    </PanelContent>
                </Panel>
            </GridColumn>
        );
    }
}

class QuickSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };

        // This binding is necessary to make `this` work in the callback
        this.showQuickSearch = this.showQuickSearch.bind(this);
        this.onResultSelected = this.onResultSelected.bind(this);
    }
    showQuickSearch() {
        QuickSearchForm.open({ onSelect: this.onResultSelected, ...this.props });
    }
    onResultSelected(result) {
        this.props.formField.setValue(result);
        QuickSearchForm.close();
    }
    render() {
        var style = this.props.style || {};
        var currentValue = (this.props.formField.state.newValue || this.props.field.Value);
        if (typeof currentValue === 'object') {
            if (this.props.displayNameField) {
                currentValue = currentValue[this.props.displayNameField];
            } else if (currentValue.DisplayName !== undefined) {
                currentValue = currentValue.DisplayName;
            } else {
                currentValue = currentValue.id;
            }
        }

        var isDisabled = this.props.formField.isDisabled();

        return (
            <div className="searchInputWrapperDiv">
                <input title={currentValue} ref="input" type="text" id={this.props.field.FieldName} style={style} readOnly={true}
                    disabled={isDisabled} name={this.props.field.FieldName} value={currentValue} />
                <div style={{margin:'auto'}} className="icon field search" title={this.props.searchTitle || 'Quick Search'} onClick={isDisabled ? null : this.showQuickSearch}></div>
            </div>
        );
    }
}
global.QuickSearchInput = QuickSearchInput;
class QuickSearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gridConfiguration: this.props.gridConfiguration,
            formConfiguration: this.props.formConfiguration,
        };
        // This binding is necessary to make `this` work in the callback
        this.formConfigurationChanged = this.formConfigurationChanged.bind(this);
        this.gridConfigurationChanged = this.gridConfigurationChanged.bind(this);
    }

    static open(params) {
        var gridConfiguration = {
            gridName: params.searchType,
            configurationName: quosal.settings.getValue(params.searchType + '_GridConfiguration') || 'Default'
        };
        gridConfiguration.fieldList = quosal.customization.grids[gridConfiguration.gridName].ObjectName

        var formConfiguration = {
            formName: params.searchType,
            configurationName: quosal.settings.getValue(params.searchType + '_FormConfiguration') || 'Default'
        };

        Dialog.open({
            resizable: true,
            draggable: true,
            title: params.searchTitle || 'Quick Search',
            message: <QuickSearchForm gridConfiguration={gridConfiguration} formConfiguration={formConfiguration} {...params} />,
            height: '80%',
            links: [{
                title: 'Cancel',
                callback: QuickSearchForm.close
            }]
        });
    }

    static close() {
        Dialog.close();
    }

    formConfigurationChanged() {
        this.setState({
            formConfiguration: {
                formName: this.state.formConfiguration.formName,
                configurationName: quosal.settings.getValue(this.state.formConfiguration.formName + '_FormConfiguration') || 'Default'
            }
        });
    }
    gridConfigurationChanged(gridConfig) {
        quosal.customization.grids.changeConfig(gridConfig.GridName, gridConfig.ConfigurationName, function (gridConfig) {
            this.setState({
                gridConfiguration: {
                    gridName: gridConfig.GridName,
                    configurationName: gridConfig.ConfigurationName,
                    fieldList: quosal.customization.grids[gridConfig.GridName].ObjectName
                }
            });
        }.bind(this, gridConfig));
    }
    componentDidMount() {
        quosal.customization.forms.configurationChanged.bind(this.formConfigurationChanged);
        quosal.customization.forms.configurationUpdated.bind(this.formConfigurationChanged);
        quosal.customization.grids.configurationChanged.bind(this.gridConfigurationChanged);
        quosal.customization.grids.configurationUpdated.bind(this.gridConfigurationChanged);
    }
    componentWillUnmount() {
        quosal.customization.forms.configurationChanged.unbind(this.formConfigurationChanged);
        quosal.customization.forms.configurationUpdated.unbind(this.formConfigurationChanged);
        quosal.customization.grids.configurationChanged.unbind(this.gridConfigurationChanged);
        quosal.customization.grids.configurationUpdated.unbind(this.gridConfigurationChanged);
    }
    render() {
        var resultsCustomizer = this.props.form ? this.props.form.props.customizeQuickSearchResults : null;

        var customColumns = [{
            replaceSortableColumn: true,
            createCell: function (row) {
                var onSelect = function (row) {
                    if (this.props.onSelect) {
                        this.props.onSelect(row);
                    }
                    else {
                        QuickSearchForm.close();
                    }
                }.bind(this, row.props.row);

                return <a className={'link'} onClick={onSelect}>Select</a>;
            }.bind(this)
        }];

        return (
            <SearchForm gridConfiguration={this.state.gridConfiguration} formConfiguration={this.state.formConfiguration}
                customResultsColumns={customColumns} customizeSearchResults={resultsCustomizer}
                autoSearch={this.props.autoSearch} />
        );
    }
}

class ForeignKeyField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };

        // This binding is necessary to make `this` work in the callback
        this.teamNameSelected = this.teamNameSelected.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.getValue = this.getValue.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.reset = this.reset.bind(this);
        this.clear = this.clear.bind(this);
    }
    teamNameSelected(e) {
        this.refs.input.value = e.target.value;
        this.props.handleChange(e);
        this.forceUpdate();
    }
    isDirty() {
        return !String.isNullOrEmpty(this.refs.input.value);
    }
    getValue() {
        return this.refs.input.value;
    }
    getFormValues() {
        var values = {};
        values[this.props.formField.props.field.FieldName] = this.getValue();
        return values;
    }
    reset() {
        this.clear();
    }
    clear() {
        this.refs.input.value = null;
    }
    render() {
        var disableInput = this.props.formField.isDisabled();
        var readOnly = this.props.formField.isReadOnly();
        var fieldName = this.props.formField.props.field.FieldName;
        var options = [];
        var teamNames = quosal.customization.teamNames;
        options.push(<option key={'option0'} value=''>{''}</option>);
        for (var i = 0; i < teamNames.length; i++) {
            options.push(<option key={'option' + (i + 1)} value={teamNames[i]}>{teamNames[i]}</option>);
        }

        return (
            <select ref="input" type="text" id={'ForeignKey_' + fieldName} style={this.props.style} disabled={disableInput} readOnly={readOnly}
                name={fieldName} value={this.props.currentValue} onKeyPress={this.props.keyPressed} onChange={this.teamNameSelected} >
                {options}
            </select>
        );
    }
}
global.ForeignKeyField = ForeignKeyField;