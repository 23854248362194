import { Alert, Box, Collapse, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NOTIFICATION_TOAST_COLOR, NOTIFICATION_TOAST_TITLE } from "../constants";
import { useEffect, useState } from "react";

export const NotificationToast = ({ isOpen, type, message, sx = {} }) => {
	const [isOpenState, setIsOpenState] = useState(isOpen);
	// type options = ["warning", "error", "info", "success"];
	const borderLeftColor = NOTIFICATION_TOAST_COLOR[`${type}`];

	useEffect(() => {
		setIsOpenState(isOpen);
	}, [isOpen]);

	const theme = useTheme();
	return (
		<Collapse in={isOpenState} sx={{ marginBottom: "8px", ...sx }}>
			<Alert
				severity={type}
				sx={{
					backgroundColor: theme.palette.background.main,
					color: theme.palette.text.primary,
					borderLeft: `3px solid ${borderLeftColor}`,
					width: "100%",
					height: "62px",
					" & .MuiAlert-message": {
						padding: "7px 0px !important"
					}
				}}
				contrast="normal"
			>
				<Typography
					sx={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					<Box sx={{ fontWeight: "bold" }}>{NOTIFICATION_TOAST_TITLE[`${type}`]}</Box>
					<Box>{message}</Box>
				</Typography>
			</Alert>
		</Collapse>
	);
};
