import {FormFieldInput} from "js/jsx/src/classes/forms.jsx";
import {GridColumn} from "js/jsx/src/classes/grids.jsx";
import {ActionsMenu} from "js/jsx/src/classes/menus.jsx";
import {DateTimeInput} from "js/jsx/src/classes/forms/specialInputs.jsx";
import {getCKEditorTabsIds} from "./newUI/helpers/getCKEditorTabsIds";
import { CPQVirtualEditor } from "./newUI/virtualCkEditor/CPQVirtualEditor";
import { useAppQuoteContext } from "../../../../context/AppQuoteContext";

export const QuotePublish = (props) => {
    const {setNeedsManualSync} = useAppQuoteContext();

    return <QuotePublishInner {...props} setNeedsManualSync={setNeedsManualSync} />;
}
class QuotePublishInner extends React.Component {
    constructor(props) {
        super(props);

        var quote = app.currentQuote;
        var docType = quote.DeliverDocumentType;
        var canPublishOther = app.currentUser.IsAdministrator || app.currentUser.IsPublisher;
        if (!quote.IsTemplate && (!docType || (!canPublishOther && docType !== 'ORDERPORTER' && docType !== 'PDF'))) {
            docType = (quosal.settings.getValue('EmailDefaultDelivery') ? 'PDF' : 'ORDERPORTER');
        }
        var settings = [];
        settings.push('OrderPorterEmailPDF', 'DisableFallbackEmail', 'UseO365');
        var settingsApi = quosal.api.settings.getSettings(settings);
        settingsApi.finished = function (msg) {
            quosal.settings.apply(msg.settings);
        };
        settingsApi.call();

        this.state = {  
            attachedDocuments: quosal.publish.defaultReportList || [],
            attachedVideos: quosal.publish.defaultVideoList || [],
            docType: docType,
            orderPorterTemplate: quote.OrderPorterTemplate,
            orderPorterTemplateValidation: "",
            isEditorOutput: !quote.UseStandardPublishOutput && quosal.util.isNewEditorPreviewContentEnabled(),
            isAutoSavingPdfContent: true
        }; 

        // This binding is necessary to make `this` work in the callback
        this.returnFormToItsList = this.returnFormToItsList.bind(this);
        this.moveFormToAttached = this.moveFormToAttached.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.usingDefaultReportIndexCache = this.usingDefaultReportIndexCache.bind(this);
        this.initializeDefaultReportIndexCache = this.initializeDefaultReportIndexCache.bind(this);
        this.initializeDefaultVideoIndexCache = this.initializeDefaultVideoIndexCache.bind(this);
        this.clearDefaultReportIndexCache = this.clearDefaultReportIndexCache.bind(this);
        this.clearDefaultVideoIndexCache = this.clearDefaultVideoIndexCache.bind(this);
        this.indexInDefaultReportList = this.indexInDefaultReportList.bind(this);
        this.indexInDefaultVideoList = this.indexInDefaultVideoList.bind(this);
        this.removeFromDefaultReportList = this.removeFromDefaultReportList.bind(this);
        this.removeFromDefaultVideoList = this.removeFromDefaultVideoList.bind(this);
        this.addToDefaultReportList = this.addToDefaultReportList.bind(this);
        this.addToDefaultVideoList = this.addToDefaultVideoList.bind(this);
        this.getOrderPorterTemplateText = this.getOrderPorterTemplateText.bind(this);
        this.orderPorterTemplateChange = this.orderPorterTemplateChange.bind(this);
        this.isOrderPorterDeliverOption = this.isOrderPorterDeliverOption.bind(this);
        this.deliverOptionChange = this.deliverOptionChange.bind(this);
        this.removeAllAdhocDocument = this.removeAllAdhocDocument.bind(this);
        this.adHocDocumentAddClick = this.adHocDocumentAddClick.bind(this);
        this.adHocVideoAddClick = this.adHocVideoAddClick.bind(this);
        this.doPublish = this.doPublish.bind(this);
        this.askBeforePublish = this.askBeforePublish.bind(this);
        this.doSave = this.doSave.bind(this);
        this.repairDefaultReportList = this.repairDefaultReportList.bind(this);
        this.repairDefaultVideoList = this.repairDefaultVideoList.bind(this);
        this.onQuoteUpdate = this.onQuoteUpdate.bind(this);
        this.throwDocumentTooLargeForFallbackError = this.throwDocumentTooLargeForFallbackError.bind(this);
        this.onChangeUpdate = this.onChangeUpdate.bind(this);
    }

    static selectAttachmentTab(quoteBlock) {
        if (quoteBlock.hasClass('form')) {
            $('#QuoteTemplatesTab a').click();
        } else if (quoteBlock.hasClass('pdf')) {
            $('#PDFDocumentsTab a').click();
        } else if (quoteBlock.hasClass('etilize')) {
            $('#EtilizeDocsTab a').click();
        } else if (quoteBlock.hasClass('product')) {
            $('#ProductBrochuresTab a').click();
        } else if (quoteBlock.hasClass('literature')) {
            $('#SalesLiteratureTab a').click();
        } else if (quoteBlock.hasClass('videoLib')) {
            $('#VideoLibraryTab a').click();
        }
    }

    isDisabled() {
        if(app.settings.global.ApprovalMethod == 'Multilevel'
            && app.currentQuote.ApprovalStatus == "Approved" && !app.currentQuote.OrderPorterApproved){
            return false;
        } else {
            return app.currentQuote.IsLocked || app.currentUser.IsReadOnly;
        }
    }

    isOnPremUser(){
        return quosal.publish.isOnPremUser;
    }

    attachedDocumentsOrVideosFromRenderedList(isVideo) {
        var attachedDocuments = [];
        var inputs;

        if (this.state.docType === 'DOC' || this.state.docType === 'DOCX') {
            inputs = $('#attachedforms').find($('.quoteformblock.form')).find('input');
        }
        else {
            inputs = (isVideo ? $('#attachedvideos') : $('#attachedforms')).find('input');         
        }

        for (var i = 0; i < inputs.length; i++) {
            attachedDocuments.push($(inputs[i]).attr('name'));
        }

        return attachedDocuments;
    }

    returnFormToItsList(quoteBlock) {
        QuotePublishInner.selectAttachmentTab(quoteBlock);
        window.setTimeout(function (quoteBlock) {
            var isVideo = quoteBlock.hasClass('video');
            var documentId = quoteBlock.find('input').attr('name');
            (isVideo ? this.removeFromDefaultVideoList : this.removeFromDefaultReportList).call(
                this, documentId);
            var isAdHocPDF = quoteBlock.hasClass('adhoc');
            if (isAdHocPDF) {
                this.deleteAdHocDocument(documentId);
            }
        }.bind(this, quoteBlock), 0);

        return false;
    }

    moveFormToAttached(e, ui) {
        var isVideo = $(ui.item).hasClass('video');
        var attachedDocuments = this.attachedDocumentsOrVideosFromRenderedList(isVideo);

        window.setTimeout(function (attachedDocuments, isVideo) {
            var newState = {};
            newState[isVideo ? 'attachedVideos' : 'attachedDocuments'] = attachedDocuments;
            this.setState(newState);
        }.bind(this, attachedDocuments, isVideo), 0);
    }

    beginDrag(e, ui) {
        QuotePublishInner.selectAttachmentTab($(ui.item));
    }

    stopDrag(e, ui) {
        if (ui && ui.item && $(ui.item.parent()).is('#attachedforms, #attachedvideos')) {
            this.moveFormToAttached(e, ui);
        } else {
            this.returnFormToItsList($(ui.item));
        }
        return false;
    }

    usingDefaultReportIndexCache(action) {
        this.initializeDefaultReportIndexCache();
        this.initializeDefaultVideoIndexCache();
        action();
        this.clearDefaultReportIndexCache();
        this.clearDefaultVideoIndexCache();
    }

    initializeDefaultReportIndexCache() {
        this.defaultReportIndexCache = {};
        for (var i in this.state.attachedDocuments) {
            if(this.state.attachedDocuments.hasOwnProperty(i)) {
                this.defaultReportIndexCache[this.state.attachedDocuments[i].toLowerCase()] = i;
            }
        }
    }

    initializeDefaultVideoIndexCache() {
        this.defaultVideoIndexCache = {};
        for (var i in this.state.attachedVideos) {
            if(this.state.attachedVideos.hasOwnProperty(i)) {
                this.defaultVideoIndexCache[this.state.attachedVideos[i]] = i;
            }
        }
    }

    clearDefaultReportIndexCache() {
        delete this.defaultReportIndexCache;
    }

    clearDefaultVideoIndexCache() {
        delete this.defaultVideoIndexCache;
    }

    indexInDefaultReportList(documentId) {
        if (this.defaultReportIndexCache) {
            var cachedIndex = this.defaultReportIndexCache[documentId.toLowerCase()];
            return (cachedIndex == undefined) ? -1 : cachedIndex;
        }

        documentId = documentId.toLowerCase();
        for (var i = 0; i < this.state.attachedDocuments.length; i++) {
            var thisDoc = this.state.attachedDocuments[i]
            if (thisDoc && (thisDoc.toLowerCase() == documentId)) {
                return i;
            }
        }
        return -1;
    }

    indexInDefaultVideoList(videoId) {
        if (this.defaultVideoIndexCache) {
            var cachedIndex = this.defaultVideoIndexCache[videoId];
            return (cachedIndex == undefined) ? -1 : cachedIndex;
        }

        return this.state.attachedVideos.indexOf(videoId);
    }

    removeFromDefaultReportList(documentId) {
        var index = this.indexInDefaultReportList(documentId);
        if (index >= 0) {
            this.state.attachedDocuments.splice(index, 1);
        }
        this.clearDefaultReportIndexCache();
        this.setState({attachedDocuments: this.state.attachedDocuments});
    }

    removeFromDefaultVideoList(videoId) {
        var index = this.indexInDefaultVideoList(videoId);
        if (index >= 0) {
            this.state.attachedVideos.splice(index, 1);
        }
        this.clearDefaultVideoIndexCache();
        this.setState({attachedVideos: this.state.attachedVideos});
    }

    addToDefaultReportList(documentId) {
        this.state.attachedDocuments.push(documentId);
        this.setState({attachedDocuments: this.state.attachedDocuments});
    }

    addToDefaultVideoList(videoId) {
        this.state.attachedVideos.push(videoId);
        this.setState({attachedVideos: this.state.attachedVideos});
    }

    getOrderPorterTemplateText(templateOptions) {
        for (var i = 0; i < templateOptions.length; i++) {
            var templateName = templateOptions[i].value;
            if (templateName == app.currentQuote.OrderPorterTemplate) {
                this.setState({
                    orderPorterTemplateValidation: templateOptions[i].text
                });
                break;
            }
        }                
    }

    orderPorterTemplateChange(selectElement) {
        //TODO saveCatch
        this.setState({
            orderPorterTemplate: selectElement.value,
            orderPorterTemplateValidation: event.target.options[event.target.selectedIndex].text
        });
    }

    isOrderPorterDeliverOption(deliverOption) {
        if (deliverOption == null) {
            deliverOption = this.state.docType;
        }
        return ('ORDERPORTER' === deliverOption) || (!deliverOption && !quosal.settings.getValue('EmailDefaultDelivery'));
    }

    deliverOptionChange(e) {
        //TODO saveCatch
        if ((this.isOrderPorterDeliverOption()
            && !this.isOrderPorterDeliverOption(e.target.value)
            && $('#VideoLibraryTab.ui-tabs-active').length > 0) || e.target.value === 'DOC' || e.target.value === 'DOCX') {
            $('#tabs ul li a').first().click();
        }

        if (e.target.value === 'DOC' || e.target.value === 'DOCX') {
            var inputs = $('#attachedforms').find($('.quoteformblock.form')).find('input');
            var adhocFiles = $('#attachedforms').find($('.quoteformblock.adhoc')).find('input');
            var attachedDocuments = [];
            for (var i = 0; i < inputs.length; i++) {
                attachedDocuments.push($(inputs[i]).attr('name'));
            }
   
            this.setState({ attachedDocuments: attachedDocuments });
            if (adhocFiles && adhocFiles.length > 0) {
                this.removeAllAdhocDocument();
            }
        }
            
        this.setState({
            docType: e.target.value
        });
    }

    removeAllAdhocDocument() {
        Dialog.setIsWorking(true);
        $.ajax(quosal.util.url('removealladhocfiles.quosalweb', 'noheader=yes'), {
            dataType: 'json',
            error: $.quosal.validation.ajax,
            success: function (result) {
                if (result.error) {
                    Dialog.open({
                        title: 'An Error Has Occurred',
                        message: result.error,
                        links: [
                            {
                                title: 'Reload (recommended)',
                                callback: function () {
                                    window.removeEventListener('beforeunload', quosal.events.beforeUnloadFunction);
                                    window.onbeforeunload = null;
                                    window.location.reload();
                                }
                            }, {
                                title: 'Dismiss',
                                callback: Dialog.closeAll
                            }
                        ]
                    })
                }
                else {                
                    $.quosal.validation.clearField($('#attachedforms'));
                }
            },
            complete: function () {
                Dialog.setIsWorking(false);
            }
        });
    }

    adHocDocumentAddClick(e) {
        var docIsValid = false;
        var onDocumentPickerChange = function (e) {
            var fileInput = $(e.target)
            var isPDF = false;
            var filename = fileInput[0].value;
            var length = filename.length;
            if (length >= 4) {
                isPDF = (filename.substring(length - 4).toUpperCase() === '.PDF');
            }
            if (!isPDF) {
                $.quosal.validation.validateField(fileInput, 'error', 'File must be a .pdf');
                docIsValid = false;
                return;
            } else {
                $.quosal.validation.clearField(fileInput);
                docIsValid = true;
            }

            if (window.FileReader && fileInput[0].files && fileInput[0].files[0]) {
                if (fileInput[0].files[0].size > (1024 * 1024 * 20)) {
                    $.quosal.validation.validateField(fileInput, 'error', 'File size cannot exceed 20 MB.');
                    docIsValid = false;
                }
            }
        };

        var csrfToken = $("meta[name=csrf-token]").attr("content");
        var message = (<div id="pdfUploadForm">
            <iframe id="pdfUploadFrame" name="pdfUploadFrame" style={{width:0, height:0, border:'0px solid #fff'}}>
            </iframe>
            <form method="post" encType="multipart/form-data" target="pdfUploadFrame" action={quosal.util.url('uploadadhocpdf.quosalweb', 'noheader=yes&snap=yes')}>
                <input type="hidden" name="csrf-token" value={csrfToken} />
                <div id="fileuploadlabel" ><i>You must choose a ".pdf" file size 20 MB or less.</i></div>
                <div><input onChange={onDocumentPickerChange} type="file" id="filelocation" name="filelocation" accept=".pdf" /></div>
            </form>
        </div>);
        var links = [
            {
                title: 'Attach',
                callback: function () {
                    if (!docIsValid) {
                        return true;
                    }
                    Dialog.setIsWorking();
                    $('#pdfUploadFrame').one('load', function () {
                        var result = $('#pdfUploadFrame').contents().find('body').contents();

                        var displayUploadError = function (message) {
                            $.quosal.validation.validateField($('#adhoc-pdf-attach'), 'error', message);
                            $('#adhoc-pdf-attach').parent().attr('style', 'margin-left:30px;');
                        };

                        if (result.attr('name') == 'filename') {
                            var documentName = result.text();
                            var documentId = documentName + '.pdf.adhoc';
                            if (!quosal.publish.adHocPdfDocuments) {
                                quosal.publish.adHocPdfDocuments = [];
                            }
                            quosal.publish.adHocPdfDocuments.push({
                                DocumentType: 'adhoc',
                                DocumentId: documentId,
                                DocumentName: documentName,
                                ThumbnailUrl: 'images/empty.png',

                            });
                            this.addToDefaultReportList(documentId);
                        } else if (result.attr('name') == 'error') {
                            displayUploadError(result.text());
                        } else if (result.text().indexOf('Maximum request length exceeded') != -1) {
                            displayUploadError('File exceeded the size limit.');
                        } else {
                            displayUploadError('An unknown error occurred in the file upload.');
                        }

                        Dialog.close();
                    }.bind(this));
                    $('#pdfUploadForm form').submit();
                }.bind(this)
            },
            Dialog.links.cancel
        ];

        $.quosal.validation.clearField($('#adhoc-pdf-attach'));
        $('#adhoc-pdf-attach').parent().removeAttr('style');
        Dialog.open({
            title: 'Upload a PDF',
            message: message,
            links: links
        });
    }

    deleteAdHocDocument(documentId) {
        Dialog.setIsWorking(true);
        $.ajax(quosal.util.url('deleteadhocpdf.quosalweb', 'noheader=yes'), {
            dataType: 'json',
            data: { filename: documentId },
            error: $.quosal.validation.ajax,
            success: function (result) {
                if (result.error) {
                    $.quosal.validation.validateField($('#attachedforms'), 'error', result.error);
                } else {
                    $.quosal.validation.clearField($('#attachedforms'));
                }
            },
            complete: function () {
                Dialog.setIsWorking(false);
            }
        });
    }

    adHocVideoAddClick() {
        var doVideoAttach = function (videoUrl) {
            var videoAttachApi = quosal.api.quote.attachPersonalVideo(app.currentQuote.IdQuoteMain, videoUrl);
            videoAttachApi.finished = function (msg) {
                Dialog.close();
                if (msg.quote) {
                    quosal.sell.quote.update(msg.quote);
                }
            };
            videoAttachApi.call();
        }.bind(this);
        var dialogBody = <PersonalVideoAttacher doVideoAttach={doVideoAttach} ></PersonalVideoAttacher>
        Dialog.open({
            title: 'Attach Video',
            message: dialogBody,
            width: '500px',
            draggable: true,
            links: [Dialog.links.close]
        })
    }

    askBeforePublish() {
        var me = this;
        Dialog.open({
            message:  "This quote is in an approved state. Do you wish to re-publish and clear the existing approval?",
            links: [
                {
                    title: 'Yes',
                    callback: function() {
                        me.doPublish();
                        Dialog.close();
                    }
                },
                Dialog.links.no
            ]
        })
    }

    doPublish() {
        var me = this;
        if (this.isDisabled()) {
            return;
        }

        if (app.currentQuote.Items.length === 0) {
            var prepareContent = app.currentQuote.QuoteType || 'Prepare Content';
            Dialog.open({
                title: 'No Items on Quote',
                message: 'You cannot publish an empty quote. Go to ' + prepareContent + ' and add at least one item.',
                links: [{
                    title: 'Go to ' + prepareContent,
                    callback: function () {
                        Dialog.closeAll();
                        app.currentModule.loadSubModule('quote.content', {
                            container: 'quoteModule',
                            unloadSubModules: true
                        });
                    }
                }]
            });
            return;
        }

        if (me.state.isEditorOutput) {
            let documentContent = app.currentQuote.HTMLContentForAutoSaving;
            let currentQuoteTabIds = app.currentQuote.Tabs.map(tab => tab.IdQuoteTabs);
            let droppedTabIds = getCKEditorTabsIds(documentContent);
            let isPreviewPanelPopulatedWithContent = documentContent && documentContent.trim().length > 0;
            let isPreviewContentPopulatedWithMatchedTabs = currentQuoteTabIds.every(id => droppedTabIds.includes(id));

            let dialogTitle = "";
            let dialogMessage = "";
            let dialogLinks = [];
            let prepareContent = app.currentQuote.QuoteType || 'Prepare Content';

            if (!isPreviewPanelPopulatedWithContent) {
                dialogTitle = "No Quote Content";
                dialogMessage = "You cannot publish an empty quote. Go to " + prepareContent + " and add at least one item.";
            }
            else if (!isPreviewContentPopulatedWithMatchedTabs) {
                dialogTitle = "Missing Quote Tabs";
                dialogMessage = "One or more quote tabs are missing from the quote document. Would you like to publish anyway?";
                dialogLinks.push({
                    id: "isEditorOutput-publish",
                    title: 'Publish',
                    callback: function () {
                        Dialog.closeAll();
                        me.doPublishCore();
                    }
                });
            }
            if (dialogTitle && dialogMessage) {
               dialogLinks.unshift(
                   {
                    id: "isEditorOutput-cancel",
                    title: 'Go to ' + prepareContent,
                        callback: function () {
                            Dialog.closeAll();
                            app.currentModule.loadSubModule('quote.content', {
                                container: 'quoteModule',
                                unloadSubModules: true
                            });
                        }
                   }
               );
                Dialog.open({
                    title: dialogTitle,
                    message: dialogMessage,
                    links: dialogLinks,
                });
                return;
            }
        }
        if (app.currentQuote.Terms.length > 0) {
            var GREAT_AMERICA_LEASE_SOURCE = "GreatAmerica";
            var greatAmerica = app.currentQuote.Terms.find(term => term.LeaseSource === GREAT_AMERICA_LEASE_SOURCE);
            if (greatAmerica !== undefined) {
                var tabList = greatAmerica.TabIncludeFilter.split(',');
                var tabs = app.currentQuote.Tabs.filter(tab => tabList.includes(tab.TabName));
                var tabPrincipal = tabs.reduce((acc, t) => t.Subtotal + acc, 0);
                if (greatAmerica.Principal !== tabPrincipal) {
                    var isAdmin = app.currentUser.IsAdministrator || quosal.settings.getValue('GreatAmericaOpenToAll');
                    var message =
                        'The totals on your quote have changed since your GreatAmerica terms were calculated. Your GreatAmerica terms may no longer be valid.';
                    var links = [];
                    if (isAdmin) {
                        links = [
                            {
                                title: 'Re-import GreatAmerica',
                                callback: function() {
                                    Dialog.closeAll();
                                    var url = quosal.util.url('importgreatamerica.quosalweb');
                                    quosal.navigation.navigate(url);
                                }
                            }
                        ];
                    } else {
                        message += ' Please have an administrator re-import GreatAmerica terms before publishing.';
                    }
                    Dialog.open({
                        title: 'Your GreatAmerica terms may no longer be valid',
                        message: message,
                        links: links.concat([
                            {
                                title: 'Cancel',
                                callback: function() {
                                    Dialog.closeAll();
                                    return;
                                }
                            },
                            {
                                title: 'Continue (Not Recommended)',
                                callback: function() {
                                    Dialog.closeAll();
                                    me.doPublishCore();
                                    return;
                                }
                            }
                        ])
                    });
                    return;
                }
            }
        }
        me.doPublishCore();
    }

    throwDocumentTooLargeForFallbackError() {
        var sizeInMB = (parseInt(quosal.deliver.documentFileSize) / 1000000).toFixed(2);
        var errorMessage1 = "Published document size is " + sizeInMB + "MB, which exceeds the maximum email size that can be sent through " + app.productBranding + " (20MB).";
        var errorMessage2 = "Please reduce the size of your published document by removing additional attachments and/or large images."
        Dialog.open({
            title: "Unable To Send Email From " + app.productBranding,
            message: <div>{errorMessage1}<br /><br />{errorMessage2}</div>,
            links: [
                {
                    title: 'OK',
                    callback: () => { Dialog.close();}
                }
            ]
        })
    }

    doCommonPublish() {
        var publishApi = quosal.api.quote.publish({
            idQuoteMain: app.currentQuote.IdQuoteMain,
            isPreview: false,
            saveOnly: false,
            docType: this.state.docType,
            orderPorterTemplate: this.state.orderPorterTemplate,
            attachedDocuments: this.attachedDocumentsOrVideosFromRenderedList(false),
            attachedVideos: this.attachedDocumentsOrVideosFromRenderedList(true),
            updatedTabBooleans: this.updatedTabBooleans,
            useStandardPublishOutput: !this.state.isEditorOutput
        });
        var attachPdfToEmail = quosal.settings.getValue('OrderPorterEmailPDF')?.toLowerCase() === 'true';
        
        publishApi.finished = function (msg) {
            quosal.deliver.file = msg.file;
            quosal.deliver.pdfFileName = msg.pdfFileName;
            quosal.deliver.pdfDownloadLink = msg.pdfDownloadLink;
            quosal.deliver.documentFileSize = msg.documentFileSize;
            quosal.deliver.thumbnails = msg.thumbnails;
            quosal.deliver.error = msg.error;
            quosal.deliver.avataxError = msg.avataxError;
            quosal.deliver.asioTaxServiceError = msg.asioTaxServiceError;
            if (quosal.deliver.docType != "ORDERPORTER" || (quosal.deliver.docType == "ORDERPORTER" && attachPdfToEmail)) {
                if (quosal.deliver.documentFileSize > 20000000) {
                    quosal.deliver.fileTooLargeForFallback = true;
                    this.throwDocumentTooLargeForFallbackError();
                }
            }
            if (this.state.docType == "ORDERPORTER") {
                quosal.deliver.orderPorterURL = msg.orderPorterURL;
            }
            if (quosal.deliver.asioTaxServiceError) {
                Dialog.open({
                    title: 'Error Calculating Taxes',
                    message: quosal.deliver.asioTaxServiceError,
                    links: [Dialog.links.ok]
                })
            }
            if (quosal.deliver.avataxError) {
                var errorLines = quosal.deliver.avataxError.split('<br />');
                var errorMarkup = [errorLines[0]];
                for (var i = 1; i < errorLines.length; i++) {
                    errorMarkup.push(<br key={i} />);
                    errorMarkup.push(errorLines[i]);
                }
                Dialog.open({
                    title: 'Avatax Warning',
                    message: <span>{errorMarkup}</span>,
                    links: [Dialog.links.ok]
                });
            }
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);

                //update url param idQuoteMain to returned quotes idQuoteMain
                if (window.history.replaceState) {
                    var urlParams = new URLSearchParams(window.location.search);
                    urlParams.set("idquotemain", msg.quote.IdQuoteMain)
                    var url = window.location.href.split('?')[0];
                    window.history.replaceState({}, null, url + '?' + urlParams.toString());
                }
                document.getElementById("sendEmailForm").action = quosal.util.url('email.quosalweb');

            } else {
                quosal.sell.quote.update(app.currentQuote);
            }
        }.bind(this);
        publishApi.call();
    }

    doCKEditorPublish() {
        var attachPdfToEmail = quosal.settings.getValue('OrderPorterEmailPDF')?.toLowerCase() === 'true';
        var publishApi = quosal.api.quote.generateCKEditorPDF({
            idQuoteMain: app.currentQuote.IdQuoteMain,
            isPublish: true,
            orderPorterTemplate: this.state.orderPorterTemplate,
            docType: this.state.docType,
            html: "",
            options: {
                format: "A4",
                orientation: "portrait",
                header_html: "",
                footer_html: "",
                header_and_footer_css: "",
                margin_top: "20mm",
                margin_bottom: "20mm",
                margin_right: "12mm",
                margin_left: "12mm"
            }
        });

        publishApi.finished = function (msg) {
            quosal.deliver.file = msg.file;
            quosal.deliver.pdfFileName = msg.pdfFileName;
            quosal.deliver.pdfDownloadLink = msg.pdfDownloadLink;
            quosal.deliver.documentFileSize = msg.documentFileSize;
            quosal.deliver.thumbnails = msg.thumbnails;
            quosal.deliver.error = msg.error;

            if (quosal.deliver.docType != "ORDERPORTER" || (quosal.deliver.docType == "ORDERPORTER" && attachPdfToEmail)) {
                if (quosal.deliver.documentFileSize > 20000000) {
                    quosal.deliver.fileTooLargeForFallback = true;
                    this.throwDocumentTooLargeForFallbackError();
                }
            }
            if (this.state.docType == "ORDERPORTER") {
                quosal.deliver.orderPorterURL = msg.orderPorterURL;
            }
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);

                //update url param idQuoteMain to returned quotes idQuoteMain
                if (window.history.replaceState) {
                    var urlParams = new URLSearchParams(window.location.search);
                    urlParams.set("idquotemain", msg.quote.IdQuoteMain)
                    var url = window.location.href.split('?')[0];
                    window.history.replaceState({}, null, url + '?' + urlParams.toString());
                }
                document.getElementById("sendEmailForm").action = quosal.util.url('email.quosalweb');

            } else {
                quosal.sell.quote.update(app.currentQuote);
            }
        }.bind(this);
        publishApi.call();
    }

    doPublishCore() {
        var docType = this.state.docType;
        this.setState({ isPublishing: true });
        quosal.deliver = {
            publishFinishedEvent: quosal.events.create(),
            docType: docType,
            primaryRepO365EmailAddress: quosal.publish.primaryRepO365EmailAddress,
            insideRepO365EmailAddress: quosal.publish.insideRepO365EmailAddress,
            orderPorterTemplate: this.state.orderPorterTemplate
        };
        if (this.state.docType == "ORDERPORTER") {
            quosal.deliver.emailSubject = quosal.publish.emailSubject;
        } else {
            quosal.deliver.emailSubject = quosal.publish.emailSubjectNoOrderPorter;
        }
        app.currentModule.loadSubModule('quote.deliver', {
            container: 'quoteModule',
            query: quosal.util.updateQueryString({ idquotemain: app.currentQuote.IdQuoteMain })
        });
        
        this.props.setNeedsManualSync(false);
        const isEditorFeatureEnabled = quosal.util.isNewEditorEnabled();

        if (isEditorFeatureEnabled && this.state.isEditorOutput && (docType === "ORDERPORTER" || docType === "PDF" || docType === 'DOCUSIGN')) {
            this.doCKEditorPublish();
        }
        else {
            this.doCommonPublish();
        }
    }

    doSave() {
        if (this.isDisabled()) {
            return;
        }

        this.setState({isSaving: true});
        var publishApi = quosal.api.quote.publish({
            idQuoteMain: app.currentQuote.IdQuoteMain,
            isPreview: false,
            saveOnly: true,
            docType: this.state.docType,
            orderPorterTemplate: this.state.orderPorterTemplate,
            attachedDocuments: this.attachedDocumentsOrVideosFromRenderedList(false),
            attachedVideos: this.attachedDocumentsOrVideosFromRenderedList(true),
            updatedTabBooleans: this.updatedTabBooleans,
            useStandardPublishOutput: !this.state.isEditorOutput
        });
        publishApi.finished = function (msg) {
            if(msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            this.setState({isSaving: false});
            quosal.sell.quote.showDocumentPreviewer( !this.state.isEditorOutput && quosal.util.shouldDocumentPreviewBeOpen());
        }.bind(this);
        publishApi.call();
    }

    repairDefaultReportList(publishDocuments, fileExtension) {
        publishDocuments = publishDocuments || [];
        for (var j = 0; j < publishDocuments.length; j++) {
            var publishDocument = publishDocuments[j];
            var documentId = publishDocument.DocumentId.toLowerCase();
            var cachedName = documentId;
            var indexInDefaultReportList = this.indexInDefaultReportList(cachedName);
            if (indexInDefaultReportList < 0) {
                cachedName = publishDocument.DocumentName.toLowerCase();
                indexInDefaultReportList = this.indexInDefaultReportList(cachedName);
            }
            if (indexInDefaultReportList < 0 && documentId.endsWith(fileExtension)) {
                cachedName = documentId.substring(0, documentId.length - fileExtension.length);
                indexInDefaultReportList = this.indexInDefaultReportList(cachedName);
            }

            if (indexInDefaultReportList >= 0) {
                delete this.defaultReportIndexCache[cachedName];
                this.defaultReportIndexCache[documentId] = indexInDefaultReportList;
                this.state.attachedDocuments[indexInDefaultReportList] = //documentId;
                                                                         publishDocument.DocumentId;
            }
        }
    }

    repairDefaultVideoList() {
        if (this.state.attachedVideos.length == 0) {
            if (quosal.publish.videos && quosal.publish.videos.length) {
                for (var i = 0; i < quosal.publish.videos.length; i++) {
                    var iVideo = quosal.publish.videos[i];
                    if (iVideo.IsGlobalDefault) {
                        this.state.attachedVideos.push(iVideo.IdQuoteVideos);
                    }
                }
            }
        } else if (this.state.attachedVideos.length == 1 && this.state.attachedVideos[0] == 'none') { // QuoteVideos.EMPTY_VIDEO_LIST = "none"
            this.state.attachedVideos = [];
        } else {
            var attachedVideoListNeedsUpdate = false;
            var isVideoDisabled = {};
            for (var i = 0; i < this.state.attachedVideos.length; i++) {
                isVideoDisabled[this.state.attachedVideos[i]] = true;
            }
            if (quosal.publish.videos && quosal.publish.videos.length) {
                for (var i = 0; i < quosal.publish.videos.length; i++) {
                    var iVideo = quosal.publish.videos[i];
                    if (isVideoDisabled[iVideo.IdQuoteVideos] && !iVideo.IsDisabled) {
                        isVideoDisabled[iVideo.IdQuoteVideos] = false;
                        attachedVideoListNeedsUpdate = true;
                    }
                }
            }
            if (attachedVideoListNeedsUpdate) {
                var newAttachedVideos = [];
                for (var i = 0; i < this.state.attachedVideos.length; i++) {
                    var idVideo = this.state.attachedVideos[i];
                    if (!isVideoDisabled[idVideo]) {
                        newAttachedVideos.push(idVideo);
                    }
                }
                this.state.attachedVideos = newAttachedVideos;
            }
        }
    }

    deletePersonalVideoClick() {
        var doDelete = function () {
            Dialog.setIsWorking();
            var videoDeleteApi = quosal.api.quote.deletePersonalVideo(app.currentQuote.IdQuoteMain);
            videoDeleteApi.finished = function (msg) {
                if (msg.quote) {
                    quosal.sell.quote.update(msg.quote);
                }
                Dialog.close();
            };
            videoDeleteApi.call();
        };
        Dialog.confirmDelete({
            message: 'Are you sure you want to remove your personal video?',
            callback: doDelete
        });
    }

    static expirationDateCheck() {
        if (quosal.publish.quoteIsExpired && !app.currentQuote.IsTemplate) {
            Dialog.open({
                title: 'Quote is Expired',
                message: <QuoteExpiredCorrectionDialog />,
                closeRequiresButton: true,
                links: [
                    {
                        title: 'Update Expiration Date',
                        callback: function () {
                            let updates = [{
                                fields: {
                                    ExpirationDate: $('#quoteExpiredCorrectionDialogDateTimeInput').val()
                                },
                                queries: [{
                                    table: 'QuoteMain',
                                    where: [{
                                        field: 'IdQuoteMain',
                                        operator: 'Equals',
                                        value: app.currentQuote.IdQuoteMain
                                    }]
                                }]
                            }];
                            let updateApi = quosal.api.data.update(updates, app.currentQuote.IdQuoteMain);
                            updateApi.finished = function (msg) {
                                quosal.sell.quote.updateFromApiResponse(msg);
                            };

                            updateApi.call();
                            Dialog.close();
                        }
                    },
                    Dialog.links.cancel
                ]
            });
        }
    }

    UNSAFE_componentWillMount() {
        if (quosal.publish.publishCheat) { return; }

        this.formSelectionTabDefinitions = [
            {
                id: 'QuoteTemplates',
                title: 'Quote Forms',
                idForSortable: 'availableforms',
                classForSortable: 'formsList',
                publishDocuments: quosal.publish.reportForms
            }, {
                publishDocuments: quosal.publish.adHocPdfDocuments
            }, {
                id: 'PDFDocuments',
                title: 'PDF Documents',
                idForSortable: 'availablepdfs',
                classForSortable: 'pdfList',
                publishDocuments: quosal.publish.pdfDocuments
            }, {
                id: 'ProductBrochures',
                title: 'Product Brochures',
                idForSortable: 'productdocs',
                classForSortable: 'productDocList',
                publishDocuments: quosal.publish.productDocuments
            }, {
                id: 'EtilizeDocs',
                title: 'Etilize Documents',
                idForSortable: 'etilizedocs',
                classForSortable: 'etilizeList',
                publishDocuments: quosal.publish.etilizeDocuments
            }, {
                id: 'SalesLiterature',
                title: 'Sales Literature',
                idForSortable: 'salesliterature',
                classForSortable: 'salesLiteratureList',
                publishDocuments: quosal.publish.dynamicsSalesLiterature
            }, {
                isVideo: true,
                id: 'VideoLibrary',
                title: 'Video Library',
                idForSortable: 'videolib',
                classForSortable: 'videoLibList',
                publishDocuments: quosal.publish.videos
            }
        ];

        this.usingDefaultReportIndexCache(function () {
            this.repairDefaultReportList(quosal.publish.reportForms, '.xrpt');
            this.repairDefaultReportList(quosal.publish.pdfDocuments, '.pdf');
            this.repairDefaultVideoList();
        }.bind(this));
    }

    componentDidMount() {
        if (quosal.publish.publishCheat) { return; }

        var selectedTemplate = document.getElementById("selectedtemplate");
        if (selectedTemplate) {
            this.getOrderPorterTemplateText(selectedTemplate.options);
        }

        $('#tabs').tabs();

        if (!this.isDisabled()) {
            $('#attachedforms').sortable({
                connectWith: '#availableforms, #availablepdfs, #etilizedocs, #productdocs, #salesliterature',
                //#BeginIsTemplate()change: function() { $.quosal.forms.saveCatch(); }, #EndIsTemplate()
                start: this.beginDrag,
                stop: this.stopDrag
            });

            $('#attachedvideos').sortable({
                connectWith: '#videolib',
                //#BeginIsTemplate()change: function() { $.quosal.forms.saveCatch(); }, #EndIsTemplate()
                start: this.beginDrag,
                stop: this.stopDrag
            });

            $('#availableforms').sortable({
                connectWith: '.formsList',
                stop: this.stopDrag
            });

            $('#availablepdfs').sortable({
                connectWith: '.pdfList',
                stop: this.stopDrag
            });

            $('#etilizedocs').sortable({
                connectWith: '.etilizeList',
                stop: this.stopDrag
            });

            $('#productdocs').sortable({
                connectWith: '.productDocList',
                stop: this.stopDrag
            });

            $('#salesliterature').sortable({
                connectWith: '.salesLiteratureList',
                stop: this.stopDrag
            });

            $('#videolib').sortable({
                connectWith: '.videoLibList',
                stop: this.stopDrag
            });

            QuotePublishInner.expirationDateCheck();
        }

        quosal.sell.quote.onUpdate.bind(this.onQuoteUpdate);
    }

    onQuoteUpdate() {
        this.forceUpdate();
    }

    componentWillUnmount() {
        quosal.sell.quote.onUpdate.unbind(this.onQuoteUpdate);
    }

    componentDidUpdate(prevProps, prevState) {
        var selectedTemplate = document.getElementById("selectedtemplate");
        if (prevState.docType != 'ORDERPORTER' && selectedTemplate && !app.currentQuote.IsTemplate) {
            this.getOrderPorterTemplateText(selectedTemplate.options);
        }
    }

    onChangeUpdate(event) {
        this.setState({
            isEditorOutput: event.target.checked
        });
    }

    render() {
        if (quosal.publish.publishCheat) {
            return <Panel title="You cannot access Publish and Deliver yet."></Panel>
        }

        var quote = app.currentQuote;
        var isDisabled = this.isDisabled();
        var isOnPremUser = this.isOnPremUser();
        var isTemplate = quote.IsTemplate;
        var docmergeClass = isTemplate ? 'template' : ''
        var formSelectionTabs = [];
        var formSelectionTabBodies = [];
        var isOrderPorter = this.isOrderPorterDeliverOption();
        var hasPersonalVideo = !!quote.EmbeddedVideoIframeAttributes;

        var availableFormActions = [];
        var returnUrlParameter = 'quotereturnurl=' + encodeURIComponent(location.href);
        if (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) {
            availableFormActions.push({
                title: 'Manage Additional Content',
                url: quosal.util.url('manageadditionalcontent.quosalweb', returnUrlParameter)
            });
            availableFormActions.push({
                title: 'Marketplace',
                url: quosal.util.url('marketplacesearch.quosalweb', returnUrlParameter)
            });
        }
         //sp 3/12/18 9880778 : Make Video Library a STANDARD feature
        if (quosal.settings.getValue('canEditVideoLibrary') && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) ) {
            availableFormActions.push({
                title: 'Video Library',
                url: quosal.util.url('videolibrary.quosalweb', returnUrlParameter)
            });
        }
        var deliverOptionActions = [
            {
                title: 'Configure My Email',
                url: quosal.util.url('adminemailsetup.quosalweb', returnUrlParameter)
            }
        ];

        var attachedDocumentTiles = [];
        var attachedVideoTiles = [];
        this.usingDefaultReportIndexCache(function () {
            for (var i = 0; i < this.formSelectionTabDefinitions.length; i++) {
                var definition = this.formSelectionTabDefinitions[i];
                if (definition.publishDocuments && definition.publishDocuments.length) {
                    var id = definition.id;
                    var tiles = [];
                    for (var j = 0; j < definition.publishDocuments.length; j++) {
                        var publishDocument = definition.publishDocuments[j];
                        if (definition.isVideo) {
                            var videoId = publishDocument.IdQuoteVideos;
                            var indexInDefaultVideoList = this.indexInDefaultVideoList(videoId);
                            if (indexInDefaultVideoList >= 0) {
                                attachedVideoTiles[Number(indexInDefaultVideoList)] =
                                    <VideoSelectionTile key={indexInDefaultVideoList}
                                                        deleteClick={this.removeFromDefaultVideoList}
                                                        video={publishDocument}
                                                        isDisabled={isDisabled}></VideoSelectionTile>;
                            } else if (id) {
                                tiles.push(<VideoSelectionTile key={j}
                                                              addClick={this.addToDefaultVideoList}
                                                               video={publishDocument}
                                                               isDisabled={isDisabled}></VideoSelectionTile>)
                            }
                        } else {
                            var documentId = publishDocument.DocumentId;
                            var indexInDefaultReportList = this.indexInDefaultReportList(documentId);
                            if (indexInDefaultReportList >= 0) {
                                var removeFromDefaultReportList = (publishDocument.DocumentType === 'adhoc') ?
                                    function (documentId) {
                                        this.deleteAdHocDocument(documentId);
                                        this.removeFromDefaultReportList(documentId);
                                    }.bind(this) :
                                    this.removeFromDefaultReportList;

                                attachedDocumentTiles[Number(indexInDefaultReportList)] =
                                    <FormSelectionTile key={indexInDefaultReportList}
                                                       deleteClick={removeFromDefaultReportList}
                                                       publishDocument={publishDocument}
                                                       isDisabled={isDisabled}></FormSelectionTile>;
                            } else if (id) {
                                tiles.push(<FormSelectionTile key={j}
                                                              addClick={this.addToDefaultReportList}
                                                              publishDocument={publishDocument}
                                                              isDisabled={isDisabled}></FormSelectionTile>)
                            }
                        }
                    }
                    if (id) {
                        var styleForHiding = (((this.state.docType === 'DOC' || this.state.docType === 'DOCX') && id != 'QuoteTemplates') || (definition.isVideo && !isOrderPorter)) ? {display:'none'} : null;
                       
                        formSelectionTabs.push(<FormSelectionTab key={id} id={id} style={styleForHiding}
                                                                 title={definition.title}></FormSelectionTab>);
                        formSelectionTabBodies.push(
                            <FormSelectionTabBody key={id} id={id}
                                                  idForSortable={definition.idForSortable}
                                                  classForSortable={definition.classForSortable}>
                                {tiles}
                            </FormSelectionTabBody>
                        );
                    }
                }
            }
        }.bind(this));
        
        var noDocuments = (attachedDocumentTiles.length === 0);
        if (noDocuments) {
            attachedDocumentTiles = <span className="prose" style={{color:'#AAAAAA'}}>You must attach at least one document in order to publish.</span>;
        }

        var publishPanelTitleChildren = [];
        var orderPorterTemplateIsValid = this.state.orderPorterTemplateValidation.includes('[INVALID]') && this.state.docType == 'ORDERPORTER' ? true : false;

        var isOrderPorterSelected = 'ORDERPORTER' === this.state.docType || (!app.settings.global.EnableDocuSign && this.state.docType === 'DOCUSIGN');
        if (quosal.util.isNewEditorPreviewContentEnabled()) {
            isOrderPorterSelected = 'ORDERPORTER' === this.state.docType;
        }

        var isSaving = this.state.isSaving;
        var isPublishing = this.state.isPublishing;
        var isAutoSavingPdfContent = this.state.isEditorOutput && this.state.isAutoSavingPdfContent;
        var shouldAskBeforePublish = isOrderPorterSelected && (quote.QuoteStatus == "Won" || quote.OrderPorterApproved);
        var disablePublishButton = isDisabled || isSaving || isPublishing || orderPorterTemplateIsValid || (!this.state.isEditorOutput && noDocuments) || isAutoSavingPdfContent;
        var disableSaveChangesButton = isDisabled || isSaving || isPublishing || isAutoSavingPdfContent;
        if (!isTemplate) {
            publishPanelTitleChildren.push(<SaveButton key="publish" text="Publish" onClick={shouldAskBeforePublish ? this.askBeforePublish : this.doPublish} style={{ float: 'right', marginRight: 10 }} disabled={disablePublishButton} isSaving={isPublishing}></SaveButton>);
        }
        publishPanelTitleChildren.push(<SaveButton key="justSave" text="Save Changes" onClick={this.doSave} style={{float:'right', marginRight:10}} disabled={disableSaveChangesButton} isSaving={isSaving} ></SaveButton>);
        publishPanelTitleChildren = <div className="toolbar right">{publishPanelTitleChildren}</div>;

        const visibleOptionStyle = quosal.util.isNewEditorPreviewContentEnabled() && this.state.isEditorOutput ? { display: 'none' } : {};
        var deliverOptionRadioButtons = [];
        var canPublishOther = app.currentUser.IsAdministrator || app.currentUser.IsPublisher;
        var canUseDocuSign = app.entity && app.entity != 'STARTER' && app.settings.global.EnableDocuSign || (quosal.util.isNewEditorPreviewContentEnabled() && this.state.isEditorOutput);
        
        if (isTemplate) {
            deliverOptionRadioButtons.push(
                    <div key="DEFAULT_DELIVERY" className="formcheckboxwrapper">
                    <input type="radio" name="doctype" value="" id="DEFAULT_DELIVERY" onChange={this.deliverOptionChange} checked={!this.state.docType} disabled={isDisabled} />
                    <label htmlFor="DEFAULT_DELIVERY" className="deliver-options formlabel">{'Use Default Deliver Option (' + (quosal.settings.getValue('EmailDefaultDelivery') ? 'Email PDF' : 'Order Porter') + ')' }</label>
                </div>
            );
        }
        deliverOptionRadioButtons.push(
            <div key="ORDERPORTER" className="formcheckboxwrapper">
                <input type="radio" name="doctype" value="ORDERPORTER" id="ORDERPORTER" onChange={this.deliverOptionChange} checked={isOrderPorterSelected} disabled={isDisabled} />
                <label htmlFor="ORDERPORTER" className="deliver-options formlabel">Order Porter</label>
                { isOrderPorter ?
                    <div id="optemplates" style={{padding:'5px 0 0 25px'}}>
                        <div className="formfieldlabel">
                            <label htmlFor="selectedtemplate" className="formlabel" style={{color: '#4E4F51', height: 13, fontSize: '10px', marginLeft: -5, display: 'block', whiteSpace: 'nowrap'}}>Choose Template</label>
                        </div>
                        <FormFieldInput field={{DataType: 'Enum', EnumType: 'OrderPorterTemplateList', FieldName: 'selectedtemplate', Value: this.state.orderPorterTemplate}}
                                        fieldId="selectedtemplate" onChange={this.orderPorterTemplateChange} disabled={isDisabled} />
                    </div>
                    : null }
            </div>
        );
        deliverOptionRadioButtons.push(
            <div key="PDF" className="formcheckboxwrapper">
                <input type="radio" name="doctype" value="PDF" id="PDF" onChange={this.deliverOptionChange} checked={'PDF' === this.state.docType} disabled={isDisabled} />
                <label htmlFor="PDF" className="deliver-options formlabel">Email PDF</label>
            </div>
        );
        if (canPublishOther) {
            deliverOptionRadioButtons.push(
                <div key="XLS" className="formcheckboxwrapper" style={{ ...visibleOptionStyle}}>
                    <input type="radio" name="doctype" value="XLS" id="XLS" onChange={this.deliverOptionChange} checked={'XLS' === this.state.docType} disabled={isDisabled} />
                    <label htmlFor="XLS" className="deliver-options formlabel">Email XLS</label>
                </div>
            );
            deliverOptionRadioButtons.push(
                <div key="RTF" className="formcheckboxwrapper" style={{ ...visibleOptionStyle }}>
                    <input type="radio" name="doctype" value="RTF" id="RTF" onChange={this.deliverOptionChange} checked={'RTF' === this.state.docType} disabled={isDisabled} />
                    <label htmlFor="RTF" className="deliver-options formlabel">Email RTF</label>
                </div>
            );
            deliverOptionRadioButtons.push(
                <div key="DOC" className="formcheckboxwrapper" style={{ ...visibleOptionStyle }}>
                    <input type="radio" name="doctype" value="DOC" id="DOC" onChange={this.deliverOptionChange} checked={'DOC' === this.state.docType} disabled={isDisabled} />
                    <label htmlFor="DOC" className="deliver-options formlabel">Email DOC</label>
                </div>
            );
            deliverOptionRadioButtons.push(
                <div key="DOCX" className="formcheckboxwrapper" style={{ ...visibleOptionStyle }}>
                    <input type="radio" name="doctype" value="DOCX" id="DOCX" onChange={this.deliverOptionChange} checked={'DOCX' === this.state.docType} disabled={isDisabled} />
                    <label htmlFor="DOCX" className="deliver-options formlabel">Email DOCX</label>
                </div>
            );
        }
        if (canUseDocuSign) {
            deliverOptionRadioButtons.push(
                <div key="DOCUSIGN" className="formcheckboxwrapper">
                    <input type="radio" name="doctype" value="DOCUSIGN" id="DOCUSIGN" onChange={this.deliverOptionChange} checked={'DOCUSIGN' === this.state.docType} disabled={isDisabled} />
                    <label htmlFor="DOCUSIGN" className="deliver-options formlabel">Email DocuSign</label>
                </div>
            );
        }

        var videosAttachedShowHide = isOrderPorter ? null : {display:'none'};

        var tabSummaries = [];
        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            var quoteTab = app.currentQuote.Tabs[i]
            if(!quoteTab.IsHidden || app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) {
                tabSummaries.push(<TabSummary key={i} tab={quoteTab} publishPage={this} isDisabled={isDisabled} ></TabSummary>)
            }
        }
        
        const editorOutputStyle = quosal.util.isNewEditorPreviewContentEnabled() && this.state.isEditorOutput ? { pointerEvents: 'none', opacity: 0.38 } : {};

        return (
            <div id="docmerge" className={docmergeClass} >
                <Panel title="Publish Document" titleChildren={publishPanelTitleChildren} duplicateTitleButtonsInFooter={true} >
                    <PanelContent>
                        <GridColumn size="4x3" style={{width: '60%'}}>
                            <div className="panelsection" style={{ width: 'calc(100% - 20px)', ...editorOutputStyle}}>
                                <div className="title" style={{fontWeight:'normal'}}>Available Attachments
                                    {availableFormActions.length > 0 ? <PanelToolbarButton style={{height: 'initial'}}>
                                        <ActionsMenu name="availableformsconfig" actions={availableFormActions} />
                                    </PanelToolbarButton>: null}
                                </div>
                                <div id="tabs">
                                    <ul>{formSelectionTabs}</ul>
                                    {formSelectionTabBodies}
                                </div>
                            </div>
                        </GridColumn>
                        <GridColumn size="4x1" style={{width: '38%', minWidth: 247}}>

                            <div className="panelsection">
                                <div className="title" style={{ fontWeight: 'normal' }}>
                                    Deliver Options
                                    <PanelToolbarButton style={{height: 'initial'}}>
                                        <ActionsMenu name="deliveroptionsconfig" actions={deliverOptionActions} />
                                    </PanelToolbarButton>
                                    {quosal.util.isNewEditorPreviewContentEnabled() && (<div className="editoroutput" style={{ float: 'right' }}>
                                        <input type="checkbox" value="true" name="EditorOutput"
                                            style={{ marginRight: '10px' }}
                                        checked={this.state.isEditorOutput}
                                        onChange={this.onChangeUpdate} />
                                    <label >Editor Output</label>
                                  <CPQVirtualEditor onAutoSaveFinished={()=>{
                                    this.setState({isAutoSavingPdfContent: false});
                                    }} />
                                </div>)}
                                </div>
                                <div className="content">
                                    <div className="formcolumn">
                                        <div className="verticalform compact">
                                            {deliverOptionRadioButtons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panelsection attached" style={{ ...editorOutputStyle }}>
                                <div className="title" style={{ fontWeight: 'normal', height: 'auto'}}>Documents Attached
                                    {isTemplate || isDisabled || isOnPremUser || this.state.docType === 'DOC' || this.state.docType === 'DOCX'? null :
                                        <div className="toolbar"><div onClick={this.adHocDocumentAddClick} className="toolbutton icons-action add color" id="adhoc-pdf-attach" title="Attach PDF (Ad Hoc)"></div></div>
                                    }
                                </div>
                                <div className="content">
                                    <div id="quoteformplaceholder">
                                        <div id="attachedforms" className="attachments formsList pdfList etilizeList productDocList salesLiteratureList">
                                            {attachedDocumentTiles}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="videosAttached" style={{ ...videosAttachedShowHide, ...editorOutputStyle }} className="panelsection attached">
                                <div className="title" style={{ fontWeight: 'normal', height: 'auto'}}>Videos Attached
                                    { isTemplate || isDisabled ? null :
                                        <div className="toolbar"><div onClick={this.adHocVideoAddClick} className="toolbutton icons-action add color" id="adhoc-video-attach" title="Attach Video (Ad Hoc)"></div></div>
                                    }
                                </div>
                                <div className="content">
                                    { hasPersonalVideo ?
                                        <div className="personal-videos">
                                            <div id="personalvideo" className="attachments">
                                                <div className="quoteformblock video">
                                                    { isDisabled ? null : <div id="removepersonalvideo" className="icons-action delete color whiteIcon" title="Remove Personal Video" onClick={this.deletePersonalVideoClick} ><CwWidgets.CwImage src="img/svgs/v1.0/Action_Delete.svg" isInlineSVG="true" style={{width:'16px', height:'16px'}} /></div> }
                                                    <div className="video-preview-container" title="Click to Preview Video" >
                                                        <iframe id="quoteVideo" {...quote.EmbeddedVideoIframeAttributes} ></iframe>
                                                    </div>
                                                    <CwWidgets.CwImage src="img/svgs/sell/Flag_Customer.svg" isInlineSVG="true" className="personalvideostate whiteIcon" title="This is a Personal Video." />
                                                    <div className="quoteformname personalvideotitle"></div>
                                                </div>
                                            </div>
                                        </div>
                                        : null }
                                    <div id="attachedvideoplaceholder">
                                        <div id="attachedvideos" className="attachments videoLibList">
                                            {attachedVideoTiles}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panelsection" style={{ ...editorOutputStyle }}>
                                <div className="title" style={{ fontWeight: 'normal'}}>Tab Summary</div>
                                <div className="content">
                                    <table cellPadding="0" cellSpacing="0" style={{marginTop:10}}>
                                        {tabSummaries}
                                    </table>
                                </div>

                            </div>
                        </GridColumn>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}

class FormSelectionTab extends React.Component {
    render() {
        return (
            <li style={this.props.style} id={this.props.id + 'Tab'}>
                <a href={'#' + this.props.id}>
                    {this.props.title}
                </a>
            </li>
        );
    }
}

class FormSelectionTabBody extends React.Component {
    render() {
        return (
            <div style={{width: '100%'}} id={this.props.id} className="tabpage" >
                <div className="panelsection">
                    <div className="content">
                        <div id={this.props.idForSortable} className={this.props.classForSortable}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class FormSelectionTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.addClick = this.addClick.bind(this); 
        this.deleteClick = this.deleteClick.bind(this); 
    }

    addClick() {
        if (this.props.addClick) {
            //#BeginIsTemplate() $.quosal.forms.saveCatch(); #EndIsTemplate()
            this.props.addClick(this.props.publishDocument.DocumentId);
        }
    }

    deleteClick() {
        if (this.props.deleteClick) {
            //#BeginIsTemplate() $.quosal.forms.saveCatch(); #EndIsTemplate()
            this.props.deleteClick(this.props.publishDocument.DocumentId);
        }
    }

    render() {
        var doc = this.props.publishDocument;
        var documentName = doc.DocumentName;
        if (documentName.length > 50) {
            documentName = documentName.substring(0, 50) + '...';
        }
        return (
            <div className={'quoteformblock ' + doc.DocumentType}>
                <div className="category">{doc.GroupName}</div>
                { this.props.isDisabled ? null : <div onClick={this.addClick} className="icons-action add color"></div> }
                { this.props.isDisabled ? null : <div onClick={this.deleteClick} className="icons-action delete color"></div> }
                <input type="hidden" name={doc.DocumentId} value="" />
                <img className="quoteformthumb" src={doc.ThumbnailUrl} />
                <div className="quoteformname" title={doc.DocumentName}>{documentName}</div>
            </div>
        );
    }
}

class VideoSelectionTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
   
        // This binding is necessary to make `this` work in the callback
        this.addClick = this.addClick.bind(this); 
        this.deleteClick = this.deleteClick.bind(this); 
        this.previewVideoClick = this.previewVideoClick.bind(this); 
    }

    addClick() {
        if (this.props.addClick) {
            //#BeginIsTemplate() $.quosal.forms.saveCatch(); #EndIsTemplate()
            this.props.addClick(this.props.video.IdQuoteVideos);
        }
    }

    deleteClick() {
        if (this.props.deleteClick) {
            //#BeginIsTemplate() $.quosal.forms.saveCatch(); #EndIsTemplate()
            this.props.deleteClick(this.props.video.IdQuoteVideos);
        }
    }

    previewVideoClick() {
        var videoId = this.props.video.VideoId;
        var videoType = this.props.video.VideoType;
        var videoUrl = this.props.video.VideoUrl;
        var videoTitle = this.props.video.Title;

        var iframeProps = {
            style: {
                width: 640,
                height: 480
            },
            frameBorder: 0,
            allowFullScreen: true,
            className: 'videoplayer'
        };
        var embed;
        if (videoType == 'YouTube') {
            iframeProps.src = 'https://www.youtube.com/embed/' + videoId + '?rel=0&autoplay=1';
            embed = <iframe {...iframeProps} ></iframe>;
        } else if (videoType == 'Vimeo') {
            iframeProps.src = '//player.vimeo.com/video/' + videoId + '?autoplay=1';
            embed = <iframe {...iframeProps} ></iframe>;
        } else if (videoType == 'Screencast') {
            iframeProps.src = videoUrl;
            iframeProps.className += ' tscplayer_inline embeddedObject';
            iframeProps.name = 'tsc_player';
            iframeProps.scrolling = 'no';
            iframeProps.type = 'text/html';
            iframeProps.style.overflow = 'hidden';
            embed = <iframe {...iframeProps} ></iframe>;
        } else {
            embed = <div class="videoplayer" style={iframeProps.style} >{videoUrl}</div>
        }

        Dialog.open({
            title: videoTitle,
            message: embed,
            links: [Dialog.links.close]
        });
    }

    render() {
        var video = this.props.video;
        var categoryTruncated = video.Category;
        if (video.Category.length > 20) {
            categoryTruncated = video.Category.substring(0, 20) + '...';
        }
        return (
            <div className={'quoteformblock video ' + video.VideoListType} >
                <div className="category" title={'Category: ' + video.Category}>{categoryTruncated}</div>
                { this.props.isDisabled ? null : <div onClick={this.addClick} className="icons-action add color"></div> }
                { this.props.isDisabled ? null : <div onClick={this.deleteClick} className="icons-action delete color"></div> }
                <input type="hidden" name={video.IdQuoteVideos} value="" />

                <div className="video-preview-container" title="Click to Preview Video" onClick={this.previewVideoClick} >
                    <div className="play-icon"></div>
                    <img className="quoteformthumb" src={video.ThumbnailUrl} />
                </div>
                {
                    video.IsGlobalDefault ?
                        <img src="img/svgs/sell/Flag_Global.svg" width="20px" className="globaldefaultstate" title="This video is a Global Default." />
                        : null
                }
                {
                    video.IsApproved ?
                        <img src="img/svgs/v1.0/Action_Approve.svg" width="20px" className="approvalstate" title="This video has been approved." />
                        : null
                }
                <div className="quoteformname">{video.Title}</div>
            </div>
        );
    }
}

class TabSummary extends React.Component {    
    constructor(props) {
        super(props);

        var tab = this.props.tab;
        var tabCheckboxValues = {};
        for (var i = 0; i < TabSummary.checkboxKeys.length; i++) {
            var fieldName = TabSummary.checkboxKeys[i];
            tabCheckboxValues[fieldName] = tab[fieldName];
        }

        this.state = {    
            tabCheckboxValues: tabCheckboxValues,
        }; 

        // This binding is necessary to make `this` work in the callback
        this.checkboxChange = this.checkboxChange.bind(this);
        this.createCheckbox = this.createCheckbox.bind(this);
    }   
   
    checkboxChange(fieldName, e) {
        this.state.tabCheckboxValues[fieldName] = e.target.checked;
        this.setState({
            tabCheckboxValues: this.state.tabCheckboxValues
        });
        if (!this.props.publishPage.updatedTabBooleans) {
            this.props.publishPage.updatedTabBooleans = {};
        }
        this.props.publishPage.updatedTabBooleans[this.props.tab.IdQuoteTabs] = this.state.tabCheckboxValues;
    }

    createCheckbox(fieldName) {
        var tab = this.props.tab;
        return <input type="checkbox" value="true" name={fieldName + '.' + tab.IdQuoteTabs}
                      onChange={this.checkboxChange.bind(this, fieldName)}
                      checked={this.state.tabCheckboxValues[fieldName]}
                      disabled={this.props.isDisabled} />
    }

    render() {
        var tab = this.props.tab;
        var hasSuggested = (tab.SuggestedTotal != 0) || (app.currentQuote.IncludeZeroSuggestedInDiscount);
        var textAlignRight = {textAlign:'right'};
        return (
            <tbody style={{fontSize:'12px'}}>
                <tr>
                    <td colSpan="5"><b style={{fontSize:'16px'}}>{tab.TabName}</b></td>
                </tr>
                <tr>
                    <td>Subtotal:</td>
                    <td style={textAlignRight}>{app.currentQuote.formatCurrency(tab.Subtotal)}</td>
                    <td width="20"></td>
                    <td>Gross Margin</td>
                    <td style={textAlignRight}>{tab.GrossMargin}</td>
                </tr>
                <tr>
                    <td>Recurring:</td>
                    <td style={textAlignRight}>{app.currentQuote.formatCurrency(tab.RecurringSubtotal)}</td>
                    <td width="20"></td>
                    <td style={{width:100}}>Is Deselected Option:</td>
                    <td style={{width:90, textAlign:'right'}}>{
                        this.createCheckbox('IsOptional')
                    }</td>
                </tr>
                <tr>
                    <td>Gross Profit:</td>
                    <td style={textAlignRight}>{app.currentQuote.formatCurrency(tab.Subtotal - tab.Cost)}</td>
                    <td width="20"></td>
                    <td>Is Printed:</td>
                    <td style={textAlignRight}>{
                        this.createCheckbox('IsPrinted')
                    }</td>
                </tr>
                <tr>
                    <td>
                        {hasSuggested ? 'Suggested:' : ''}
                    </td>
                    <td style={textAlignRight}>
                        {hasSuggested ? app.currentQuote.formatCurrency(tab.SuggestedTotal) : ''}
                    </td>
                    <td width="20"></td>
                    <td>Included in Totals:</td>
                    <td style={textAlignRight}>{
                        this.createCheckbox('IsTotalsIncluded')
                    }</td>
                </tr>
                {hasSuggested ?
                    <tr>
                        <td>Suggested Discount:</td>
                        <td style={textAlignRight}>{tab.PrintedSuggestedDiscountPercent}</td>
                        <td width="20"></td>
                        <td></td>
                        <td style={textAlignRight}></td>
                    </tr>
                    : null}
                <tr><td>&nbsp;</td></tr>
            </tbody>
        );
    }
}

TabSummary.checkboxKeys= ['IsOptional', 'IsPrinted', 'IsTotalsIncluded'];

class PersonalVideoAttacher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            videoUrl: this.props.videoUrl || '',
        }; 

        // This binding is necessary to make `this` work in the callback
        this.doVideoAttach = this.doVideoAttach.bind(this);
        this.urlChange = this.urlChange.bind(this);
    }

    static youtubeUrlRegex(){ return new RegExp('^(?:https?://)?(?:www\.)?youtu(?:\.be|be\.com)/(?:embed/|v/||watch\\?v=|watch\\?.+&v=)([A-Za-z0-9_-]{11})$') }

    doVideoAttach() {
        if (PersonalVideoAttacher.youtubeUrlRegex().test(this.state.videoUrl)) {
            var content = PersonalVideoAttacher.youtubeUrlRegex().exec(this.state.videoUrl);
            var videoEmbedSrc = "//www.youtube.com/embed/" + content[1] + "?rel=0&showinfo=0&wmode=opaque";
            this.props.doVideoAttach(videoEmbedSrc);
        }
    }

    urlChange(e) {
        var value = e.target.value;
        var valid = PersonalVideoAttacher.youtubeUrlRegex().test(value);
        var validationText = valid ? null : 'This url is not in the correct format; please enter a YouTube URL.'
        this.setState({
            videoUrl: value,
            validationText: validationText
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.validationText != prevState.validationText) {
            if (this.state.validationText) {
                $.quosal.validation.validateField('YouTubeURLInput', 'error', this.state.validationText);
            } else {
                $.quosal.validation.clearField('YouTubeURLInput');
                this.doVideoAttach();
            }
        }
    }

    render() {
        var validationIcon = null;
        var validationTooltip = null;
        return (
            <div className="content" >
                <div className="table" style={{marginLeft:'auto', marginRight: 'auto'}}>
                    <div className="tr">
                        <div className="td center">
                            <p>Input a YouTube URL</p>
                            <div id="embeddedVideoURL" style={{display: 'inline-block'}}>
                                <input id="YouTubeURLInput" name="YouTubeURLInput" type="text" onPaste={this.urlChange} onChange={this.urlChange} value={this.state.videoUrl} />
                                <div id="newEmbeddedVideoErrors"></div>
                            </div>
                        </div>
                    </div>
                    <div className="tr">
                        <div className="td center">
                            <p style={{margin:15}}>
                                If you want to record a personal video, we recommend getting a browser extension to directly take a webcam video and put it right on Youtube.  One extension we recommend
                                is <a href="https://www.screencastify.com/" target="_blank">Screencastify</a>.
                                This allows you to record directly onto YouTube, and will give you a friendly URL that you can paste right above.
                            </p>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class QuoteExpiredCorrectionDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        let message = (app.currentQuote.ExpirationDate ? 'This quote is expired.' : 'This quote is missing an expiration date.');

        return (<div style={{width: 300}}>
            <div>{message} Would you like to update the expiration date?</div>
            <br />
            <div className="formcolumn">
                <div className="formfieldwrapper">
                    <div className="formfieldlabel">
                        <label htmlFor="quoteExpiredCorrectionDialogDateTimeInput" className="formlabel" >Expiration Date</label>
                    </div>
                    <div className="formfield">
                        <DateTimeInput id="quoteExpiredCorrectionDialogDateTimeInput"
                                       inputElementProps={{defaultValue: app.currentQuote.ExpirationDate}}
                                       setValue={function () {}} />
                    </div>
                </div>
            </div>
        </div>);
    }
}